import axios from "axios";
import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useLocation } from "react-router-dom";

const OurBrochure = () => {
  const location = useLocation();
  // get method
  const [AddPartnerss, setAddPartnerss] = useState([]);
  const getaddPPartnerss = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddBPartnerss"
    );
    if (res.status === 200) {
      setAddPartnerss(res.data.getadddBPartners);
    }
  };

  if (location?.state) {
    AddPartnerss = JSON.parse(JSON.stringify(location?.state));
    AddPartnerss?.pop();
  }
  // to print the pdf ----->
  // const createPDF = async () => {
  //   const pdf = new jsPDF("portrait", "pt", "a4");
  //   const data = await html2canvas(document.querySelector("#pdf"), {
  //     useCORS: true,
  //   });
  //   console.log("hhhh", data);
  //   const img = data.toDataURL("image/png");
  //   console.log("ddkd1", img);
  //   const imgProperties = pdf.getImageProperties(img);
  //   console.log("ddkd2", imgProperties);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   console.log("ddkd3", pdfWidth);
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //   console.log("ddkd4", pdfHeight);
  //   pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

  //   pdf.save("Brochures.pdf");
  // };
  const createPDF = async (elementId) => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const element = document.getElementById(elementId);
    const data = await html2canvas(element, {
      useCORS: true,
    });
    const img = data.toDataURL("image/png");
  
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (data.height * imgWidth) / data.width;
  
    pdf.addImage(img, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save("Brochure.pdf");
  };
  useEffect(() => {
    window.scroll(0,0);
    getaddPPartnerss();
  }, []);

  return (
    <div style={{backgroundColor:"#F5F5F5"}}>
      {/* <div
        className="container mt-3 mb-2"
        style={{
          backgroundImage: 'url("../we-provide-bg.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "200px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-slider3 text-center">
          <h1
            style={{
              color: "white",
              marginTop: "66px",
              fontSize: "50px",
            }}
          >
            Our Brochure
          </h1>
        </div>
      </div> */}

      <div >
        <div className="container pb-3" style={{backgroundColor:"#F5F5F5"}}>
        <div className="row text-center ">
          <h2 className="pt-2">
            Our <span style={{ color: "#FE5E00" }}>Brochures</span>
          </h2>
          <h6>
          “You can get everything in life you want if you will just help enough other people get what they want.” 
          </h6>
        </div>
         
              <div className="row mt-3">
              {AddPartnerss?.map((item, i) => {
            return (
                <div className="col-md-4 box2 mt-4" >
                  <div className="box" style={{backgroundColor:"#F5F5F5"}}>
                    <div id={`pdf-${i}`}>
                    <img
                      src={`https://kanagamultiservice.in/OurBrochures/${item?.BImg}`}
                      alt=""
                      style={{ height: "329px", position: "relative" }}
                    />
                    </div>
                   

                    <div className="box-content">
                      <div className="content">
                        <button
                          type="submit"
                          style={{backgroundColor:"#2D0290",border:"1px solid #2D0290"}}
                          className="btn btn-success p-3 "
                          // onClick={createPDF}
                          onClick={() => createPDF(`pdf-${i}`)}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                );
              })}
              </div>
            
        </div>
      </div>
    </div>
  );
};

export default OurBrochure;
