import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { useEffect } from "react";
import parse from "html-react-parser";
import Card from "react-bootstrap/Card";
import Aos from "aos";
import { Pagination } from "react-bootstrap";
import emailjs from "@emailjs/browser";

const Services = () => {
   //email integration 
   const form = useRef();
   const sendEmail = (e) => {
     e.preventDefault();
     emailjs
       .sendForm(
         "service_3w3g9h7",
         "template_oi0qp23",
         form.current,
         "MUIWT1wj9Eqv9fJC0"
       )
      //  .then(
      //    (result) => {
      //      console.log(result.text, "afewaeh");
      //      window.location.assign("/services");
      //    },
      //    (error) => {
      //      console.log(error.text);
      //    }
      //  );
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          window.location.assign("/services");
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      );
   };

  const [SelectedId, setSelectedId] = useState();
  const [Viewmore, setViewmore] = useState(true);

  const [expanded, setExpanded] = useState(false);
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };
  const [Electric, setElectric] = useState("");
  const [Catfilter, setCatfilter] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [servicedata, setservicedata] = useState({});
  const handleShow = (item) => {
    setShow(true);
    setservicedata(item);
  };
  // integrating post method
  const [SFName, setSFName] = useState("");
  const [SLName, setSLName] = useState("");
  const [SPhone, setSPhone] = useState("");
  const [SEmail, setSEmail] = useState("");
  const [SDesiFrom, setSDesiFrom] = useState("");
  const [SDesiTo, setSDesiTo] = useState("");
  const [SRquire, setSRquire] = useState("");

  console.log("servicedata", servicedata);
  const EnquiryS = async () => {
    try {
      if (!SFName) return alert("Please Enter Your First Name");
      if (!SLName) return alert("Please Enter Your Last Name");
      if (!SPhone) return alert("Please Enter Your Phone no");
      if (!SEmail) return alert("Please Enter Your Email id");
      if (!SDesiFrom) return alert("Please Select From Date");
      if (!SDesiTo) return alert("Please Select To Date");
      if (!SRquire) return alert("Please Enter Your Requirement");
      const config = {
        url: "/user/Senquiry",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          ServiceName: servicedata?.SHeader,
          SFName: SFName,
          SLName: SLName,
          SPhone: SPhone,
          SEmail: SEmail,
          SDesiFrom: SDesiFrom,
          SDesiTo: SDesiTo,
          SRquire: SRquire,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          setSFName("");
          setSLName("");
          setSPhone("");
          setSEmail("");
          setSDesiFrom("");
          setSDesiTo("");
          setSRquire("");
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddGraph, setAddGraph] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const [SelectedCat, setSelectedCat] = useState("");
  const getAddGraph = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallService"
      );
      if (res.status === 200) {
        setAddGraph(res.data.getAddService);
        setnochangedata(res.data.getAddService);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating category get method
  const [AddServiceLists, setAddServiceList] = useState([]);
  const getAddServiceList = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/Admin/getaddServiceList"
      );
      if (res.status == 200) {
        setAddServiceList(res.data.getadddServiceList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAddGraph([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAddGraph([...nochangedata]);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  const searchedProduct = AddGraph.filter((item) => {
    if (searchTerm.value === "") {
      return item;
    }
    if (item?.SHeader.toLowerCase().includes(searchTerm.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  // get method
  const [HServices, setHServices] = useState([]);
  const getaddHService = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddHService");
    if (res.status === 200) {
      setHServices(res.data.getadddHService);
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
    Aos.init();
    getAddGraph();
    getAddServiceList();
    getaddHService();
  }, []);
  console.log(AddGraph);

  useEffect(() => {
    if (Electric) {
      const xyz = AddGraph?.filter((item) => item.SCat === Electric);
      setCatfilter(xyz);
    } else {
      setCatfilter(AddGraph);
    }
  }, [Electric]);

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = AddGraph.slice(visitedPage, visitedPage + productPerPage);
  const pageCount = Math.ceil(AddGraph.length / productPerPage);

  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "green" : "black",
      textDecoration: isActive ? "none" : "none",
      fontWeight: isActive ? "700" : "400",
    };
  };

 
  return (
    <div>
      {/* <div
        className="container mt-3 mb-2"
        style={{
          backgroundImage: 'url("../we-provide-bg.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "200px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-slider3 text-center">
          <h1
            style={{
              color: "white",
              marginTop: "66px",
              fontSize: "50px",
            }}
          >
            Services
          </h1>
        </div>
      </div> */}

      <div className="container">
        <div
          className="row text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1>
            Our <span style={{ color: "#FE5E00" }}>Services</span>
          </h1>
          <h5>We Feel Happy To Provide You The Best Service</h5>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5>
                <span
                  style={{
                    color: "#FD5E00",
                    fontSize: "27px",
                    fontWeight: "550",
                  }}
                >
                  Services
                </span>{" "}
              </h5>
              <hr
                style={{
                  color: "#FE5E00",
                  border: "1px solid #FE5E00",
                }}
              />
              {HServices?.map((item) => {
                return (
                  <p style={{ textAlign: "justify" }}>
                    {parse(`<div>${item?.HService}</div>`)}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="col-md-12 mb-2"
          data-aos="fade-up"
          data-aos-duration="3000"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <div
            className="row justify-content-center"
            style={{ padding: "20px", backgroundColor: "#F5F5F5" }}
          >
            <div className="col-md-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilter}
                />
              </Form>
            </div>
          </div>
        </div>
        <div
          className="row pt-1"
          style={{ backgroundColor: "#F5F5F5" }}
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div className="col-md-4 ">
            <Card style={{ position: "sticky", top: "158px" }}>
              <Card.Header
                style={{
                  backgroundColor: "#2D0290",
                  color: "white",
                  fontSize: "20px",
                }}
              >
                Service List
              </Card.Header>
              <Card.Body>
                <div className="si09">
                  <Card.Text>
                    {AddServiceLists?.map((item) => {
                      return (
                        <div
                          className="row p-2 "
                          onClick={() => setSelectedCat(item?.Scat)}
                        >
                          <div className=" col-2 col-sm-2 col-md-2 col-lg-2">
                            <img
                              src={`https://kanagamultiservice.in/ServiceList/${item?.SIcon}`}
                              alt="pic"
                              width={30}
                              height={25}
                            />
                          </div>
                          <div
                            className="col-10 col-sm-10 col-md-10 col-lg-10 categories"
                            style={{ fontSize: "20px" }}
                            onClick={() => {
                              setElectric(item?.Scat);
                            }}
                          >
                            <p>{item?.Scat}</p>
                          </div>
                        </div>
                      );
                    })}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </div>
          {Catfilter?.length > 0 ? (
            <>
              <div className="col-md-8">
                <h2>{SelectedCat}</h2>
                {Catfilter?.map((item, i) => {
                  return (
                    <>
                      {/* <h2>{item?.SCat}</h2> */}
                      <div
                        style={{
                          width: "100%",
                          background: "rgba(255,255,255,0.24)",
                          boxShadow: "8px 8px 32px 0 rgba(19,19,20,0.37)",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="row p-3">
                          <div className="col-md-5 ">
                            <img
                              src={`https://kanagamultiservice.in/Services/${item?.SImage}`}
                              alt=""
                              style={{ width: "100%", height: "200px" }}
                            />
                          </div>
                          <div className="col-md-7">
                            <div className="row">
                              <div className="col-md-12">
                                <h4
                                  style={{ color: "#FE5E00", fontSize: "20px" }}
                                >
                                  <strong>{item?.SHeader}</strong>
                                </h4>
                              </div>
                              <div className="col-md-12 ">
                                <p style={{ textAlign: "justify" }}>
                                  <p style={{ textAlign: "justify" }}>
                                    {SelectedId === item?._id && Viewmore
                                      ? parse(`<div>${item?.SDesription}</div>`)
                                      : parse(
                                          `<div>${item?.SDesription.slice(
                                            0,
                                            150
                                          )}</div>`
                                        )}
                                  </p>
                                  {SelectedId === item?._id && Viewmore ? (
                                    <>
                                      <div style={{ float: "right" }}>
                                        <a
                                          onClick={() => {
                                            setSelectedId();
                                            setViewmore(!Viewmore);
                                          }}
                                          style={{
                                            color: "blue",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Show less...
                                        </a>
                                      </div>
                                    </>
                                  ) : (
                                    <div style={{ float: "right" }}>
                                      <a
                                        onClick={() => {
                                          setSelectedId(item?._id);
                                          setViewmore(!Viewmore);
                                        }}
                                        style={{
                                          color: "blue",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Show more...
                                      </a>
                                    </div>
                                  )}
                                </p>
                                <div className="col-md-12">
                                  <div className=" pb-3 ">
                                    <div className="p-1 ">
                                      <Button
                                        className="  btn-danger p-1"
                                        onClick={() => {
                                          
                                          handleShow(item);
                                        }}
                                        style={{ backgroundColor: "#FE5E00" }}
                                      >
                                        Enquire Now
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="text-end">
                <Pagination style={{ float: "right" }}>
                  <Pagination.First onClick={() => setPageNumber(0)} />
                  <Pagination.Prev
                    onClick={() =>
                      setPageNumber((prev) => Math.max(prev - 1, 0))
                    }
                  />
                  {Array.from({ length: pageCount }, (_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index === pageNumber}
                      onClick={() => setPageNumber(index)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                    }
                  />
                  <Pagination.Last
                    onClick={() => setPageNumber(pageCount - 1)}
                  />
                </Pagination>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-8">
                {displayPage?.map((item, i) => {
                  return (
                    <>
                      <h2>{item?.SCat}</h2>
                      <div
                        style={{
                          boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="row pt-3">
                          <div className="col-md-5 ">
                            <img
                              src={`https://kanagamultiservice.in/Services/${item?.SImage}`}
                              alt=""
                              style={{ width: "100%", height: "210px" }}
                            />
                          </div>
                          <div className="col-md-7">
                            <div className="row">
                              <div className="col-md-12">
                                <h4
                                  style={{ color: "#FE5E00", fontSize: "25px" }}
                                >
                                  <strong>{item?.SHeader}</strong>
                                </h4>
                              </div>
                              <div className="col-md-12 ">
                                <p style={{ textAlign: "justify" }}>
                                  <p style={{ textAlign: "justify" }}>
                                    {SelectedId === item?._id && Viewmore
                                      ? parse(`<div>${item?.SDesription}</div>`)
                                      : parse(
                                          `<div>${item?.SDesription.slice(
                                            0,
                                            150
                                          )}</div>`
                                        )}
                                  </p>
                                  {SelectedId === item?._id && Viewmore ? (
                                    <>
                                      <div style={{ float: "right" }}>
                                        <a
                                          onClick={() => {
                                            setSelectedId();
                                            setViewmore(!Viewmore);
                                          }}
                                          style={{
                                            color: "blue",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Show less...
                                        </a>
                                      </div>
                                    </>
                                  ) : (
                                    <div style={{ float: "right" }}>
                                      <a
                                        onClick={() => {
                                          setSelectedId(item?._id);
                                          setViewmore(!Viewmore);
                                        }}
                                        style={{
                                          color: "blue",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Show more...
                                      </a>
                                    </div>
                                  )}
                                </p>
                                <div className="col-md-12">
                                  <div className=" pb-3 ">
                                    <div className="p-1 ">
                                      <Button
                                        className="  btn-danger p-1"
                                        onClick={() => handleShow(item)}
                                        style={{ backgroundColor: "#FE5E00" }}
                                      >
                                        Enquire Now
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="text-end">
                <Pagination style={{ float: "right" }}>
                  <Pagination.First onClick={() => setPageNumber(0)} />
                  <Pagination.Prev
                    onClick={() =>
                      setPageNumber((prev) => Math.max(prev - 1, 0))
                    }
                  />
                  {Array.from({ length: pageCount }, (_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index === pageNumber}
                      onClick={() => setPageNumber(index)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                    }
                  />
                  <Pagination.Last
                    onClick={() => setPageNumber(pageCount - 1)}
                  />
                </Pagination>
              </div>
            </>
          )}
        </div>

        <Modal
          size="lg"
          style={{ zIndex: "99999" }}
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#FE5E00" }}>Enquire Now</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form ref={form} 
            onSubmit={sendEmail}
            >
              <Row>
                <Col>
                  <Form.Control
                  type="text"
                    placeholder="First name"
                    id="user_Fname"
                    name="user_Fname"
                    onChange={(e) => setSFName(e.target.value)}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                   type="text"
                    placeholder="Last name"
                    id="user_Lname"
                    name="user_Lname"
                    onChange={(e) => setSLName(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Control type="email"
                    placeholder="Email"
                    id="user_email"
                    name="user_email"
                    onChange={(e) => setSEmail(e.target.value)}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Mobile"
                    name="user_phone"
                    onChange={(e) => setSPhone(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Destination From"
                    onChange={(e) => setSDesiFrom(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Destination To"
                    onChange={(e) => setSDesiTo(e.target.value)}
                  />
                </Col>
              </Row>
              <br />
              <Form.Control
                as="textarea"
                type="text"
                placeholder="Message"
                name="user_message"
                style={{ height: "100px" }}
                onChange={(e) => setSRquire(e.target.value)}
              />
              <br />
              <div className="d-flex justify-content-end gap-3">
              <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
              <Button
            type="submit"
            value="Send"
              variant="btn-danger"
              style={{ backgroundColor: "#FE5E00", color: "white" }}
              onClick={EnquiryS}
              // state={{ item: item }}
            >
              Send Request
            </Button>
              </div>
            </Form>
          </Modal.Body>
          
        </Modal>
      </div>
    </div>
  );
};

export default Services;
