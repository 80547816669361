import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SiSmartthings } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import {
  MdOutlineContactPage,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlinePages,
  MdThumbUpAlt,
} from "react-icons/md";
import { BsFillTelephoneFill, BsQuestionLg } from "react-icons/bs";
import { AiFillSetting, AiOutlineHome } from "react-icons/ai";
import { FaLink } from "react-icons/fa";
import axios from "axios";
import { useEffect } from "react";

function SideBar() {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);
  const [acc9, setacc9] = useState(false);
  const [acc10, setacc10] = useState(false);
  const [acc11, setacc11] = useState(false);
  const [acc12, setacc12] = useState(false);
  const [acc13, setacc13] = useState(false);
  const [acc14, setacc14] = useState(false);
  const [acc15, setacc15] = useState(false);
  const [acc16, setacc16] = useState(false);
  const [acc17, setacc17] = useState(false);
  const [acc18, setacc18] = useState(false);
  const [acc19, setacc19] = useState(false);
  const [acc20, setacc20] = useState(false);
  const [acc21, setacc21] = useState(false);
  const [acc22, setacc22] = useState(false);
  const [acc23, setacc23] = useState(false);
  const [acc24, setacc24] = useState(false);
  const [acc25, setacc25] = useState(false);
  const [acc26, setacc26] = useState(false);
  const [acc27, setacc27] = useState(false);
  const [acc28, setacc28] = useState(false);
  const [acc29, setacc29] = useState(false);
  const [acc30, setacc30] = useState(false);
  const [acc31, setacc31] = useState(false);
  const [acc32, setacc32] = useState(false);
  const [acc33, setacc33] = useState(false);
  const [acc34, setacc34] = useState(false);
  const [acc35, setacc35] = useState(false);
  const [acc36, setacc36] = useState(false);
  const [acc37, setacc37] = useState(false);
  const [acc38, setacc38] = useState(false);
  const [acc39, setacc39] = useState(false);
  const [acc40, setacc40] = useState(false);
  const [acc41, setacc41] = useState(false);
  const [acc42, setacc42] = useState(false);
  const [gallery, setgallery] = useState(false);
  const [Branch, setBranch] = useState(false);
  const [service, setservice] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [bookS, setbookS] = useState(false);
  const [timeSlot, settimeSlot] = useState(false);
  const [opening, setopening] = useState(false);
  const [Enquires, setEnquires] = useState(false);
  const [ratting, setratting] = useState(false);
  const [Leaves, setLeave] = useState(false);
  const [FAQ, setFAQ] = useState(false);
  const [SafetyRule, setSafetyRule] = useState(false);
  const [vehicleT, setvehicleT] = useState(false);
  const [additional, setadditional] = useState(false);
  const [expanse, setexpanse] = useState(false);
  const [webManagement, setwebManagement] = useState(false);
  const [vehiclemanagement, setvehiclemanagement] = useState(false);
  const [servicelist, setservicelist] = useState(false);

  const [courses, setCourses] = useState(false);
  const [services, setServices] = useState(false);
  const [HomePartners, setHomePartners] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [Home, setHome] = useState(false);
  const [RtoApp, setRtoApp] = useState(false);
  const [form, setForm] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const [AddEnquiry, setAddEnquiry] = useState([]);
  const getAddEnquiry = async () => {
    try {
      let res = await axios.get("https://kanagamultiservice.in/api/user/getallenquiry");
      if (res.status === 200) {
        setAddEnquiry(res.data.getenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AddpEnquiry, setAddpEnquiry] = useState([]);
  const getAddpEnquiry = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/user/getallPenquiry"
      );
      if (res.status === 200) {
        setAddpEnquiry(res.data.getPenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AddEnquiryS, setAddEnquiryS] = useState([]);
  const getAddEnquirys = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/user/getallSenquiry"
      );
      if (res.status === 200) {
        setAddEnquiryS(res.data.getSenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AddEnquiryE, setAddEnquiryE] = useState([]);
  const getAddEEnquirys = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/user/getallEenquiry"
      );
      if (res.status === 200) {
        setAddEnquiryE(res.data.getEenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AddJEnquiry, setJAddEnquiry] = useState([]);
  const getAddJEnquiry = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/user/getallJenquiry"
      );
      if (res.status === 200) {
        setJAddEnquiry(res.data.getJenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const [AddComment, setAddCommnet] = useState([]);
  // const getAddComment = async () => {
  //   try {
  //     let res = await axios.get("https://kanagamultiservice.in/api/user/getallComment");
  //     if (res.status == 200) {
  //       setAddCommnet(res.data.getCommentnow);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    getAddEnquiry();
    getAddpEnquiry();
    getAddEnquirys();
    getAddEEnquirys();
    getAddJEnquiry();
    // getAddComment();
  }, []);
  console.log(AddEnquiry);
  console.log(AddpEnquiry);
  console.log(AddEnquiryS);
  // console.log(AddComment);
  return (
    <div className="si09">
      <div className="lo-ad">
        {/* <img
          src="footer-logo.png"
          alt="adminlogo"
          style={{ width: "200px", height: "80px", padding: "10px 11px" }}
        /> */}
         <img
              src="kanag-logo.jpg"
              alt="adminlogo"
              style={{ width: "250px", height: "122px",padding:"10px 11px" }}
            />
      </div>
      <ul className="">
        <Link to="">
          <li
            className={`a-ele ${acc3 ? "active-0" : "null"}`}
            onClick={() => {
              setHome(!Home);
              setEmployee(false);
              setServices(false);
              setHomePartners(false);
              setvehiclemanagement(false);
              setwebManagement(false);
              setCourses(false);
              setForm(false);
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(true);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc11(false);
            }}
          >
            <span>
              <AiOutlineHome style={{ fontSize: "20px" }} />
            </span>{" "}
            <span>Home </span>{" "}
            {Home ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowUp />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>
        <Link to="">
          {Home ? (
            <>
              <div className="webmanagement">
                <Link to="/Admin_home_graph">
                  <li
                    className={`a-ele ${acc25 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(true);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-list-ul"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Graph</span>
                  </li>
                </Link>
                <Link to="/Admin_homeservice">
                  <li
                    className={`a-ele ${acc41 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(true);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(true);
                      setacc42(false);
                    }}
                  >
                    <span>
                      {" "}
                      <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                    </span>
                    <span className="ms-2">Services </span>{" "}
                  </li>
                </Link>
                <Link to="/Admin_whychooseus">
                  <li
                    className={`a-ele ${acc6 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(true);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(true);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      {" "}
                      <BsQuestionLg style={{ fontSize: "20px" }} />
                    </span>
                    <span className="ms-2">Why Choose Us?</span>{" "}
                  </li>
                </Link>
                <Link to="/Admin_countries">
                  <li
                    className={`a-ele ${acc7 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(true);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(true);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      {" "}
                      <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                    </span>
                    <span className="ms-2">Countries Worked With </span>{" "}
                  </li>
                </Link>
              
              </div>
            </>
          ) : (
            ""
          )}
        </Link>
        <Link to="">
          <li
            className={`a-ele ${acc ? "active-0" : "null"}`}
            onClick={() => {
              setCourses(!courses);
              setvehiclemanagement(false);
              setwebManagement(false);
              setServices(false);
              setHomePartners(false);
              setEmployee(false);
              setHome(false);
              setForm(false);
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc11(false);
            }}
          >
            <span>
              <i class="fa-solid fa-book-open" style={{ fontSize: "18px" }}></i>
            </span>{" "}
            <span>About us </span>{" "}
            {courses ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowUp />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>
        <Link to="">
          {courses ? (
            <>
              <div className="webmanagement">
                <Link to="/admin_about_us">
                  <li
                    className={`a-ele ${acc4 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(true);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-info ms-1"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span
                      className={`a-ele ${acc5 ? "active2" : "null"}`}
                      onClick={() => {
                        setacc(false);
                        setacc1(false);
                        setacc2(false);
                        setacc3(false);
                        setacc4(false);
                        setacc5(true);
                        setacc6(false);
                        setacc7(false);
                        setacc8(false);
                        setacc9(false);
                        setacc10(false);
                        setacc11(false);
                        setacc12(false);
                        setacc13(false);
                        setacc14(false);
                        setacc15(false);
                        setacc16(false);
                        setacc17(false);
                        setacc18(false);
                        setacc19(false);
                        setacc20(false);
                        setacc21(false);
                        setacc22(false);
                        setacc23(false);
                        setacc24(false);
                        setacc25(false);
                        setacc26(false);
                        setacc27(false);
                        setacc28(false);
                        setacc29(false);
                        setacc30(false);
                        setacc31(false);
                        setacc32(false);
                        setacc33(false);
                        setacc34(false);
                        setacc35(false);
                        setacc36(false);
                        setacc37(false);
                        setacc38(false);
                        setacc39(false);
                        setacc40(false);
                        setacc41(false);
                        setacc42(false);
                      }}
                    >
                      {" "}
                      About Us
                    </span>
                  </li>
                </Link>
                <Link to="/admin_successtory">
                  <li
                    className={`a-ele ${acc4 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(true);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <MdThumbUpAlt />
                    </span>
                    <span
                      className={`a-ele ${acc5 ? "active2" : "null"}`}
                      onClick={() => {
                        setacc(false);
                        setacc1(false);
                        setacc2(false);
                        setacc3(false);
                        setacc4(false);
                        setacc5(false);
                        setacc6(true);
                        setacc7(false);
                        setacc8(false);
                        setacc9(false);
                        setacc10(false);
                        setacc11(false);
                        setacc12(false);
                        setacc13(false);
                        setacc14(false);
                        setacc15(false);
                        setacc16(false);
                        setacc17(false);
                        setacc18(false);
                        setacc19(false);
                        setacc20(false);
                        setacc21(false);
                        setacc22(false);
                        setacc23(false);
                        setacc24(false);
                        setacc25(false);
                        setacc26(false);
                        setacc27(false);
                        setacc28(false);
                        setacc29(false);
                        setacc30(false);
                        setacc31(false);
                        setacc32(false);
                        setacc33(false);
                        setacc34(false);
                        setacc35(false);
                        setacc36(false);
                        setacc37(false);
                        setacc38(false);
                        setacc39(false);
                        setacc40(false);
                        setacc41(false);
                        setacc42(false);
                      }}
                    >
                      {" "}
                      Our Success Story
                    </span>
                  </li>
                </Link>
                <Link to="/admin_certificates">
                  <li
                    className={`a-ele ${acc4 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(true);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <MdOutlinePages />
                    </span>
                    <span
                      className={`a-ele ${acc5 ? "active2" : "null"}`}
                      onClick={() => {
                        setacc(false);
                        setacc1(false);
                        setacc2(false);
                        setacc3(false);
                        setacc4(false);
                        setacc5(false);
                        setacc6(true);
                        setacc7(false);
                        setacc8(false);
                        setacc9(false);
                        setacc10(false);
                        setacc11(false);
                        setacc12(false);
                        setacc13(false);
                        setacc14(false);
                        setacc15(false);
                        setacc16(false);
                        setacc17(false);
                        setacc18(false);
                        setacc19(false);
                        setacc20(false);
                        setacc21(false);
                        setacc22(false);
                        setacc23(false);
                        setacc24(false);
                        setacc25(false);
                        setacc26(false);
                        setacc27(false);
                        setacc28(false);
                        setacc29(false);
                        setacc30(false);
                        setacc31(false);
                        setacc32(false);
                        setacc33(false);
                        setacc34(false);
                        setacc35(false);
                        setacc36(false);
                        setacc37(false);
                        setacc38(false);
                        setacc39(false);
                        setacc40(false);
                        setacc41(false);
                        setacc42(false);
                      }}
                    >
                      {" "}
                      Our Certificates
                    </span>
                  </li>
                </Link>

                <Link to="/Admin_count">
                  <li
                    className={`a-ele ${acc8 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(true);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa fa-calculator ms-1"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Counts</span>
                  </li>
                </Link>
                <Link to="/Admin_ourteam">
                  <li
                    className={`a-ele ${acc18 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(true);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-check"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2"> Our Team</span>
                  </li>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </Link>

        <Link to="/Admin_service">
          <li
            className={`a-ele ${acc11 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(true);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc42(false);
            }}
          >
            <span>
              <AiFillSetting style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Services</span>
          </li>
        </Link>
        <Link to="/Admin_products">
          <li
            className={`a-ele ${acc42 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc42(true);
            }}
          >
            <span>
              <SiSmartthings style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Products</span>
          </li>
        </Link>
        <Link to="/Admin_events">
          <li
            className={`a-ele ${acc30 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(true);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i class="fa-solid fa-blog" style={{ fontSize: "20px" }}></i>
            </span>
            <span className="ms-2">Events</span>
          </li>
        </Link>
        <Link to="/Admin_Gallery">
          <li
            className={`a-ele ${acc13 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(true);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i class="fa-solid fa-image" style={{ fontSize: "20px" }}></i>
            </span>{" "}
            <span className="ms-1">Gallery</span>
          </li>
        </Link>
        <Link to="/Admin_ourbrochure">
          <li
            className={`a-ele ${acc2 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(true);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
            }}
          >
            <span>
              <MdOutlineContactPage style={{ fontSize: "22px" }} />
            </span>
            <span className="ms-2">Our Brochures</span>
          </li>
        </Link>

        <Link to="/Admin_trusted_parnets">
          <li
            className={`a-ele ${acc14 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(true);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
            }}
          >
            <span>
              <IoIosPeople style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Our Partners</span>
          </li>
        </Link>

        <Link to="/Enquiry">
          <li
            className={`a-ele ${acc32 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(true);
              setacc33(false);
            }}
          >
            <span>
              <i
                class="fa fa-question-circle"
                aria-hidden="true"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2">
              Enquires{" "}
              <span style={{ float: "right" }}>({AddEnquiry?.length})</span>
            </span>
          </li>
        </Link>
        <Link to="/Service_enquiry">
          <li
            className={`a-ele ${acc10 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(true);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
            }}
          >
            <span>
              <i
                class="fa fa-question-circle"
                aria-hidden="true"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2">
              Service Enquiry{" "}
              <span style={{ float: "right" }}>({AddEnquiryS?.length})</span>{" "}
            </span>
          </li>
        </Link>
        <Link to="/Products_Enquiry">
          <li
            className={`a-ele ${acc9 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(true);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
            }}
          >
            <span>
              <i
                class="fa fa-question-circle"
                aria-hidden="true"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2">
              Product Enquiry{" "}
              <span style={{ float: "right" }}>({AddpEnquiry?.length})</span>{" "}
            </span>
          </li>
        </Link>
        <Link to="/Admin_eventenquiry">
          <li
            className={`a-ele ${acc15 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(true);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
            }}
          >
            <span>
              <i
                class="fa fa-question-circle"
                aria-hidden="true"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2">
              Event Enquiry{" "}
              <span style={{ float: "right" }}>({AddEnquiryE?.length})</span>{" "}
            </span>
          </li>
        </Link>
        <Link to="/Admin_joinpartnersrequests">
          <li
            className={`a-ele ${acc20 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(true);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
            }}
          >
            <span>
              <i
                class="fa fa-question-circle"
                aria-hidden="true"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2">
              Join Partners Requests{" "}
              <span style={{ float: "right" }}>({AddJEnquiry?.length})</span>{" "}
            </span>
          </li>
        </Link>

        <Link to="/admin_contactus">
          <li
            className={`a-ele ${acc12 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(true);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
            }}
          >
            <span>
              <BsFillTelephoneFill style={{ fontSize: "18px" }} />
            </span>
            <span className="ms-2">Contact Us</span>
          </li>
        </Link>

        <Link to="/admin_socialmedias">
          <li
            className={`a-ele ${acc16 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(true);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
            }}
          >
            <span>
              <FaLink style={{ fontSize: "18px" }} />
            </span>
            <span className="ms-2">Social Medias</span>
          </li>
        </Link>
      </ul>
    </div>
  );
}

export default SideBar;
