import React, { useEffect, useState } from "react";
import { Button, Table, Image, Pagination, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { IoEye } from "react-icons/io5";
import exportFromJSON from "export-from-json";
import * as XLSX from "xlsx";
import ReactPaginate from "react-paginate";

const AdminProducts = () => {
  const [View, setView] = useState({});
  const [show100, setShow100] = useState(false);
  const handleClose100 = () => setShow100(false);
  const handleShow100 = () => setShow100(true);

  const [product, setProduct] = useState(true);
  const [ProList, setProList] = useState(false);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setPDesc(data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // integrating post method
  const formdata = new FormData();
  const [PImage, setPImage] = useState("");
  const [Image1, setImage1] = useState("");
  const [Image2, setImage2] = useState("");
  const [Image3, setImage3] = useState("");
  const [Image4, setImage4] = useState("");
  const [PCat, setPCat] = useState("");
  const [PName, setPName] = useState("");
  const [PPrice, setPPrice] = useState("");
  const [PQuantity, setPQuantity] = useState("");
  const [POrigin, setPOrigin] = useState("");
  const [PDesc, setPDesc] = useState("");
  const AddProducts = async () => {
    if(!PImage) return alert("Please Select the Image1");
    if(!Image1) return alert("Please Select the Image2");
    if(!Image2) return alert("Please Select the Image3");
    if(!Image3) return alert("Please Select the Image4");
    if(!Image4) return alert("Please Select the Image5");
    if(!PName) return alert("Please Enter the Name");
    if(!PCat) return alert("Please Select the Category");
    if(!POrigin) return alert("Please Enter the Origin");
    if(!PDesc) return alert("Please Enter the Description");
    formdata.set("PImage", PImage);
    formdata.append("Image1", Image1);
    formdata.append("Image2", Image2);
    formdata.append("Image3", Image3);
    formdata.append("Image4", Image4);
    formdata.set("PCat", PCat);
    formdata.set("PName", PName);
    formdata.set("PPrice", PPrice);
    formdata.set("PQuantity", PQuantity);
    formdata.set("POrigin", POrigin);
    formdata.set("PDesc", PDesc);

    try {
      const config = {
        url: "/admin/Products",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getProducts();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [nochangedataH, setnochangedataH] = useState([]);
  // const [AddProductss, setAddProductss] = useState([]);
  const getProducts = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallProducts"
      );
      if (res.status === 200) {
        setAddProductss(res.data.getAddProducts);
        setnochangedataH(res.data.getAddProducts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteProducts = async () => {
    try {
      const config = {
        url: "admin/DeleteAddProducts/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "multipart/form-data" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getProducts();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setPName(item?.PName);
    setPCat(item?.PCat);
    // setPPrice(item?.PPrice);
    // setPQuantity(item?.PQuantity);
    setPOrigin(item?.POrigin);
    setPDesc(item?.PDesc);
  };

  const editProducts = async (e) => {
    e.preventDefault();
    formdata.set("PImage", PImage);
    formdata.append("Image1", Image1);
    formdata.append("Image2", Image2);
    formdata.append("Image3", Image3);
    formdata.append("Image4", Image4);
    formdata.set("PName", PName);
    formdata.set("PCat", PCat);
    formdata.set("PPrice", PPrice);
    formdata.set("PQuantity", PQuantity);
    formdata.set("POrigin", POrigin);
    formdata.set("PDesc", PDesc);
    formdata.set("id", Data1?._id);
    try {
      const config = {
        url: "/admin/editAddProducts",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getProducts();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // Product Category
  const formdata1 = new FormData();
  const [PIocn, setPIocn] = useState("");
  const [Pcat, setPcat] = useState("");
  const AddProductCat = async () => {
    formdata1.set("PIocn", PIocn);
    formdata1.set("Pcat", Pcat);
    try {
      if(!PIocn) return alert("Please Select the Icon");
      if(!Pcat) return alert("Please Enter the Product Type");
      const config = {
        url: "/admin/addProductCategories",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose4();
          getAddProductcate();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating category get method
  const [nochangedata, setnochangedata] = useState([]);
  const [AddProductcate, setAddProductcate] = useState([]);
  const getAddProductcate = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddProductCategories"
      );
      if (res.status == 200) {
        setAddProductcate(res.data.getadddProductCategories);
        setnochangedata(res.data.getadddProductCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating categories delete method
  const [Data2, setData2] = useState("");
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = (item) => {
    setShow10(true);
    setData2(item);
  };
  const deleteProducCat = async () => {
    try {
      const config = {
        url: "admin/deleteaddProductCategories/" + Data2,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        headers: { "content-type": "multipart/form-data" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getAddProductcate();
          handleClose10();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating categories update method
  const [Aexp, setAexp] = useState("");
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = (item) => {
    setShow6(true);
    setAexp(item);
    setPcat(item?.Pcat);
  };
  const editProductcat = async (e) => {
    formdata1.set("PIocn", PIocn);
    formdata1.set("Pcat", Pcat);
    formdata1.set("id", Aexp?._id);
    e.preventDefault();
    try {
      const config = {
        url: "/admin/updateProductCategories",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Updated");
          getAddProductcate();
          handleClose6();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getProducts();
    getAddProductcate();
  }, []);

  const [Viewmore, setViewmore] = useState(true);
  const [SelectedId, setSelectedId] = useState();

 

  // Pagination for products
  const [pageNumberS, setPageNumberS] = useState(0);
  const [AddProductss, setAddProductss] = useState([]);

  const productPerPageS = 5;
  const visitedPageS = pageNumberS * productPerPageS;
  const displayPageS = AddProductss?.slice(
    visitedPageS,
    visitedPageS + productPerPageS
  );

  const pageCountS = Math?.ceil((AddProductss?.length || 0) / productPerPageS);


  // const [pageNumber, setPageNumber] = useState(0);
  // const usersPerPage = 10;
  // const pagesVisited = pageNumber * usersPerPage;
  // const pageCount = Math.ceil(AddProductss.length / usersPerPage);
  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  // };
  // Pagination for product category
  const [pageNumberP, setPageNumberP] = useState(0);
  const productPerPageP = 5;
  const visitedPageP = pageNumberP * productPerPageP;
  const displayPageP = AddProductcate?.slice(
    visitedPageP,
    visitedPageP + productPerPageP
  );
  const pageCountP = Math.ceil(AddProductcate.length / productPerPageP);

  //search filter for product category
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAddProductcate([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAddProductcate([...nochangedata]);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  const searchedProduct = AddProductcate.filter((item) => {
    if (searchTerm.value === "") {
      return item;
    }
    if (item?.ConAddress?.toLowerCase().includes(searchTerm?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });

  //search filter for products list
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAddProductss([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAddProductss([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = AddProductss?.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.ConAddress?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });

  //Export Excel===================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Products List");

  const ExportToExcel = () => {
    nochangedataH.map((item, i) => {
      let obj = {
        "S.No": i + 1,
        Image: item?.PImage,
        Category: item?.PCat,
        "Product Name": item?.PName,
        "Place Of Origin": item?.POrigin,
        Description: item?.PDesc,
      };
      data.push(obj);
    });

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  // Excel Import
  const [excel, setexcel] = useState();
  const [exceldata, setexceldata] = useState([]);

  function convertToJson(csv) {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        // console.log(currentline[j]?.replace(/_/g, ","));
        obj[headers[j]] = currentline[j]?.replace(/_/g, ",");
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }
  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setexceldata(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }
  const postorders = async (e) => {
    e.preventDefault();

    if (excel) {
      try {
        const config = {
          url: "/admin/addmultipleproduct",
          method: "post",
          baseURL: "https://kanagamultiservice.in/api",
          headers: { "content-type": "application/json" },
          data: {
            product: exceldata.map((datas) => ({
              PImage: datas.Image1,
              Image1: datas.Image2,
              Image2: datas.Image3,
              Image3: datas.Image4,
              Image4: datas.Image5,
              PName: datas.ProductName,
              PCat: datas.ProductCategory,
              POrigin: datas.Place_Of_Origin,
              PDesc: datas.Description,
            })),
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert(res.data.success);
          getAddProductcate();
          setexcel("");
          return res;
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please select a file");
    }
  };

  if (excel && exceldata.length === 0) {
    readFile();
  }

  //Upload Images
  const [image, setimage] = useState("");

  const postimage = async (e) => {
    e.preventDefault();
    Array.from(image).forEach((photo) => {
      formdata.append("P_image1", photo);
    });
    if (image) {
      try {
        const config = {
          url: "/admin/images",
          method: "post",
          baseURL: "https://kanagamultiservice.in/api",

          data: formdata,
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          alert("Images Uploaded Successfully");
          getAddProductcate();
          return res;
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please select a images");
    }
  };
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="ad-b mt-4 mb-4 col-md-12">
              <button
                onClick={() => {
                  setProduct(true);
                  setProList(false);
                }}
              >
                Products List
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setProduct(false);
                  setProList(true);
                }}
              >
                Products Category
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra ms-5">
          {product ? (
            <>
              <div className="container">
                <div className="row mb-2">
                  <div className=" col-lg-12 mt-2 d-flex gap-1">
                    <div className="add-b">
                      <a href="addproduct.xlsx">
                        <Button variant="outline-info">Download Excel</Button>
                      </a>
                    </div>
                    <div className="add-b">
                     
                      <label
                        className="btn btn-success"
                        htmlFor="icon-button-file"
                        style={{
                          backgroundColor: "#0A700B",
                          border: "1px solid #0A700B",
                          borderRadius: "0px",
                        }}
                      >
                        {" "}
                        Import Excel
                      </label>
                      <input
                        accept=".xlsx,.xls"
                        style={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        onChange={(e) => setexcel(e.target.files[0])}
                      />
                    </div>
                    <div className="add-b">
                      <button onClick={(e) => postorders(e)}>Upload</button>
                    </div>
                    <div className="add-b">
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="icon-button-file1"
                        type="file"
                        multiple
                        onChange={(e) => setimage(e.target.files[0])}
                      />
                      <label
                        className="btn btn-success "
                        htmlFor="icon-button-file1"
                        style={{
                          backgroundColor: "#0A700B",
                          border: "1px solid #0A700B",
                          borderRadius: "0px",
                        }}
                      >
                        {" "}
                        Select Images
                      </label>
                    </div>
                    <div className="add-b">
                      <button onClick={(e) => postimage(e)}>
                        Upload Images
                      </button>
                    </div>
                    <div className="add-b">
                      <button onClick={ExportToExcel}>Export Excel</button>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div
                    className="col-8 col-sm-8 col-md-8 col-lg-8"
                    style={{ paddingLeft: "unset" }}
                  >
                    <Form inline>
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className=" mr-sm-2"
                        // onChange={handleFilterH}
                      />
                    </Form>
                  </div>
                 
                  <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                Products Lists 
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                        setPImage("");
                        setImage1("");
                        setImage2("");
                        setImage3("");
                        setPName("");
                        setPCat("");
                        setPOrigin("");
                        setPDesc("");
                        handleShow()}}>+ Add Products List</button>
                  </div>
                </div>
                </div>
              </div>

              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Category</th>
                    <th>Product Name</th>
                    {/* <th>Price</th>
                    <th>Min Order Quantity</th> */}
                    <th>Place Of Origin</th>
                    <th>Description</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {displayPageS?.map((item, i) => {
                    const serialNumber = i + 1 + visitedPageS;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>
                          <button className="btn btn-success">
                            <IoEye
                              style={{ fontSize: "20px" }}
                              onClick={() => {
                                handleShow100();
                                setView(item);
                              }}
                            />
                          </button>
                          {/* <Image
                            src={`https://kanagamultiservice.in/Products/${item?.PImage}`}
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          /> */}
                        </td>

                        <td style={{ paddingTop: "20px" }}>{item?.PCat}</td>
                        <td style={{ paddingTop: "20px" }}>{item?.PName}</td>
                        {/* <td style={{ paddingTop: "20px" }}>{item?.PPrice}</td>
                        <td style={{ paddingTop: "20px" }}>
                          {item?.PQuantity}
                        </td> */}
                        <td style={{ paddingTop: "20px" }}>{item?.POrigin}</td>
                        <td style={{ paddingTop: "20px" }}>
                          {/* {parse(`<div>${item?.PDesc}</div>`)} */}

                          {SelectedId === item?._id && Viewmore
                            ? parse(`<div>${item?.PDesc}</div>`)
                            : parse(`<div>${item?.PDesc?.slice(0, 270)}</div>`)}
                          {SelectedId === item?._id && Viewmore ? (
                            <>
                              <div
                                style={{ float: "right", marginTop: "-10px" }}
                              >
                                <a
                                  onClick={() => {
                                    setSelectedId();
                                    setViewmore(!Viewmore);
                                  }}
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  Show less...
                                </a>
                              </div>
                            </>
                          ) : (
                            <div style={{ float: "right", marginTop: "-10px" }}>
                              <a
                                onClick={() => {
                                  setSelectedId(item?._id);
                                  setViewmore(!Viewmore);
                                }}
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Show more...
                              </a>
                            </div>
                          )}
                        </td>

                        <td>
                          <button className="btn btn-success">
                            <AiTwotoneEdit onClick={() => handleShow1(item)} />
                          </button>
                        </td>
                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleShow2(item?._id);
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              
              <Pagination style={{ float: "right" }}>
                <Pagination.First onClick={() => setPageNumberS(0)} />
                <Pagination.Prev
                  onClick={() =>
                    setPageNumberS((prev) => Math.max(prev - 1, 0))
                  }
                />
                {Array.from({ length: pageCountS }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === pageNumberS}
                    onClick={() => setPageNumberS(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setPageNumberS((prev) => Math.min(prev + 1, pageCountS - 1))
                  }
                />
                <Pagination.Last
                  onClick={() => setPageNumberS(pageCountS - 1)}
                />
              </Pagination>
               {/* <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              /> */}
            </>
          ) : (
            <>
              {ProList ? (
                <>
                  <div className="container">
                    <div className="row mb-1">
                      <div
                        className="col-8 col-sm-8 col-md-8 col-lg-8"
                        style={{ paddingLeft: "unset" }}
                      >
                        <Form inline>
                          <Form.Control
                            type="search"
                            placeholder="Search"
                            className=" mr-sm-2"
                            onChange={handleFilter}
                          />
                        </Form>
                      </div>
                      <div className="col-md-4">
                        <div className="add-b text-end mb-1 col-md-12">
                         
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                Products Category List
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                            setPIocn("");
                            setPCat("");
                          handleShow4()}}>
                            + Add Products Category List
                          </button>
                  </div>
                </div>
                    </div>
                  </div>

                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Product Category</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayPageP.map((item, i) => {
                        const serialNumberP = i + 1 + visitedPageP;
                        return (
                          <tr>
                            <td>{serialNumberP}</td>

                            <td>
                              <Image
                                // src=""
                                src={`https://kanagamultiservice.in/ProductCategories/${item?.PIocn}`}
                                alt="pic"
                                style={{ width: "75px", height: "75px" }}
                              />
                            </td>
                            <td>{item?.Pcat}</td>
                            <td>
                              <button className="btn btn-success">
                                <AiTwotoneEdit
                                  onClick={() => handleShow6(item)}
                                />
                              </button>
                            </td>
                            <td>
                              <button className="btn btn-danger">
                                <AiTwotoneDelete
                                  style={{
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    handleShow10(item?._id);
                                  }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <Pagination style={{ float: "right" }}>
                    <Pagination.First onClick={() => setPageNumberP(0)} />
                    <Pagination.Prev
                      onClick={() =>
                        setPageNumberP((prev) => Math.max(prev - 1, 0))
                      }
                    />
                    {Array.from({ length: pageCountP }, (_, index) => (
                      <Pagination.Item
                        key={index}
                        active={index === pageNumberP}
                        onClick={() => setPageNumberP(index)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() =>
                        setPageNumberP((prev) =>
                          Math.min(prev + 1, pageCountP - 1)
                        )
                      }
                    />
                    <Pagination.Last
                      onClick={() => setPageNumberP(pageCountP - 1)}
                    />
                  </Pagination>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <Modal show={show100} onHide={handleClose100}>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6 p-3">
                <Image
                  src={`https://kanagamultiservice.in/Products/${View?.PImage}`}
                  alt="pic"
                  style={{ width: "180px", height: "150px" }}
                />
              </div>
              <div className="col-md-6 p-3">
                <Image
                  src={`https://kanagamultiservice.in/Products/${View?.Image1}`}
                  alt="pic"
                  style={{ width: "180px", height: "150px" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 p-3">
                <Image
                  src={`https://kanagamultiservice.in/Products/${View?.Image2}`}
                  alt="pic"
                  style={{ width: "180px", height: "150px" }}
                />
              </div>
              <div className="col-md-6 p-3">
                <Image
                  src={`https://kanagamultiservice.in/Products/${View?.Image3}`}
                  alt="pic"
                  style={{ width: "180px", height: "150px" }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 p-3">
                <Image
                  src={`https://kanagamultiservice.in/Products/${View?.Image4}`}
                  alt="pic"
                  style={{ width: "180px", height: "150px" }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose100}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image1</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setPImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image2</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setImage1(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image3</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setImage2(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image4</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setImage3(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image5</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setImage4(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Product Name</label>
            <input
              type="text"
              placeholder="Enter Product Name "
              className="vi_0"
              onChange={(e) => setPName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Category</label>
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => setPCat(e.target.value)}
            >
              <option value="">----Select Category----</option>
              {AddProductcate?.map((item) => {
                return <option value={item?.Pcat}>{item?.Pcat}</option>;
              })}
            </select>
          </div>
          {/* <div className="do-sear mt-2">
            <label>Product Price</label>
            <input
              type="text"
              placeholder="Enter Price"
              className="vi_0"
              onChange={(e) => setPPrice(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Product Minimum Quantity</label>
            <input
              type="text"
              placeholder="Enter Product Quantity"
              className="vi_0"
              onChange={(e) => setPQuantity(e.target.value)}
            />
          </div> */}
          <div className="do-sear mt-2">
            <label>Product Place Of Origin</label>
            <input
              type="text"
              placeholder="Enter Place of Origin"
              className="vi_0"
              onChange={(e) => setPOrigin(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              // data={PDesc}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddProducts}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Icon</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setPIocn(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Product Category</label>
            <input
              type="text"
              placeholder="Enter Product Category"
              className="vi_0"
              onChange={(e) => setPcat(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddProductCat}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>Update Product Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Icon</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setPIocn(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Product Category</label>
            <input
              type="text"
              placeholder="Enter Product Category"
              className="vi_0"
              value={Pcat}
              onChange={(e) => setPcat(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editProductcat}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Products </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image1</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setPImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image2</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setImage1(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image3</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setImage2(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image4</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setImage3(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image5</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setImage4(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Category</label>
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => setPCat(e.target.value)}
            >
              <option value="">----Select Category----</option>
              {AddProductcate?.map((item) => {
                return <option value={item?.Pcat}>{item?.Pcat}</option>;
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Product Name</label>
            <input
              type="text"
              placeholder="Enter Product Name "
              className="vi_0"
              value={PName}
              onChange={(e) => setPName(e.target.value)}
            />
          </div>
          {/* <div className="do-sear mt-2">
            <label>Price</label>
            <input
              type="text"
              placeholder="Enter Price"
              className="vi_0"
              value={PPrice}
              onChange={(e) => setPPrice(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Minimun Order Quantity</label>
            <input
              type="text"
              placeholder="Enter Quantity"
              className="vi_0"
              value={PQuantity}
              onChange={(e) => setPQuantity(e.target.value)}
            />
          </div> */}
          <div className="do-sear mt-2">
            <label>Place Of Origin</label>
            <input
              type="text"
              placeholder="Enter Place Of Origin"
              className="vi_0"
              value={POrigin}
              onChange={(e) => setPOrigin(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={PDesc}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editProducts}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={DeleteProducts}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show10} onHide={handleClose10}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose10}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteProducCat}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminProducts;
