import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image, Form, Pagination } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminCountriesWorkedWith = () => {
  const [service, setService] = useState(true);
  const [product, setProduct] = useState(false);
  //AsianCountries............
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // post method for experience
  const formdata = new FormData();
  const [AFlag, setAFlag] = useState("");
  const [AsianC, setAsianC] = useState("");

  const AddAsianC = async () => {
    formdata.append("AFlag", AFlag);
    formdata.append("AsianC", AsianC);

    // console.log("formdata",formdata);

    try {
      if (!AFlag) {
        return alert("Please add Image");
      }
      if (!AsianC) {
        return alert("Please add Text");
      }
      const config = {
        url: "/admin/addAsianC",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getaddAsianC();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [AsianCo, setAsianCo] = useState([]);
  const getaddAsianC = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddAsianC");
    if (res.status === 200) {
      setAsianCo(res.data.getadddAsianC);
      setnochangedataH(res.data.getadddAsianC);
    }
  };

  //delete method
  const [show100, setShow100] = useState(false);
  const [Data, setData] = useState("");

  const handleClose100 = () => setShow100(false);
  const handleShow100 = (item) => {
    setShow100(true);
    setData(item);
  };
  const deleteadddAsianC = async () => {
    try {
      const config = {
        url: "admin/deleteaddAsianC/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddAsianC();
          handleClose100();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method for asian country
  const [show50, setShow50] = useState(false);
  const handleClose50 = () => setShow50(false);
  const handleShow50 = (item) => {
    setShow50(true);
    setDAta1(item);
    setAsianC(item?.AsianC);
  };
  const [DAta1, setDAta1] = useState({});

  const editAsianC = async (e) => {
    e.preventDefault();
    // if (!AddImg) {
    //   alert("Select the image");
    // } else {
    formdata.append("AFlag", AFlag);
    formdata.append("AsianC", AsianC);
    formdata.append("id", DAta1?._id);
    try {
      const config = {
        url: "/admin/updateAsianC",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose50();
          getaddAsianC();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  //African Countries............
  const [show10, setShow10] = useState(false);

  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // post method for experience
  const formdata1 = new FormData();
  const [AfFlag, setAfFlag] = useState("");
  const [AfricanC, setAfricanC] = useState("");

  const AddAfricanC = async () => {
    formdata1.append("AfFlag", AfFlag);
    formdata1.append("AfricanC", AfricanC);

    // console.log("formdata",formdata);

    try {
      if (!AfFlag) {
        return alert("Please add Image");
      }
      if (!AfricanC) {
        return alert("Please add Text");
      }
      const config = {
        url: "/admin/addAfricanC",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose10();
        getaddAfricanC();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [nochangedataA, setnochangedataA] = useState([]);
  const [AfricanCo, setAfricanCo] = useState([]);
  const getaddAfricanC = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddAfricanC");
    if (res.status === 200) {
      setAfricanCo(res.data.getadddAfricanC);
      setnochangedataA(res.data.getadddAfricanC);
    }
  };

  //delete method
  const [show99, setShow99] = useState(false);
  const [Data1, setData1] = useState("");

  const handleClose99 = () => setShow99(false);
  const handleShow99 = (item) => {
    setShow99(true);
    setData1(item);
  };
  const deleteadddAfricanC = async () => {
    try {
      const config = {
        url: "admin/deleteaddAfricanC/" + Data1,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddAfricanC();
          handleClose99();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  useEffect(() => {
    getaddAsianC();
    getaddAfricanC();
  }, []);
  //update method for african country
  const [show49, setShow49] = useState(false);
  const handleClose49 = () => setShow49(false);
  const handleShow49 = (item) => {
    setShow49(true);
    setDAta2(item);
    setAfricanC(item?.AfricanC);
  };
  const [DAta2, setDAta2] = useState({});

  const editAfricanC = async (e) => {
    e.preventDefault();
    // if (!AddImg) {
    //   alert("Select the image");
    // } else {
    formdata1.append("AfFlag", AfFlag);
    formdata1.append("AfricanC", AfricanC);
    formdata1.append("id", DAta2?._id);
    try {
      const config = {
        url: "/admin/updateAfricanC",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose49();
          getaddAfricanC();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // console.log(getaddAsianCountries);
  //search filter for asian
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAsianCo([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAsianCo([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = AsianCo.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  //search filter for african
  const [searchA, setSearchA] = useState("");
  const handleFilterA = (e) => {
    if (e.target.value != "") {
      setSearchA(e.target.value);
      const filterTableA = nochangedataA.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAfricanCo([...filterTableA]);
    } else {
      setSearchA(e.target.value);
      setAfricanCo([...nochangedataA]);
    }
  };
  const [searchTermA, setSearchTermA] = useState("");
  const searchedProductA = AfricanCo.filter((item) => {
    if (searchTermA.value === "") {
      return item;
    }
    if (item?.EName?.toLowerCase().includes(searchTermA?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });

  // Pagination for asian
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 10;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = AsianCo.slice(visitedPage, visitedPage + productPerPage);
  const pageCount = Math.ceil(AsianCo.length / productPerPage);

  // Pagination for african
  // Pagination
  const [pageNumber1, setPageNumber1] = useState(0);
  const productPerPage1 = 10;
  const visitedPage1 = pageNumber1 * productPerPage1;
  const displayPage1 = AfricanCo.slice(
    visitedPage1,
    visitedPage1 + productPerPage1
  );
  const pageCount1 = Math.ceil(AfricanCo.length / productPerPage1);
  console.log(displayPage1, "oFJDW");
  return (
    <>
      <div className="ms-5">
        <div className="">
          <div className="container">
            <div className="ad-b mt-4 mb-4">
              <button
                onClick={() => {
                  setService(true);
                  setProduct(false);
                  // setEvent(false);
                }}
              >
                Asia
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setService(false);
                  setProduct(true);
                  // setEvent(false);
                }}
              >
                Africa
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-3">
            {service ? (
              <>
                <div className="row mb-3">
                  <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                    <Form inline>
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className=" mr-sm-2"
                        onChange={handleFilterH}
                      />
                    </Form>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="add-b text-end  mb-4 col-md-12">
                      <button onClick={()=>{setAsianC("");
                      handleShow()}}>Add Asian Country </button>
                    </div>
                  </div> */}
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                    Countries Worked With Asia
                  </h2>
                  <div className="add-b">
                    <button
                      onClick={() => {
                        setAsianC("");
                        handleShow();
                      }}
                    >
                      Add Asian Country{" "}
                    </button>
                  </div>
                </div>

                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Flag</th>
                      <th>Asian Countries Name</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayPage.map((item, i) => {
                      const serialNumber = i + 1 + visitedPage;
                      return (
                        <tr>
                          <td>{serialNumber}</td>
                          <td>
                            <img
                              src={`https://kanagamultiservice.in/AsianCountry/${item?.AFlag}`}
                              // accept=".jpg,.png.avif,.jpeg,.mp4,.webm"
                              alt="pic"
                              style={{ width: "75px", height: "75px" }}
                            />
                          </td>
                          <td style={{ paddingTop: "20px" }}>{item?.AsianC}</td>
                          <td>
                            <button className="btn btn-success">
                              <AiTwotoneEdit
                                onClick={() => handleShow50(item)}
                              />
                            </button>
                          </td>
                          <td>
                            <button className="btn btn-danger">
                              <AiTwotoneDelete
                                style={{
                                  fontSize: "20px",
                                }}
                                onClick={() => {
                                  handleShow100(item?._id);
                                }}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pagination style={{ float: "right" }}>
                  <Pagination.First onClick={() => setPageNumber(0)} />
                  <Pagination.Prev
                    onClick={() =>
                      setPageNumber((prev) => Math.max(prev - 1, 0))
                    }
                  />
                  {Array.from({ length: pageCount }, (_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index === pageNumber}
                      onClick={() => setPageNumber(index)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                    }
                  />
                  <Pagination.Last
                    onClick={() => setPageNumber(pageCount - 1)}
                  />
                </Pagination>
              </>
            ) : (
              <>
                {product ? (
                  <>
                    <div className="row mb-3">
                      <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                        <Form inline>
                          <Form.Control
                            type="search"
                            placeholder="Search"
                            className=" mr-sm-2"
                            onChange={handleFilterA}
                          />
                        </Form>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="add-b text-end  mb-4 col-md-12">
                          <button
                            onClick={() => {
                              setAfricanC("");
                              handleShow10();
                            }}
                          >
                            Add South African Country{" "}
                          </button>
                        </div>
                      </div> */}
                    </div>{" "}
                    <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                 Countries Worked With Africa
                  </h2>
                  <div className="add-b">
                  <button
                            onClick={() => {
                              setAfricanC("");
                              handleShow10();
                            }}
                          >
                            Add South African Country{" "}
                          </button>
                  </div>
                </div>
                    <Table
                      striped
                      bordered
                      hover
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Flag</th>
                          <th>South African Countries Name</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayPage1.map((item, i) => {
                          const serialNumber1 = i + 1 + visitedPage1;

                          return (
                            <tr>
                              <td>{serialNumber1}</td>
                              <td>
                                <img
                                  src={`https://kanagamultiservice.in/AfricanCountry/${item?.AfFlag}`}
                                  // accept=".jpg,.png.avif,.jpeg,.mp4,.webm"
                                  alt="pic"
                                  style={{ width: "75px", height: "75px" }}
                                />
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.AfricanC}
                              </td>
                              <td>
                                <button className="btn btn-success">
                                  <AiTwotoneEdit
                                    onClick={() => handleShow49(item)}
                                  />
                                </button>
                              </td>
                              <td>
                                <button className="btn btn-danger">
                                  <AiTwotoneDelete
                                    style={{
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleShow99(item?._id);
                                    }}
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination style={{ float: "right" }}>
                      <Pagination.First onClick={() => setPageNumber1(0)} />
                      <Pagination.Prev
                        onClick={() =>
                          setPageNumber1((prev) => Math.max(prev - 1, 0))
                        }
                      />
                      {Array.from({ length: pageCount1 }, (_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index === pageNumber1}
                          onClick={() => setPageNumber1(index)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() =>
                          setPageNumber1((prev) =>
                            Math.min(prev + 1, pageCount1 - 1)
                          )
                        }
                      />
                      <Pagination.Last
                        onClick={() => setPageNumber1(pageCount1 - 1)}
                      />
                    </Pagination>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Asian Country</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Flag</label>
              <input
                type="file"
                className="vi_0"
                onChange={(e) => setAFlag(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Country</label>
              <input
                type="text"
                placeholder="Enter Country Name"
                className="vi_0"
                onChange={(e) => setAsianC(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={AddAsianC}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show10}
          onHide={handleClose10}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add African Country</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Flag</label>
              <input
                type="file"
                className="vi_0"
                onChange={(e) => setAfFlag(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Country</label>
              <input
                type="text"
                placeholder="Enter Country Name"
                className="vi_0"
                onChange={(e) => setAfricanC(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={AddAfricanC}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show100}
          onHide={handleClose100}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose100}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={deleteadddAsianC}
            >
              Delete{" "}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show99}
          onHide={handleClose99}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose99}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={deleteadddAfricanC}
            >
              Delete{" "}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show50}
          onHide={handleClose50}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Asian Country</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Flag</label>
              <input
                type="file"
                className="vi_0"
                onChange={(e) => setAFlag(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Country</label>
              <input
                type="text"
                placeholder="Enter Text Here..."
                className="vi_0"
                value={AsianC}
                onChange={(e) => setAsianC(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose50}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#633188" }}
              onClick={editAsianC}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show49}
          onHide={handleClose49}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Asian Country</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Flag</label>
              <input
                type="file"
                className="vi_0"
                onChange={(e) => setAfFlag(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Country</label>
              <input
                type="text"
                placeholder="Enter Text Here..."
                className="vi_0"
                value={AfricanC}
                onChange={(e) => setAfricanC(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose49}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#633188" }}
              onClick={editAfricanC}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminCountriesWorkedWith;
