import "./App.css";
import "react-multi-carousel/lib/styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "aos/dist/aos.css";
import { Header } from "./Components/Header";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import AboutUS from "./Components/AboutUS";
import Gallery from "./Components/Gallery";
import ContactUS from "./Components/ContactUS";
import Services from "./Components/Services";
import OurBrochure from "./Components/OurBrochure";
import OurClients from "./Components/OurClients";
import Events from "./Components/Events";
import Products from "./Components/Products";
import ProductViewMore from "./Components/ProductViewMore";
import Partner from "./Components/Partner";

import Dashboard from "./Components/Admin/Dashboard";
import Admindashboard from "./Components/Admin/AdminDashboard";
import AdminClients from "./Components/Admin/AdminPartners";
import AdminGallery from "./Components/Admin/AdminGallery";
import AdminHome from "./Components/Admin/Adminhome";
import AdminAboutus from "./Components/Admin/AdminAboutUs";
import AdminSuccesstory from "./Components/Admin/AdminSuccesstory";
import AdminCount from "./Components/Admin/AdminCount";
import AdminOurteam from "./Components/Admin/AdminOurteam";
import AdminServices from "./Components/Admin/AdminService";
import AdminProducts from "./Components/Admin/Admin/AdminProducts";
import AdminPartners from "./Components/Admin/AdminPartners";
import AdminOurBrochure from "./Components/Admin/AdminOurBrochure";
import AdminEvents from "./Components/Admin/AdminEvents";
import AdminContactUs from "./Components/Admin/Admin/AdminContactUs";
import AdminOurCertificates from "./Components/Admin/AdminCertificates";
import AdminEnquiry from "./Components/Admin/AdminEnquiry";
import AdminServiceEnquiry from "./Components/Admin/AdminServiveEnquiry";
import AdminProductsEnquiry from "./Components/Admin/AdminProductsEnquiry";
import AdminEventsEnquiry from "./Components/Admin/AdminEventsEnquiry";
import AdminWhychooseus from "./Components/Admin/AdminWhychooseus";
import AdminHomeServiceList from "./Components/Admin/Admin/AdminHomeServiceList";
import OurServicePartners from "./Components/OurServicePartners";
import OurProductPartners from "./Components/OurProductPartners";
import AdminServicePartners from "./Components/Admin/AdminServicePartners";
import AdminProductPartners from "./Components/Admin/AdminProductPartners";
import Whatsapp from "./Components/Whatsapp";
import JoinAsAPartner from "./Components/Admin/JoinAsAPartner";
import AdminCountriesWorkedWith from "./Components/Admin/AdminCountriesWorkedWith";
import OurEventPartners from "./Components/OurEventPartners";
import AdminLogin from "./Components/Admin/AdminLogin";
import AdminSocialmedias from "./Components/Admin/AdminSocialmedias";
import AdminHomeService from "./Components/Admin/AdminHomeService";

function App() {
  return (
    <>
      
      <BrowserRouter>
        <Routes>
          {/* ======Website======== */}
          <Route
            path="/admin"
            element={
              <>
                <AdminLogin />
              </>
            }
          />
          <Route
            path="/partner"
            element={
              <>
                <Header />
                <Partner />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <Header />
                <Home />
                <OurClients />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/aboutus"
            element={
              <>
                <Header />
                <AboutUS />
                <OurClients />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/gallery"
            element={
              <>
                <Header />
                <Gallery />
                <OurClients />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/contactus"
            element={
              <>
                <Header />
                <ContactUS />
                <OurClients />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/services"
            element={
              <>
                <Header />
                <Services />
                {/* <OurServicePartners /> */}
                <OurClients />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/ourbrochure"
            element={
              <>
                <Header />
                <OurBrochure />
                <OurClients />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/events"
            element={
              <>
                <Header />
                <Events />
                {/* <OurEventPartners /> */}
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/products"
            element={
              <>
                <Header />
                <Products />
                {/* <OurProductPartners /> */}
                <OurClients />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          <Route
            path="/productviewmore"
            element={
              <>
                <Header />
                <ProductViewMore />
                {/* <OurProductPartners /> */}
                <OurClients />
                <Whatsapp />
                <Footer />
              </>
            }
          />
          {/* ======Wesite======== */}

          {/* ==========Admin starts======= */}
          <Route
            path="/admin"
            element={
              <>
                <Dashboard />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                children={
                  <>
                    <Admindashboard />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_clients"
            element={
              <Dashboard
                children={
                  <>
                    <AdminClients />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_Gallery"
            element={
              <Dashboard
                children={
                  <>
                    <AdminGallery />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_home_graph"
            element={
              <Dashboard
                children={
                  <>
                    <AdminHome />
                  </>
                }
              />
            }
          />
           <Route
            path="/Admin_homeservice"
            element={
              <Dashboard
                children={
                  <>
                    <AdminHomeService />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_about_us"
            element={
              <Dashboard
                children={
                  <>
                    <AdminAboutus />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_certificates"
            element={
              <Dashboard
                children={
                  <>
                    <AdminOurCertificates />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_successtory"
            element={
              <Dashboard
                children={
                  <>
                    <AdminSuccesstory />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_count"
            element={
              <Dashboard
                children={
                  <>
                    <AdminCount />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_ourteam"
            element={
              <Dashboard
                children={
                  <>
                    <AdminOurteam />
                  </>
                }
              />
            }
          />

          <Route
            path="/Admin_service"
            element={
              <Dashboard
                children={
                  <>
                    <AdminServices />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_Service_list"
            element={
              <Dashboard
                children={
                  <>
                    <AdminHomeServiceList />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_products"
            element={
              <Dashboard
                children={
                  <>
                    <AdminProducts />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_trusted_parnets"
            element={
              <Dashboard
                children={
                  <>
                    <AdminPartners />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_Servicetrusted_parnets"
            element={
              <Dashboard
                children={
                  <>
                    <AdminServicePartners />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_Producttrusted_parnets"
            element={
              <Dashboard
                children={
                  <>
                    <AdminProductPartners />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_ourbrochure"
            element={
              <Dashboard
                children={
                  <>
                    <AdminOurBrochure />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_events"
            element={
              <Dashboard
                children={
                  <>
                    <AdminEvents />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_contactus"
            element={
              <Dashboard
                children={
                  <>
                    <AdminContactUs />
                  </>
                }
              />
            }
          />
          <Route
            path="/Enquiry"
            element={
              <Dashboard
                children={
                  <>
                    <AdminEnquiry />
                  </>
                }
              />
            }
          />
          <Route
            path="/Service_enquiry"
            element={
              <Dashboard
                children={
                  <>
                    <AdminServiceEnquiry />
                  </>
                }
              />
            }
          />
          <Route
            path="/Products_Enquiry"
            element={
              <Dashboard
                children={
                  <>
                    <AdminProductsEnquiry />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_eventenquiry"
            element={
              <Dashboard
                children={
                  <>
                    <AdminEventsEnquiry />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_joinpartnersrequests"
            element={
              <Dashboard
                children={
                  <>
                    <JoinAsAPartner />
                  </>
                }
              />
            }
          />
         
          <Route
            path="/Admin_whychooseus"
            element={
              <Dashboard
                children={
                  <>
                    <AdminWhychooseus />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_countries"
            element={
              <Dashboard
                children={
                  <>
                    <AdminCountriesWorkedWith />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin_socialmedias"
            element={
              <Dashboard
                children={
                  <>
                    <AdminSocialmedias />
                  </>
                }
              />
            }
          />

          {/* ==========Admin Ends======= */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
