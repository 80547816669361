import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminAboutus = () => {
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setAbDescription(data);
  };
  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setAbDescription2(data);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // intergrating post method in addminaboutus

  const formdata = new FormData();
  const [AbImg, setAbImg] = useState("");
  const [AbTitle, setAbTitle] = useState("");
  const [AbSubtitle, setAbSubtitle] = useState("");
  const [AbDescription, setAbDescription] = useState("");
  const [AbDescription2, setAbDescription2] = useState("");

  const Addaboutus = async () => {
    formdata.set("AbImg", AbImg);
    formdata.set("AbTitle", AbTitle);
    formdata.set("AbSubtitle", AbSubtitle);
    formdata.set("AbDescription", AbDescription);
    formdata.set("AbDescription2", AbDescription2);

    try {
      const config = {
        url: "/admin/addaboutus",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getaddaboutus();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [Adddaboutus, setAdddaboutus] = useState([]);
  const getaddaboutus = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallaboutus"
      );
      if (res.status === 200) {
        setAdddaboutus(res.data.getAddaboutus);
        setnochangedataH(res.data.getAddaboutus);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const deleteadddaboutus = async () => {
    try {
      const config = {
        url: "admin/DeleteAddaboutus/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddaboutus();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setAbTitle(item?.AbTitle);
    setAbDescription(item?.AbDescription);
    setAbDescription2(item?.AbDescription2);
  };

  const editAddaboutus = async (e) => {
    e.preventDefault();
    formdata.set("AbImg", AbImg);
    formdata.set("AbTitle", AbTitle);
    formdata.set("AbSubtitle", AbSubtitle);
    formdata.set("AbDescription", AbDescription);
    formdata.set("AbDescription2", AbDescription2);
    formdata.set("id", Data1?._id);
    try {
      const config = {
        url: "/admin/editAddaboutus",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Updated");
          handleClose1();
          getaddaboutus();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getaddaboutus();
  }, []);
  console.log(Adddaboutus);

  //search filter for about us
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAdddaboutus([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAdddaboutus([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = Adddaboutus.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  return (
    <>
      <div className="ms-5">
        <div className="container mt-3">
        <div className="row mb-3">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilterH}
                />
              </Form>
            </div>
            {/* <div className="col-md-4">
            <div className="add-b text-end mt-4  col-md-12">
            {Adddaboutus?.length !== 0 ? (
              ""
            ) : (
              <>
                <button onClick={handleShow}>Add About Us</button>
              </>
            )}
          </div>
            </div> */}
          </div>
          <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                 About Us
                  </h2>
                  <div className="add-b">
                  {Adddaboutus?.length !== 0 ? (
              ""
            ) : (
              <>
                <button onClick={handleShow}>Add About Us</button>
              </>
            )}
                  </div>
                </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-3 ms-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>SubTitle</th>
                <th>Image </th>
                <th>Description1</th>
                <th>Description2</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Adddaboutus?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item?.AbTitle}</td>
                    <td>{item?.AbSubtitle}</td>
                    <td>
                      <Image
                        src={`https://kanagamultiservice.in/AddAboutus/${item?.AbImg}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {parse(`<div>${item?.AbDescription}</div>`)}
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {parse(`<div>${item?.AbDescription2}</div>`)}
                    </td>

                    <td>
                      <button className="btn btn-success">
                        <AiTwotoneEdit onClick={() => handleShow1(item)} />
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-danger">
                        <AiTwotoneDelete
                          style={{
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            handleShow2(item?._id);
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Title</label>
            <input
              type="text"
              placeholder="Enter Title"
              className="vi_0"
              onChange={(e) => setAbTitle(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Sub Title</label>
            <input
              type="text"
              placeholder="Enter Sub-title"
              className="vi_0"
              onChange={(e) => setAbSubtitle(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setAbImg(e.target.files[0])}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Description1</label>
            <CKEditor
              editor={ClassicEditor}
              data={AbDescription}
              onChange={handleChange}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description2</label>
            <CKEditor
              editor={ClassicEditor}
              data={AbDescription2}
              onChange={handleChange1}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={Addaboutus}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update About us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Title</label>
            <input
              type="text"
              placeholder="Enter Title"
              value={AbTitle}
              className="vi_0"
              onChange={(e) => setAbTitle(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Sub Title</label>
            <input
              type="text"
              placeholder="Enter Sub-title"
              value={AbSubtitle}
              className="vi_0"
              onChange={(e) => setAbSubtitle(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setAbImg(e.target.files[0])}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Description1</label>
            <CKEditor
              editor={ClassicEditor}
              data={Data1?.AbDescription}
              onChange={handleChange}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description2</label>
            <CKEditor
              editor={ClassicEditor}
              data={Data1?.AbDescription2}
              onChange={handleChange1}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editAddaboutus}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>
            Do You Really Want To Delete...??
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteadddaboutus}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminAboutus;
