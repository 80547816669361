import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminCount = () => {
  // const handleChange = (e, editor) => {
  //   const data = editor.getData();
  //   setCounts(data);
  // };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const [Counts, setCounts] = useState("");
  const [Text, setText] = useState("");

  const AddTestimonials = async () => {
    try {
      const config = {
        url: "/admin/Counts",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          Counts: Counts,
          Text: Text,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAddTestimonials();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonials = async () => {
    try {
      let res = await axios.get("https://kanagamultiservice.in/api/admin/getCounts");
      if (res.status == 200) {
        setAddTestimonial(res.data.getCounts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const deleteTestimonials = async () => {
    try {
      const config = {
        url: "admin/deleteCounts/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddTestimonials();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Aexp, setAexp] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setAexp(item);
    setCounts(item?.Counts);
    setText(item?.Text);
  };
  const editTestimonials = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/editCounts",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          id: Aexp?._id,
          Counts: Counts,
          Text: Text,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddTestimonials();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAddTestimonials();
  }, []);
  console.log(AddTestimonial);
  return (
    <>
      <div className="add-gr">
        <div className="container">
         
          <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                Counts
                  </h2>
                  <div className="add-b">
                  {AddTestimonial?.length !== 4 ? (
              <>
              <button onClick={handleShow}>Add Counts</button>
              </>
            ) : (
              <>
                
              </>
            )}
                  </div>
                </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra ms-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>Counts</th>
                <th> Text</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddTestimonial.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{item?.Counts} </td>
                      <td style={{ paddingTop: "20px" }}>{item?.Text}</td>
                      <td>
                        <button className="btn btn-success">
                          <AiTwotoneEdit onClick={() => handleShow1(item)} />
                        </button>
                       
                      </td>
                      <td>
                            <button className="btn btn-danger">
                              <AiTwotoneDelete
                                style={{
                                  fontSize: "20px",
                                }}
                                onClick={() => {
                                  handleShow2(item?._id);
                                }}
                              />
                            </button>
                          </td>
                     
                    </tr>

                    {/* <tr>
                      <td>{item?.Counts1} </td>
                      <td style={{ paddingTop: "20px" }}>{item?.Text1}</td>
                      <td>
                        <button className="btn btn-success">
                          <AiTwotoneEdit onClick={() => handleShow1(item)} />
                        </button>
                       
                      </td>
                     
                    </tr>
                    <tr>
                      <td>{item?.Counts2} </td>
                      <td style={{ paddingTop: "20px" }}>{item?.Text2}</td>
                      <td>
                        <button className="btn btn-success">
                          <AiTwotoneEdit onClick={() => handleShow1(item)} />
                        </button>
                       
                      </td>
                     
                    </tr>

                    <tr>
                      <td>{item?.Counts3} </td>
                      <td style={{ paddingTop: "20px" }}>{item?.Text3}</td>
                      <td>
                        <button className="btn btn-success">
                          <AiTwotoneEdit onClick={() => handleShow1(item)} />
                        </button>
                      
                      </td>
                     
                    </tr> */}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Counts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
             
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Count </label>
                  <input
                    type="number"
                    placeholder="Enter Count 1 Here..."
                    className="vi_0"
                    onChange={(e) => setCounts(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text </label>
                  <input
                    type="text"
                    placeholder="Enter Text 1 Here..."
                    className="vi_0"
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container mt-2">
            <div className="row">
              <label style={{ textDecoration: "underline" }}>
                Permanent Clients
              </label>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Count 2</label>
                  <input
                    type="number"
                    placeholder="Enter Count 2 Here..."
                    className="vi_0"
                    onChange={(e) => setCounts1(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text 2</label>
                  <input
                    type="text"
                    placeholder="Enter Text 2 Here..."
                    className="vi_0"
                    onChange={(e) => setText1(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <label style={{ textDecoration: "underline" }}>
                Owned Vehicles
              </label>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Count 3</label>
                  <input
                    type="number"
                    placeholder="Enter Count 3 Here..."
                    className="vi_0"
                    onChange={(e) => setCounts2(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text 3</label>
                  <input
                    type="text"
                    placeholder="Enter Text 3 Here..."
                    className="vi_0"
                    onChange={(e) => setText2(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <label style={{ textDecoration: "underline" }}>
                Support Memebers
              </label>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Calender</label>
                  <input
                    type="number"
                    placeholder="Enter Count 4 Here..."
                    className="vi_0"
                    onChange={(e) => setCounts3(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text 4</label>
                  <input
                    type="text"
                    placeholder="Enter Text 4 Here..."
                    className="vi_0"
                    onChange={(e) => setText3(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddTestimonials}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteTestimonials}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Counts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Count</label>
                  <input
                    type="number"
                    placeholder="Enter Count 1 Here..."
                    className="vi_0"
                    value={Counts}
                    onChange={(e) => setCounts(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text</label>
                  <input
                    type="text"
                    placeholder="Enter Text 1 Here..."
                    className="vi_0"
                    value={Text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container mt-2">
            <div className="row">
              <label style={{ textDecoration: "underline" }}>
                Permanent Clients
              </label>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Count 2</label>
                  <input
                    type="number"
                    placeholder="Enter Count 2 Here..."
                    className="vi_0"
                    value={Counts1}
                    onChange={(e) => setCounts1(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text 2</label>
                  <input
                    type="text"
                    placeholder="Enter Text 2 Here..."
                    className="vi_0"
                    value={Text1}
                    onChange={(e) => setText1(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <label style={{ textDecoration: "underline" }}>
                Owned Vehicles
              </label>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Count 3</label>
                  <input
                    type="number"
                    placeholder="Enter Count 3 Here..."
                    className="vi_0"
                    value={Counts2}
                    onChange={(e) => setCounts2(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text 3</label>
                  <input
                    type="text"
                    placeholder="Enter Text 3 Here..."
                    className="vi_0"
                    value={Text2}
                    onChange={(e) => setText2(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <label style={{ textDecoration: "underline" }}>
                Support Members
              </label>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Counts</label>
                  <input
                    type="number"
                    placeholder="Enter Count 4 Here..."
                    className="vi_0"
                    value={Counts3}
                    onChange={(e) => setCounts3(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text 4</label>
                  <input
                    type="text"
                    placeholder="Enter Text 4 Here..."
                    className="vi_0"
                    value={Text3}
                    onChange={(e) => setText3(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editTestimonials}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminCount;
