import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image, Pagination } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiTwotoneDelete } from "react-icons/ai";

const AdminPartners = () => {
  const [show, setShow] = useState(false);
  const [show10, setShow10] = useState(false);
  const [show11, setShow11] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [service, setService] = useState(true);
  const [product, setProduct] = useState(false);
  const [event, setEvent] = useState(false);

  //Service partners
  // post method for partners
  const formdata = new FormData();
  const [PImgS, setPImgS] = useState("");

  const AddSPartners = async () => {
    formdata.append("PImgS", PImgS);

    try {
      if(!PImgS) return alert("Please Select the Image");
      const config = {
        url: "/admin/addSPartnerss",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getaddSPartnerss();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [AddSPartnerss, setAddSPartnerss] = useState([]);
  const getaddSPartnerss = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddSPartnerss"
    );
    if (res.status === 200) {
      setAddSPartnerss(res.data.getadddSPartners);
    }
  };
  //delete method
  const [show100, setShow100] = useState(false);
  const [show99, setShow99] = useState(false);
  const [show98, setShow98] = useState(false);

  const [Data, setData] = useState("");

  const handleClose100 = () => setShow100(false);
  const handleShow100 = (item) => {
    setShow100(true);
    setData(item);
  };
  const handleClose99 = () => setShow99(false);
  const handleShow99 = (item) => {
    setShow99(true);
    setData(item);
  };
  const handleClose98 = () => setShow98(false);
  const handleShow98 = (item) => {
    setShow98(true);
    setData(item);
  };
  const deleteaddSPartnerss = async () => {
    try {
      const config = {
        url: "admin/deleteaddSPartnerss/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddSPartnerss();
          handleClose100();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //Product partners
  // post method for partners
  const formdata1 = new FormData();
  const [PImgP, setPImgP] = useState("");

  const AddPPartners = async () => {
    formdata1.append("PImgP", PImgP);

    try {
      if(!PImgP) return alert("Please Select the Image");
      const config = {
        url: "/admin/addPPartnerss",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose10();
        getaddPPartnerss();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [AddPPartnerss, setAddPPartnerss] = useState([]);
  const getaddPPartnerss = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddPPartnerss"
    );
    if (res.status === 200) {
      setAddPPartnerss(res.data.getadddPPartners);
    }
  };
  //delete method
  const deleteaddPPartnerss = async () => {
    try {
      const config = {
        url: "admin/deleteaddPPartnerss/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddPPartnerss();
          handleClose99();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //Event partners
  // post method for partners
  const formdata2 = new FormData();
  const [EImg, setEImg] = useState("");

  const AddEPartners = async () => {
    formdata2.append("EImg", EImg);

    try {
      if(!EImg) return alert("Please Select the Image");
      const config = {
        url: "/admin/addEPartnerss",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata2,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose11();
        getaddEPartnerss();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [AddEPartnerss, setAddEPartnerss] = useState([]);
  const getaddEPartnerss = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddEPartnerss"
    );
    if (res.status === 200) {
      setAddEPartnerss(res.data.getadddEPartners);
    }
  };
  const deleteaddEPartnerss = async () => {
    try {
      const config = {
        url: "admin/deleteaddEPartnerss/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddEPartnerss();
          handleClose98();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  useEffect(() => {
    getaddSPartnerss();
    getaddPPartnerss();
    getaddEPartnerss();
  }, []);
  console.log(AddSPartnerss);
  console.log(AddPPartnerss);
  console.log(AddEPartnerss);
  // Pagination for service
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = AddSPartnerss.slice(
    visitedPage,
    visitedPage + productPerPage
  );
  const pageCount = Math.ceil(AddSPartnerss.length / productPerPage);

  // Pagination for products
  const [pageNumberP, setPageNumberP] = useState(0);
  const productPerPageP = 5;
  const visitedPageP = pageNumberP * productPerPageP;
  const displayPageP = AddPPartnerss.slice(
    visitedPageP,
    visitedPageP + productPerPageP
  );
  const pageCountP = Math.ceil(AddPPartnerss.length / productPerPageP);

  // Pagination for events
  const [pageNumberE, setPageNumberE] = useState(0);
  const productPerPageE = 5;
  const visitedPageE = pageNumberE * productPerPageE;
  const displayPageE = AddEPartnerss.slice(
    visitedPageE,
    visitedPageE + productPerPageE
  );
  const pageCountE = Math.ceil(AddEPartnerss.length / productPerPageE);

  return (
    <>
      <div className="ms-5">
        <div className="">
          <div className="container">
            <div className="ad-b mt-3 mb-3">
              <button
                onClick={() => {
                  setService(true);
                  setProduct(false);
                  setEvent(false);
                }}
              >
                Service Partners
              </button>
              <button
                onClick={() => {
                  setService(false);
                  setProduct(true);
                  setEvent(false);
                }}
              >
                Product Partners
              </button>
              {/* <button
                onClick={() => {
                  setService(false);
                  setProduct(false);
                  setEvent(true);
                }}
              >
                Event Partners
              </button> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mt-5">
            {service ? (
              <>
              
                <div className="d-flex justify-content-between ">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
              Our Partners
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                    setPImgS("");
                   
                    handleShow()}}>Add Partners </button>
                  </div>
                </div>

                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Service Partners</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayPage.map((item, i) => {
                      const serialNumber = i + 1 + visitedPage;
                      return (
                        <tr>
                          <td>{serialNumber}</td>
                          <td>
                            <img
                              src={`https://kanagamultiservice.in/OurServicePartners/${item?.PImgS}`}
                              alt="pic"
                              style={{ width: "75px", height: "75px" }}
                            />
                          </td>

                          <td>
                            <button className="btn btn-danger">
                              <AiTwotoneDelete
                                style={{
                                  fontSize: "20px",
                                }}
                                onClick={() => {
                                  handleShow100(item?._id);
                                }}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <Pagination style={{ float: "right" }}>
                  <Pagination.First onClick={() => setPageNumber(0)} />
                  <Pagination.Prev
                    onClick={() =>
                      setPageNumber((prev) => Math.max(prev - 1, 0))
                    }
                  />
                  {Array.from({ length: pageCount }, (_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index === pageNumber}
                      onClick={() => setPageNumber(index)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                    }
                  />
                  <Pagination.Last
                    onClick={() => setPageNumber(pageCount - 1)}
                  />
                </Pagination>
              </>
            ) : (
              <>
                {product ? (
                  <>
                   
                    <div className="d-flex justify-content-between ">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
              Product Partners
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{ setPImgP("");
                   handleShow10()}}>
                        Add Product Partners{" "}
                      </button>
                  </div>
                </div>
                    <Table
                      striped
                      bordered
                      hover
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product Partners</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayPageP.map((item, i) => {
                          const serialNumberP = i + 1 + visitedPageP;
                          return (
                            <tr>
                              <td>{serialNumberP}</td>
                              <td>
                                <img
                                  src={`https://kanagamultiservice.in/OurProductPartners/${item?.PImgP}`}
                                  alt="pic"
                                  style={{ width: "75px", height: "75px" }}
                                />
                              </td>

                              <td>
                                <button className="btn btn-danger">
                                  <AiTwotoneDelete
                                    style={{
                                      // color: "red",
                                      fontSize: "20px",
                                      // paddingTop: "20px",
                                    }}
                                    onClick={() => {
                                      handleShow99(item?._id);
                                    }}
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination style={{ float: "right" }}>
                      <Pagination.First onClick={() => setPageNumberP(0)} />
                      <Pagination.Prev
                        onClick={() =>
                          setPageNumberP((prev) => Math.max(prev - 1, 0))
                        }
                      />
                      {Array.from({ length: pageCountP }, (_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index === pageNumberP}
                          onClick={() => setPageNumberP(index)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() =>
                          setPageNumberP((prev) =>
                            Math.min(prev + 1, pageCountP - 1)
                          )
                        }
                      />
                      <Pagination.Last
                        onClick={() => setPageNumberP(pageCountP - 1)}
                      />
                    </Pagination>
                  </>
                ) : (
                  <>
                    {event ? (
                      <>
                       
                        <div className="d-flex justify-content-between ">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
              Event Partners
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{ setEImg("");handleShow11()}}>
                            Add Events Partners{" "}
                          </button>
                  </div>
                </div>
                        <Table
                          striped
                          bordered
                          hover
                          style={{ textAlign: "center" }}
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Event Partners</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayPageE.map((item, i) => {
                              const serialNumberE = i + 1 + visitedPageE;
                              return (
                                <tr>
                                  <td>{serialNumberE}</td>
                                  <td>
                                    <img
                                      src={`https://kanagamultiservice.in/OurEventsPartners/${item?.EImg}`}
                                      alt="pic"
                                      style={{ width: "75px", height: "75px" }}
                                    />
                                  </td>

                                  <td>
                                    <button className="btn btn-danger">
                                      <AiTwotoneDelete
                                        style={{
                                          // color: "red",
                                          fontSize: "20px",
                                          // paddingTop: "20px",
                                        }}
                                        onClick={() => {
                                          handleShow98(item?._id);
                                        }}
                                      />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <Pagination style={{ float: "right" }}>
                          <Pagination.First onClick={() => setPageNumberE(0)} />
                          <Pagination.Prev
                            onClick={() =>
                              setPageNumberE((prev) => Math.max(prev - 1, 0))
                            }
                          />
                          {Array.from({ length: pageCountE }, (_, index) => (
                            <Pagination.Item
                              key={index}
                              active={index === pageNumberE}
                              onClick={() => setPageNumberE(index)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() =>
                              setPageNumberE((prev) =>
                                Math.min(prev + 1, pageCountE - 1)
                              )
                            }
                          />
                          <Pagination.Last
                            onClick={() => setPageNumberE(pageCountE - 1)}
                          />
                        </Pagination>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Partners</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="file"
                placeholder="Enter Years"
                className="vi_0"
                onChange={(e) => setPImgS(e.target.files[0])}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={AddSPartners}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show10}
          onHide={handleClose10}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Product Partners</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="file"
                placeholder="Enter Years"
                className="vi_0"
                onChange={(e) => setPImgP(e.target.files[0])}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={AddPPartners}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show11}
          onHide={handleClose11}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Event Partners</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="file"
                placeholder="Enter Years"
                className="vi_0"
                onChange={(e) => setEImg(e.target.files[0])}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={AddEPartners}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show100}
          onHide={handleClose100}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose100}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={deleteaddSPartnerss}
            >
              Delete{" "}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show99}
          onHide={handleClose99}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose99}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={deleteaddPPartnerss}
            >
              Delete{" "}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show98}
          onHide={handleClose98}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose98}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={deleteaddEPartnerss}
            >
              Delete{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminPartners;
