import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap/cjs";
import Table from "react-bootstrap/Table";

function AdminDashboard() {
  const [AddEnquiry, setAddEnquiry] = useState([]);
  const getAddEnquiry = async () => {
    try {
      let res = await axios.get("https://kanagamultiservice.in/api/user/getallenquiry");
      if (res.status === 200) {
        setAddEnquiry(res.data.getenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AddpEnquiry, setAddpEnquiry] = useState([]);
  const getAddpEnquiry = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/user/getallPenquiry"
      );
      if (res.status === 200) {
        setAddpEnquiry(res.data.getPenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method
  const [AddEnquiryS, setAddEnquiryS] = useState([]);
  const getAddEnquirys = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/user/getallSenquiry"
      );
      if (res.status === 200) {
        setAddEnquiryS(res.data.getSenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddEnquiry();
    getAddpEnquiry();
    getAddEnquirys();
  }, []);
  console.log(AddEnquiry);
  console.log(AddpEnquiry);
  console.log(AddEnquiryS);
  const user = JSON.parse(sessionStorage.getItem("admin"));
  console.log(user);
  if (!user) {
    alert("please login first");
    window.location.assign("/admin");
  } else
    return (
      <>
        <div className=" add-gr ad-dash pt-3">
          <div className="container">
            <div className="dash-0 mb-3">
              <div className="row">
                <div className="col-md-4">
                  <div className="ad-pa">
                    <div className="wraper-0">
                      <div className="wrapper-ad joi">
                        <div className="view-wrap">
                          <a href="/Products_Enquiry">View All</a>{" "}
                        </div>{" "}
                        <br />
                        <p style={{ textAlign: "center" }}>
                          No. of Product Enquiries
                        </p>
                        <p
                          className="text-center"
                          style={{
                            borderTop: "1px solid darkgray",
                            paddingTop: "20px",
                          }}
                        >
                          {AddpEnquiry?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ad-pa">
                    <div className="wraper-0">
                      <div className="wrapper-ad joi">
                        <div className="view-wrap">
                          <a href="/Service_enquiry">View All</a>{" "}
                        </div>{" "}
                        <br />
                        <p style={{ textAlign: "center" }}>
                          No. of Service Enquiries
                        </p>
                        <p
                          className="text-center"
                          style={{
                            borderTop: "1px solid darkgray",
                            paddingTop: "20px",
                          }}
                        >
                          {AddEnquiryS?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ad-pa">
                    <div className="wraper-0">
                      <div className="wrapper-ad joi">
                        <div className="view-wrap">
                          <a href="/Enquiry">View All</a>{" "}
                        </div>{" "}
                        <br />
                        <p style={{ textAlign: "center" }}>No. of Enquires</p>
                        <p
                          className="text-center"
                          style={{
                            borderTop: "1px solid darkgray",
                            paddingTop: "20px",
                          }}
                        >
                          {AddEnquiry?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dash-0 mb-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="ad-pa">
                    <div className="wraper-0">
                      <div className="wrapper-ad joi">
                        <div className="view-wrap">
                          {" "}
                          <a href="/Products_Enquiry">View All</a>
                        </div>
                        <br />
                        <p style={{ textAlign: "center" }}>
                          Recently Product Enquires
                        </p>
                      </div>

                      <div>
                        <div className="admin-list res-0">
                          <Table
                            striped
                            bordered
                            hover
                            style={{ textAlign: "center" }}
                          >
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Product Category</th>
                                <th>Brand</th>
                                <th>Product Model</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Message</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AddpEnquiry?.slice(0, 3)?.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.ProductCat}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.ProductBrand}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.ProductModel}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.EFName}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.ELName}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.Emsg}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ad-pa">
                    <div className="wraper-0">
                      <div className="wrapper-ad joi">
                        <div className="view-wrap">
                          <a href="/Service_enquiry"></a>
                          View All
                        </div>
                        <br />
                        <p style={{ textAlign: "center" }}>
                          Recently Service Enquiries
                        </p>
                      </div>

                      <div>
                        <div className="admin-list admin-list res-0">
                          <Table
                            striped
                            bordered
                            hover
                            style={{ textAlign: "center" }}
                          >
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Computer/Laptop</th>
                                <th>Brand</th>
                                <th>Operating System</th>
                                <th>First Name</th>
                                <th>Message</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AddEnquiryS?.slice(0, 3)?.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.ServiceFor}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.ServiceBrand}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.ServiceOS}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.EFName}
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.Emsg?.slice(0, 10)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Enquires</h4>
                <div>
                  <a
                    href="/category"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {" "}
                    View All
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <div className="admin-list admin-list res-0">
                    <Table
                      striped
                      bordered
                      hover
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>

                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {AddEnquiry?.slice(0, 3)?.map((item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.EFName}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.ELName}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.EEmail}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.EPhone}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.Emsg}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default AdminDashboard;
