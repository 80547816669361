import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminProductPartners = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // post method for partners
  const formdata = new FormData();
  const [PImgP, setPImgP] = useState("");
  const [PText, setPText] = useState("");

  const AddPPartners = async () => {
    formdata.append("PImgP", PImgP);
    formdata.append("PText", PText);

    // console.log("formdata",formdata);

    try {
      const config = {
        url: "/admin/addPPartnerss",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getaddPPartnerss();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [AddPartnerss, setAddPartnerss] = useState([]);
  const getaddPPartnerss = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddPPartnerss"
    );
    if (res.status === 200) {
      setAddPartnerss(res.data.getadddPPartners);
    }
  };
  //delete method
  const [show33, setShow33] = useState(false);
  const [Data, setData] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setData(item);
  };
  const deleteaddPPartnerss = async () => {
    try {
      const config = {
        url: "admin/deleteaddPPartnerss/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddPPartnerss();
          handleClose33();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setDAta1(item);
    setPText(item?.PText);
  };
  const [DAta1, setDAta1] = useState({});

  const updatePPartnerss = async (e) => {
    e.preventDefault();
    // if (!PImg) {
    //   alert("Select the image");
    // } else {
    formdata.append("PImgP", PImgP);
    formdata.append("PText", PText);
    formdata.append("id", DAta1?._id);
    try {
      const config = {
        url: "/admin/updatePPartnerss",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getaddPPartnerss();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getaddPPartnerss();
  }, []);
  console.log(AddPartnerss);
  return (
    <>
      <div className="ms-5">
        <div className="add-gr">
          <div className="container">
            <div className="ad-b mt-4 mb-4">
              <button onClick={handleShow}>Add Product Partners </button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  {/* <th>Edit</th> */}
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {AddPartnerss.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <Image
                          src={`https://kanagamultiservice.in/OurProductPartners/${item?.PImgP}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>
                    
                     
                      <td>
                        <button className="btn btn-danger">
                          <AiTwotoneDelete
                            style={{
                              // color: "red",
                              fontSize: "20px",
                              // paddingTop: "20px",
                            }}
                            onClick={() => {
                              handleShow33(item?._id);
                            }}
                          />
                        </button>
                       
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Partners</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="file"
                placeholder="Enter Years"
                className="vi_0"
                onChange={(e) => setPImgP(e.target.files[0])}
              />
            </div>
            {/* <div className="do-sear mt-2">
              <label>Text</label>
              <input
                type="text"
                placeholder="Enter Text Here..."
                className="vi_0"
                onChange={(e) => setPText(e.target.value)}
              />
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={AddPPartners}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show33}
          onHide={handleClose33}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose33}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={deleteaddPPartnerss}
            >
              Delete{" "}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Partnerss</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="file"
                placeholder="Enter Years"
                className="vi_0"
                onChange={(e) => setPImgP(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Text</label>
              <input
                type="text"
                placeholder="Enter Text Here..."
                className="vi_0"
                value={PText}
                onChange={(e) => setPText(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={updatePPartnerss}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminProductPartners;
