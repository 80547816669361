import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import axios from "axios";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminWhychooseus = () => {
  const [WhyChooseUs, setWhyChooseUs] = useState(true);
  const [AditionalPoints, seAditionalPoints] = useState(true);
  const [btn, setBtn] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setAbDesc(data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // intergrating post method
  const formdata = new FormData();
  const [AbImg, setAbImg] = useState("");
  const [AbDesc, setAbDesc] = useState("");

  const AddBlogg = async () => {
    formdata.append("AbImg", AbImg);
    formdata.append("AbDesc", AbDesc);

    try {
      const config = {
        url: "admin/addblog",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddBlog();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [AddBlog, setAddBlog] = useState([]);
  const getAddBlog = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddblog");
    if (res.status === 200) {
      setAddBlog(res.data.getAddBlog);
      setnochangedataH(res.data.getAddBlog);
    }
  };

  //delete method
  const [show33, setShow33] = useState(false);
  const [Data, setData] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setData(item);
  };
  const DeleteadddBlog = async () => {
    try {
      const config = {
        url: "admin/deleteaddblog/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Deleted");
          getAddBlog();
          handleClose33();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [DAta1, setDAta1] = useState({});
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setDAta1(item);
  };

  const editAddBlog = async (e) => {
    e.preventDefault();
    formdata.append("AbImg", AbImg);
    formdata.append("AbDesc", AbDesc);
    formdata.append("id", DAta1?._id);
    try {
      console.log("jgjgfjg", DAta1?._id);
      const config = {
        url: "/admin/updateaddblogs",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose3();
          getAddBlog();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // Additional Points ============================================
  const [Catfilter, setCatfilter] = useState([]);
  const [show99, setShow99] = useState(false);

  const handleClose99 = () => setShow99(false);
  const handleShow99 = () => setShow99(true);

  const formdata1 = new FormData();
  const [AddImg, setAddImg] = useState("");
  const [Text, setText] = useState("");

  const AddPoints = async () => {
    formdata1.append("AddImg", AddImg);
    formdata1.append("Text", Text);

    try {
      if(!AddImg) return alert("Please Select The Image");
      if(!Text) return alert("Please Enter the Text");
      const config = {
        url: "/admin/addPoints",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose99();
        getaddpointss();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [nochangedataA, setnochangedataA] = useState([]);
  const [addpoints, setaddpoints] = useState([]);
  const getaddpointss = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddPoints");
    if (res.status === 200) {
      setaddpoints(res.data.getadddpoints);
      setnochangedataA(res.data.getadddpoints);
    }
  };

  //delete method
  const [show98, setShow98] = useState(false);
  const [Data1, setData1] = useState("");

  const handleClose98 = () => setShow98(false);
  const handleShow98 = (item) => {
    setShow98(true);
    setData1(item);
  };
  const deleteadddpoints = async () => {
    try {
      const config = {
        url: "admin/deleteaddpoints/" + Data1,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddpointss();
          handleClose98();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [DAta2, setDAta2] = useState({});
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setDAta2(item);
    setText(item?.Text);
  };

  const editAddGallerry = async (e) => {
    e.preventDefault();
    formdata1.append("AddImg", AddImg);
    formdata1.append("Text", Text);
    formdata1.append("id", DAta2?._id);
    try {
      console.log("jgjgfjg", DAta2?._id);
      const config = {
        url: "/admin/updatepoints",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Updated");
          handleClose4();
          getaddpointss();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  //update method
  // const [Aexp, setAexp] = useState("");
  // const [show97, setShow97] = useState(false);
  // const handleClose97 = () => setShow97(false);
  // const handleShow97 = (item) => {
  //   setShow4(true);
  //   setDAta2(item);
  //   setText(DAta2?.Text);
  // };
  // const [show4, setShow4] = useState(false);
  // const handleClose4 = () => setShow4(false);
  // const handleShow4 = (item) => {
  //   setShow4(true);
  //   setDAta2(item);
  //   setAddImg(item?.AbImg);
  //   setText(item?.Text);
  // setAddImg1(item?.AbImg1);
  // setText1(item?.Text1);
  // setAddImg2(item?.AbImg2);
  // setText2(item?.Text2);
  // setAddImg3(item?.AbImg3);
  // setText3(item?.Text3);
  // };
  // const [DAta2, setDAta2] = useState({});

  // const editAddGallerry = async (e) => {
  //   e.preventDefault();
  //   formdata1.append("AddImg", AddImg);
  //   formdata1.append("Text", Text);
  // formdata1.append("AddImg1", AddImg1);
  // formdata1.append("Text1", Text1);
  // formdata1.append("AddImg2", AddImg2);
  // formdata1.append("Text2", Text2);
  // formdata1.append("AddImg3", AddImg3);
  // formdata1.append("Text3", Text3);
  // formdata1.append("id", DAta2?._id);
  // try {
  //   const config = {
  //     url: "/admin/updatepoints",
  //     method: "put",
  //     baseURL: "https://kanagamultiservice.in/api",
  //     headers: { "content-type": "multipart/form-data" },
  //     data: {
  // id: Aexp?._id,
  //         formdata1,
  //       },
  //     };
  //     await axios(config).then((res) => {
  //       if (res.status === 200) {
  //         alert("successfully Updated");
  //         handleClose4();
  //         getaddpointss();
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     alert(error.response.data.error);
  //   }
  // };
  useEffect(() => {
    getAddBlog();
    getaddpointss();
  }, []);
  console.log(addpoints);

  //search filter for why choose us
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAddBlog([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAddBlog([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = AddBlog.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  //search filter for additional points
  const [searchA, setSearchA] = useState("");
  const handleFilterA = (e) => {
    if (e.target.value != "") {
      setSearchA(e.target.value);
      const filterTableA = nochangedataA.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setaddpoints([...filterTableA]);
    } else {
      setSearchA(e.target.value);
      setaddpoints([...nochangedataA]);
    }
  };
  const [searchTermA, setSearchTermA] = useState("");
  const searchedProductA = addpoints.filter((item) => {
    if (searchTermA.value === "") {
      return item;
    }
    if (item?.EName?.toLowerCase().includes(searchTermA?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="ad-b mt-4 mb-4 col-md-12">
              <button
                onClick={() => {
                  setWhyChooseUs(true);
                  seAditionalPoints(false);
                }}
              >
                Why Choose Us
              </button>
              <button
                onClick={() => {
                  setWhyChooseUs(false);
                  seAditionalPoints(true);
                }}
              >
                Additional points
              </button>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra  ms-5">
          {WhyChooseUs ? (
            <>
              <div className="row mb-3">
                <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                  <Form inline>
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className=" mr-sm-2"
                      onChange={handleFilterH}
                    />
                  </Form>
                </div>
              
              </div>
              <div className="d-flex justify-content-between">
              <h2 style={{color:"#FC5E04",font:"bold"}}>Why Choose Us</h2>
              <div className="add-b">
                    {AddBlog?.length !== 0 ? (
                      ""
                    ) : (
                      <>
                        <button onClick={handleShow}>+ Why Choose Us</button>
                      </>
                    )}
              </div>
              </div>

              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Description</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {AddBlog?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          <img
                            src={`https://kanagamultiservice.in/WhyChooseUs/${item?.AbImg}`}
                            alt=""
                            style={{ width: "75px", height: "75px" }}
                          />
                        </td>
                        {/* <td>{item?.AbTittle}</td> */}
                        <td> {parse(`<div>${item?.AbDesc}</div>`)}</td>
                        {/* <td>{parse(`<div>${item?.AbDesc}</div>`)}</td> */}

                        <td>
                          <button className="btn btn-success">
                            <AiTwotoneEdit onClick={() => handleShow3(item)} />
                          </button>
                        </td>
                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleShow33(item?._id);
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              {AditionalPoints ? (
                <>
                  {/* {Catfilter?.length < 4 ? (
                    <>
                      <div className="add-b text-end mb-4 col-md-12">
                        {AditionalPoints?.length !== 4 ? (
                          <>
                            <button onClick={handleShow99}>
                              + Additional Points
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )} */}
                  <div className="container">
                  <div className="row mb-3">
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                      <Form inline>
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className=" mr-sm-2"
                          onChange={handleFilterA}
                        />
                      </Form>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="add-b text-end col-md-12">
                        {/* {addpoints?.length !== 4 ? (
                          <>
                             <button onClick={handleShow99}>
                              + Additional Points
                            </button>
                          </>
                        ) : (
                          <>
                         
                          </>
                        )} 
                        <button onClick={()=>{setText("");
                        handleShow99()}}>
                          + Additional Points
                        </button>
                      </div>
                    </div> */}
                    <div className="d-flex justify-content-between mt-3">
              <h2 style={{color:"#FC5E04",font:"bold"}}>Additional Points</h2>
              <div className="add-b">
              <button onClick={()=>{setText("");
                        handleShow99()}}>
                          + Additional Points
                        </button>
              </div>
              </div>
                  </div>
                  </div>
                  

                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>Icon</th>
                        <th> Text</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addpoints?.map((item, i) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <img
                                  src={`https://kanagamultiservice.in/WhyAdditionalPoints/${item?.AddImg}`}
                                  alt=""
                                  style={{width:"20%",height:"40px"}}
                                />
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.Text}
                              </td>
                              <td>
                                <button className="btn btn-success">
                                  <AiTwotoneEdit
                                    onClick={() => handleShow4(item)}
                                  />
                                </button>
                              </td>
                              <td>
                                <button className="btn btn-danger">
                                  <AiTwotoneDelete
                                    style={{
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleShow98(item?._id);
                                    }}
                                  />
                                </button>
                              </td>
                            </tr>

                            {/* <tr>
                              <td>
                                <img
                                  src={`https://kanagamultiservice.in/WhyAdditionalPoints/${item?.AddImg1}`}
                                  alt=""
                                />
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.Text1}
                              </td>
                              <td>
                                <button className="btn btn-success">
                                  <AiTwotoneEdit
                                    onClick={() => handleShow4(item)}
                                  />
                                </button>
                              </td>
                              <td>
                                <button className="btn btn-danger">
                                  <AiTwotoneDelete
                                    style={{
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleShow98(item?._id);
                                    }}
                                  />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src={`https://kanagamultiservice.in/WhyAdditionalPoints/${item?.AddImg2}`}
                                  alt=""
                                />{" "}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.Text2}
                              </td>
                              <td>
                                <button className="btn btn-success">
                                  <AiTwotoneEdit
                                    onClick={() => handleShow4(item)}
                                  />
                                </button>
                              </td>
                              <td>
                                <button className="btn btn-danger">
                                  <AiTwotoneDelete
                                    style={{
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleShow98(item?._id);
                                    }}
                                  />
                                </button>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <img
                                  src={`https://kanagamultiservice.in/WhyAdditionalPoints/${item?.AddImg3}`}
                                  alt=""
                                />{" "}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.Text3}
                              </td>
                              <td>
                                <button className="btn btn-success">
                                  <AiTwotoneEdit
                                    onClick={() => handleShow4(item)}
                                  />
                                </button>
                              </td>
                              <td>
                                <button className="btn btn-danger">
                                  <AiTwotoneDelete
                                    style={{
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleShow98(item?._id);
                                    }}
                                  />
                                </button>
                              </td>
                            </tr> */}
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Why Choose Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setAbImg(e.target.files[0])}
            />
          </div>
          {/* <div className="do-sear mt-2">
            <label>Title</label>
            <input
              type="text"
              placeholder="Enter Product Name "
              className="vi_0"
              value={AbTittle}
              onChange={(e) => setAbTittle(e.target.value)}
            />
          </div> */}
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={AbDesc}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={AddBlogg}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show99} onHide={handleClose99}>
        <Modal.Header closeButton>
          <Modal.Title>Add Counts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Icon1</label>
                  <input
                    type="file"
                    className="vi_0"
                    onChange={(e) => setAddImg(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text 1</label>
                  <input
                    type="text"
                    placeholder="Enter Text 1 Here..."
                    className="vi_0"
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose99}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddPoints}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Why Choose Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              className="vi_0"
              onChange={(e) => setAbImg(e.target.files[0])}
            />
          </div>
          {/* <div className="do-sear mt-2">
            <label>Tittle</label>
            <input
              type="text"
              placeholder={DAta1.AbTittle}
              className="vi_0"
              value={AbTittle}
              onChange={(e) => setAbTittle(e.target.value)}
            />
          </div> */}

          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={DAta1?.AbDesc}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={editAddBlog}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show33}
        onHide={handleClose33}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={DeleteadddBlog}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show98}
        onHide={handleClose98}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose98}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddpoints}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddpoints}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Update Additional Points</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Icon</label>
                  <input
                    type="file"
                    className="vi_0"
                    onChange={(e) => setAddImg(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label>Text</label>
                  <input
                    type="text"
                    placeholder="Enter Text 1 Here..."
                    className="vi_0"
                    value={Text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editAddGallerry}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show98}
        onHide={handleClose98}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose98}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteadddpoints}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminWhychooseus;
