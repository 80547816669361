import React, { useEffect, useRef } from "react";
import Carousel from "react-bootstrap/Carousel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Aos from "aos";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { InputGroup } from "react-bootstrap";
import emailjs from "@emailjs/browser";

const Events = () => {
  //email integration 
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_3w3g9h7",
        "template_oi0qp23",
        form.current,
        "MUIWT1wj9Eqv9fJC0"
      )
     //  .then(
     //    (result) => {
     //      console.log(result.text, "afewaeh");
     //      window.location.assign("/services");
     //    },
     //    (error) => {
     //      console.log(error.text);
     //    }
     //  );
     .then(
       (result) => {
         console.log("Email sent successfully:", result.text);
         window.location.assign("/events");
       },
       (error) => {
         console.error("Email sending failed:", error.text);
       }
     );
  };
  const [SelectedId, setSelectedId] = useState();
  const [show, setShow] = useState(false);

  const [eventdata, seteventdata] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    seteventdata(item);
  };
  
  const [Electric, setElectric] = useState("");
  const [Catfilter, setCatfilter] = useState([]);

  // integrating post method for event enquiry
  const [EEName, setEEName] = useState("");
  const [EEmail, setEEmail] = useState("");
  const [EPhone, setEPhone] = useState("");
  const [EComName, setEComName] = useState("");
  const [ELoc, setELoc] = useState("");
  const [EAltPhone, setEAltPhone] = useState("");
  const [ERequire, setERequire] = useState("");

  const EnquiryE = async () => {
    try {
      if(!EEName) return alert("Please Enter Your  Name");
      if(!EEmail) return alert("Please Enter Your Email id");
      if(!EPhone) return alert("Please Enter Your Phone no");
      if(!EComName) return alert("Please Enter  Company ");
      if(!ELoc) return alert("Please Enter the Location");
      if(!EAltPhone) return alert("Please Enter the Alternate Phone no");
      if(!ERequire) return alert("Please Enter Your Requirement");
      const config = {
        url: "/user/Eenquiry",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "application/json" },
        data: {
          EventName: eventdata?.EName,
          EEName: EEName,
          EEmail: EEmail,
          EPhone: EPhone,
          EComName: EComName,
          ELoc: ELoc,
          EAltPhone: EAltPhone,
          ERequire: ERequire,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
     setEEName("");
          setEEmail("");
          setEPhone("");
          setEComName("");
          setELoc("");
          setEAltPhone("");
          setERequire("");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating banner get method
  const [AddEventsbanner, setAddEventsbanner] = useState([]);
  const getAddEventsBanner = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddEBanners"
      );
      if (res.status == 200) {
        setAddEventsbanner(res.data.getadddEBanners);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating event list get  method
  const [EventsList, setEventsList] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getEventsList = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallEventsList"
      );
      if (res.status === 200) {
        setEventsList(res.data.getAddEventsList);
        setnochangedata(res.data.getAddEventsList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating upcoming events get  method
  const [UEventsList, setUEventsList] = useState([]);
  const getUEventsList = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallUEventsList"
      );
      if (res.status === 200) {
        setUEventsList(res.data.getAddUEventsList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setEventsList([...filterTable]);
    } else {
      setSearch(e.target.value);
      setEventsList([...nochangedata]);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  const searchedProduct = EventsList.filter((item) => {
    if (searchTerm.value === "") {
      return item;
    }
    if (item?.EName.toLowerCase().includes(searchTerm.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  const [Viewmore, setViewmore] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };
  const [Viewmore1, setViewmore1] = useState(true);

  const [expanded1, setExpanded1] = useState(false);
  const toggleExpansion1 = () => {
    setExpanded1(!expanded1);
  };
  useEffect(() => {
    window.scroll(0,0);
    Aos.init();
    getAddEventsBanner();
    getEventsList();
    getUEventsList();
  }, []);
  useEffect(() => {
    if (Electric === "All") {
      setCatfilter(EventsList);
    } else if (Electric && Electric !== "All") {
      const xyz = EventsList?.filter((item) => item.EName === Electric);
      setCatfilter(xyz);
    } else {
      setCatfilter(EventsList);
    }
  }, [Electric]);

  console.log("Catfilter", Catfilter, EventsList);
  console.log("SortAll", Electric);

  return (
    <div>
      {/* <div
        className="container mt-3 mb-2"
        style={{
          backgroundImage: 'url("../we-provide-bg.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "200px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-slider3 text-center">
          <h1
            style={{
              color: "white",
              marginTop: "66px",
              fontSize: "50px",
            }}
          >
            Events
          </h1>
        </div>
      </div> */}

      <div className="container mt-4">
        <div className="row text-center">
          <h1>
            Our <span style={{ color: "#FE5E00" }}>Activities/Exhibition</span>
          </h1>
        </div>
        <div className="row">
          <Carousel fade>
            {AddEventsbanner.map((item, i) => {
              return (
                <Carousel.Item>
                  <img
                    src={`https://kanagamultiservice.in/EventBanner/${item?.EBanners}`}
                    alt="First slide"
                    style={{
                      width: "100%",
                      height: "300px",
                      position: "relative",
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center pt-4">
            <button
              className="btn btn-danger"
              onClick={()=>{handleShow()}}
              style={{
                backgroundColor: "#FE5E00",
                border: "1px solid #FE5E00",
              }}
            >
              Book Your Slot
            </button>
          </div>
        </div>
      </div>
      <div></div>

      <div className="container" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="row mt-1" style={{ padding: "20px" }}>
          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
            <Col>
              <Form.Select onChange={(e) => setElectric(e.target.value)}>
                <option>---Search for the Events---</option>
                <option value="All">All Events</option>
                {EventsList?.map((item) => {
                  return <option value={item?.EName}>{item?.EName}</option>;
                })}
              </Form.Select>
            </Col>
          </div>
          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
            <Form inline>
              <Row>
                <Col xs="auto">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className=" mr-sm-2"
                    onChange={handleFilter}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>

      <div className="container ">
        {Catfilter?.length > 0 ? (
          <></>
        ) : (
          searchedProduct?.map((item) => {
            return (
              <div className="row pt-4">
                <div
                  className="row event"
                  style={{
                    boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)",
                    paddingTop: "40px",
                    borderRadius: "10px",
                  }}
                >
                  <div className="col-md-3">
                    <div
                    className="eventdate"
                      style={{
                        position: "relative",
                        top: "79px",
                        left: "-40px",
                        backgroundColor: "#FE5E00",
                        color: "white",
                        width: "25%",
                        backdropFilterorderRadius: "5px",
                        padding: "5px",
                        zIndex: "1",
                        textAlign: "center",
                        borderRadius: "5px",
                      }}
                    >
                      {moment(item?.ESDate).format("MMM Do YYYY")}
                    </div>
                    <div>
                      <figure>
                        <img
                          src={`https://kanagamultiservice.in/Events/${item?.EImage}`}
                          alt=""
                          className="img-fluid"
                          style={{
                            width: "80%",
                            height: "197px",
                            position: "relative",
                            top: "-51px",
                            Left: "20px",
                          }}
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-9 p-2">
                    <h4 style={{ color: "#FE5E00" }}>{item?.EName}</h4>
                    <h6 style={{ fontSize: "15px" }}>
                      {" "}
                      <span style={{ fontWeight: "550" }}>
                        Event Start Date :
                      </span>{" "}
                      {moment(item?.ESDate).format("MMM Do YY")}
                    </h6>
                    <h6 style={{ fontSize: "15px" }}>
                      {" "}
                      <span style={{ fontWeight: "550" }}>
                        Event End Date :
                      </span>{" "}
                      {moment(item?.EEDate).format("MMM Do YY")}
                    </h6>
                    <h5 style={{ fontSize: "15px" }}>
                      <span style={{ fontWeight: "550" }}>
                        Event Location :
                      </span>{" "}
                      {item?.ELoc}
                    </h5>
                    <div className="row">
                      <div className="col-md-8">
                        <p>
                          <h5
                            className="text-decoration-underline"
                            style={{ fontSize: "15px", fontWeight: "550" }}
                          >
                            Exhibits
                          </h5>
                          <p style={{ textAlign: "justify" }}>
                            {SelectedId === item?._id && Viewmore
                              ? parse(`<div>${item?.EDesc}</div>`)
                              : parse(
                                  `<div>${item?.EDesc.slice(0, 170)}</div>`
                                )}
                          </p>
                          {SelectedId === item?._id && Viewmore ? (
                            <>
                              <div
                                style={{ float: "right", marginTop: "-20px" }}
                              >
                                <a
                                  onClick={() => {
                                    setSelectedId();
                                    setViewmore(!Viewmore);
                                  }}
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  Show less...
                                </a>
                              </div>
                            </>
                          ) : (
                            <div style={{ float: "right", marginTop: "-20px" }}>
                              <a
                                onClick={() => {
                                  setSelectedId(item?._id);
                                  setViewmore(!Viewmore);
                                }}
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Show more...
                              </a>
                            </div>
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 text-center mt-3">
                        <button
                          className="btn btn-danger"
                          style={{
                            backgroundColor: "#FE5E00",
                            border: "1px solid #FE5E00",
                            color: "white",
                            padding: "10px",
                          }}
                          onClick={() => handleShow(item)}
                        >
                          enquire now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="container">
        {Catfilter?.length > 0 ? (
          <>
            {Catfilter?.map((item) => {
              return (
              <div className="row pt-4">
                  <div className="row "
                style={{
                  boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)",
                  paddingTop: "40px",
                  borderRadius: "10px",
                }}>
                  <div className="col-md-3">
                    <div
                      style={{
                        position: "relative",
                        top: "79px",
                        left: "-40px",
                        backgroundColor: "#FE5E00",
                        color: "white",
                        width: "25%",
                        backdropFilterorderRadius: "5px",
                        padding: "5px",
                        zIndex: "1",
                        textAlign: "center",
                        borderRadius: "5px",
                      }}
                    >
                      {moment(item?.ESDate).format("MMM Do YYYY")}
                    </div>
                    <div>
                      <figure>
                        <img
                          src={`https://kanagamultiservice.in/Events/${item?.EImage}`}
                          alt=""
                          className="img-fluid"
                          style={{
                            width: "80%",
                            height: "197px",
                            position: "relative",
                            top: "-51px",
                            Left: "20px",
                          }}
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-9 p-2">
                    <h4 style={{ color: "#FE5E00" }}>{item?.EName}</h4>
                    <h6 style={{ fontSize: "15px" }}>
                      {" "}
                      <span style={{ fontWeight: "550" }}>
                        Event Start Date :
                      </span>{" "}
                      {moment(item?.ESDate).format("MMM Do YY")}
                    </h6>
                    <h6 style={{ fontSize: "15px" }}>
                      {" "}
                      <span style={{ fontWeight: "550" }}>
                        Event End Date :
                      </span>{" "}
                      {moment(item?.EEDate).format("MMM Do YY")}
                    </h6>
                    <h5 style={{ fontSize: "15px" }}>
                      <span style={{ fontWeight: "550" }}>
                        Event Location :
                      </span>{" "}
                      {item?.ELoc}
                    </h5>
                    <div className="row">
                      <div className="col-md-8">
                        <p>
                          <h5
                            className="text-decoration-underline"
                            style={{ fontSize: "15px", fontWeight: "550" }}
                          >
                            Exhibits
                          </h5>
                          <p style={{ textAlign: "justify" }}>
                            {SelectedId === item?._id && Viewmore
                              ? parse(`<div>${item?.EDesc}</div>`)
                              : parse(
                                  `<div>${item?.EDesc.slice(0, 170)}</div>`
                                )}
                          </p>
                          {SelectedId === item?._id && Viewmore ? (
                            <>
                              <div
                                style={{ float: "right", marginTop: "-20px" }}
                              >
                                <a
                                  onClick={() => {
                                    setSelectedId();
                                    setViewmore(!Viewmore);
                                  }}
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  Show less...
                                </a>
                              </div>
                            </>
                          ) : (
                            <div style={{ float: "right", marginTop: "-20px" }}>
                              <a
                                onClick={() => {
                                  setSelectedId(item?._id);
                                  setViewmore(!Viewmore);
                                }}
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Show more...
                              </a>
                            </div>
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 text-center mt-3">
                        <button
                          className="btn btn-danger"
                          style={{
                            backgroundColor: "#FE5E00",
                            border: "1px solid #FE5E00",
                            color: "white",
                            padding: "10px",
                          }}
                          onClick={() => handleShow(item)}
                        >
                          enquire now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              );
            })}
            {/* {searchedProduct?.map((item) => {
              return (
                <div className="row ">
                  <div className="col-md-3">
                    <div
                      style={{
                        position: "relative",
                        top: "79px",
                        left: "-40px",
                        backgroundColor: "#FE5E00",
                        color: "white",
                        width: "25%",
                        backdropFilterorderRadius: "5px",
                        padding: "5px",
                        zIndex: "1",
                        textAlign: "center",
                        borderRadius: "5px",
                      }}
                    >
                      {moment(item?.ESDate).format("MMM Do YYYY")}
                    </div>
                    <div>
                      <figure>
                        <img
                          src={`https://kanagamultiservice.in/Events/${item?.EImage}`}
                          alt=""
                          className="img-fluid"
                          style={{
                            width: "80%",
                            height: "197px",
                            position: "relative",
                            top: "-51px",
                            Left: "20px",
                          }}
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-9 p-2">
                    <h4 style={{ color: "#FE5E00" }}>{item?.EName}</h4>
                    <h6 style={{ fontSize: "15px" }}>
                      {" "}
                      <span style={{ fontWeight: "550" }}>
                        Event Start Date :
                      </span>{" "}
                      {moment(item?.ESDate).format("MMM Do YY")}
                    </h6>
                    <h6 style={{ fontSize: "15px" }}>
                      {" "}
                      <span style={{ fontWeight: "550" }}>
                        Event End Date :
                      </span>{" "}
                      {moment(item?.EEDate).format("MMM Do YY")}
                    </h6>
                    <h5 style={{ fontSize: "15px" }}>
                      <span style={{ fontWeight: "550" }}>
                        Event Location :
                      </span>{" "}
                      {item?.ELoc}
                    </h5>
                    <div className="row">
                      <div className="col-md-8">
                        <p>
                          <h5
                            className="text-decoration-underline"
                            style={{ fontSize: "15px", fontWeight: "550" }}
                          >
                            Exhibits
                          </h5>
                          <p style={{ textAlign: "justify" }}>
                            {SelectedId === item?._id && Viewmore
                              ? parse(`<div>${item?.EDesc}</div>`)
                              : parse(
                                  `<div>${item?.EDesc.slice(0, 175)}</div>`
                                )}
                          </p>
                          {SelectedId === item?._id && Viewmore ? (
                            <>
                              <div
                                style={{ float: "right", marginTop: "-40px" }}
                              >
                                <a
                                  onClick={() => {
                                    setSelectedId();
                                    setViewmore(!Viewmore);
                                  }}
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  Show less...
                                </a>
                              </div>
                            </>
                          ) : (
                            <div style={{ float: "right", marginTop: "-40px" }}>
                              <a
                                onClick={() => {
                                  setSelectedId(item?._id);
                                  setViewmore(!Viewmore);
                                }}
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Show more...
                              </a>
                            </div>
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 text-center mt-3">
                        <button
                          className="btn btn-danger"
                          style={{
                            backgroundColor: "#FE5E00",
                            border: "1px solid #FE5E00",
                            color: "white",
                            padding: "10px",
                          }}
                          onClick={() => handleShow(item)}
                        >
                          enquire now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}
          </>
        ) : (
          <>
            {/* {UEventsList?.map((item) => {
              return (
                <div className="row ">
                  <div className="col-md-3">
                    <div>
                      <figure>
                        <img
                          src={`https://kanagamultiservice.in/UpcomingEvents/${item?.UEImage}`}
                          alt=""
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-9 p-2">
                    <h4 style={{ color: "#FE5E00" }}>{item?.UEName}</h4>
                    <h6> Event Start Date : {item?.UESDate}</h6>
                    <h6> Event End Date : {item?.UEEDate}</h6>
                    <h5>
                      Event Location &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                      {item?.UELoc}
                    </h5>
                    <p>
                      <p style={{ textAlign: "justify" }}>
                        {SelectedId === item?._id && Viewmore
                          ? parse(`<div>${item?.UEDesc}</div>`)
                          : parse(`<div>${item?.UEDesc.slice(0, 150)}</div>`)}
                      </p>
                      {SelectedId === item?._id && Viewmore ? (
                        <>
                          <div style={{ float: "right" }}>
                            <a
                              onClick={() => {
                                setSelectedId();
                                setViewmore(!Viewmore);
                              }}
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              Show less...
                            </a>
                          </div>
                          <button
                            className="btn btn-danger"
                            style={{
                              backgroundColor: "#FE5E00",
                              border: "1px solid #FE5E00",
                              color: "white",
                              padding: "10px",
                            }}
                            onClick={() => handleShow(item)}
                          >
                            enquire now
                          </button>
                        </>
                      ) : (
                        <div style={{ float: "right" }}>
                          <a
                            onClick={() => {
                              setSelectedId(item?._id);
                              setViewmore(!Viewmore);
                            }}
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Show more...
                          </a>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              );
            })} */}
          </>
        )}
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#FE5E00" }}>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} 
            onSubmit={sendEmail}>
            <Row>
              <Col>
                <Form.Control
                  placeholder="First name"
                  type="text"
                  id="user_Fname"
                  name="user_Fname"
                  onChange={(e) => setEEName(e.target.value)}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Email"
                  type="email"
                  id="user_email"
                    name="user_email"
                  onChange={(e) => setEEmail(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Control
                  placeholder="Mobile"
                  name="user_phone"
                  onChange={(e) => setEPhone(e.target.value)}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Whatsapp / Alternative no"
                  onChange={(e) => setEAltPhone(e.target.value)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Control
                  placeholder="Company name"
                  onChange={(e) => setEComName(e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Location"
                  onChange={(e) => setELoc(e.target.value)}
                />
              </Col>
            </Row>
            <br />

            <Form.Control
              as="textarea"
              type="text"
              name="user_message"
              placeholder="Brief about your requirement"
              style={{ height: "100px" }}
              onChange={(e) => setERequire(e.target.value)}
            />

<br />
              <div className="d-flex justify-content-end gap-3">
              <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
              <Button
            type="submit"
            value="Send"
              variant="btn-danger"
              style={{ backgroundColor: "#FE5E00", color: "white" }}
              onClick={EnquiryE}
              // state={{ item: item }}
            >
              Send Request
            </Button>
              </div>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="btn-danger"
            style={{
              backgroundColor: "#FE5E00",
              border: "1px solid #FE5E00",
              color: "white",
            }}
            onClick={()=>{
             
              EnquiryE()}}
          >
            Send Request
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Events;
