import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BiLogOutCircle } from "react-icons/bi";

function AdminHeader() {
  const logOut = () => {
    sessionStorage.removeItem("admin");
    sessionStorage.removeItem("token");
    window.location.assign("/admin");
  };
  let admin = JSON.parse(sessionStorage.getItem("admin"));
  return (
    <>
      <div
        className="header"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <Navbar expand="lg" style={{ marginLeft: "4rem" }}>
          <Container fluid>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="ms-auto my-2 my-lg-0"
                navbarScroll
              >
                <Nav.Link href="#" onClick={logOut}>
                  <BiLogOutCircle style={{ fontSize: "25px", color: "#FE5E00" }} />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default AdminHeader;
