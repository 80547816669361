import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image, Pagination, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
const AdminOurteam = () => {
  const [profile, setProfile] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  // integrating post method for experties
  const formdata = new FormData();
  const [AbImg, setAbImg] = useState("");
  const [AbName, setAbName] = useState("");
  const [AbDesignation, setAbDesignation] = useState("");
  const AddGrapph = async () => {
    formdata.append("AbImg", AbImg);
    formdata.append("AbName", AbName);
    formdata.append("AbDesignation", AbDesignation);

    try {
      if(!AbImg) return alert("Please Select the Image");
      if(!AbName) return alert("Please Enter the Name");
      if(!AbDesignation) return alert("Please Enter the Designation");
      const config = {
        url: "/Admin/AddExperts",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddGraph();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddGraph, setAddGraph] = useState([]);
  const [nochangedataH, setnochangedataH] = useState([]);
  const getAddGraph = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/Admin/getallExperts"
      );
      if (res.status === 200) {
        setAddGraph(res.data.getallExperts);
        setnochangedataH(res.data.getallExperts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setData(item);
  };
  const Deletegallery = async () => {
    try {
      const config = {
        url: "Admin/DeleteExperts/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddGraph();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data2, setData2] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData2(item);
    setAbName(item?.AbName);
    setAbDesignation(item?.AbDesignation);
  };

  const editgallery = async (e) => {
    e.preventDefault();
    
    formdata.append("AbImg", AbImg);
    formdata.append("AbName", AbName);
    formdata.append("AbDesignation", AbDesignation);
    formdata.append("id", Data2?._id);
    try {
      
      const config = {
        url: "/Admin/editExperts",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose3();
          getAddGraph();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddGraph();
  }, []);
  console.log(AddGraph);
  // integrating post method
  const [Eheader, setEheader] = useState("");

  // const AddTestimonials = async () => {
  //   try {
  //     const config = {
  //       url: "/admin/AddExpertsheader",
  //       method: "post",
  //       baseURL: "https://kanagamultiservice.in/api",
  //       header: { "content-type": "application/json" },
  //       data: {
  //         // Eheader: Eheader,
  //       },
  //     };
  //     await axios(config).then((res) => {
  //       if (res.status === 200) {
  //         alert(res.data.success);
  //         getAddTestimonials();
  //         handleClose2();
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     alert(error.response.data.msg);
  //   }
  // };
  //integrating get  method
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonials = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get(
        "https://kanagamultiservice.in/api/Admin/getallExperts"
      );
      if (res.status == 200) {
        setAddTestimonial(res.data.getallExpertsheader);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data4, setData4] = useState("");
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = (item) => {
    setShow7(true);
    setData4(item);
  };
  const deleteTestimonials = async () => {
    try {
      const config = {
        url: "admin/DeleteExpertsheader/" + Data4,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddTestimonials();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Aexp, setAexp] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setAexp(item);
    setAbName(item?.AbName);
    setAbDesignation(item?.AbDesignation);
  };
  const editTestimonials = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/editExpertsHeader",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          id: Aexp?._id,
          // Eheader: Eheader,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddTestimonials();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAddTestimonials();
  }, []);
  console.log(AddTestimonial);
   // Pagination
   const [pageNumber, setPageNumber] = useState(0);
   const productPerPage = 5;
   const visitedPage = pageNumber * productPerPage;
   const displayPage = AddGraph.slice(visitedPage, visitedPage + productPerPage);
   const pageCount = Math.ceil(AddGraph.length / productPerPage);

      //search filter for our team
      const [searchH, setSearchH] = useState("");
      const handleFilterH = (e) => {
        if (e.target.value != "") {
          setSearchH(e.target.value);
          const filterTableH = nochangedataH.filter((o) =>
            Object.keys(o).some((k) =>
              String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
            )
          );
          setAddGraph([...filterTableH]);
        } else {
          setSearchH(e.target.value);
          setAddGraph([...nochangedataH]);
        }
      };
      const [searchTermH, setSearchTermH] = useState("");
      const searchedProductH = AddGraph.filter((item) => {
        if (searchTermH.value === "") {
          return item;
        }
        if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
          return item;
        } else {
          return console.log("not found");
        }
      });
  return (
    <>
      <div className="ms-5 mt-3">
        <div className="container">
        <div className="row mb-2">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilterH}
                />
              </Form>
            </div>
           
            <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                Our Team
                  </h2>
                  <div className="add-b">
                  {AddTestimonial?.length !== 4 ? (
              <>
              <button onClick={handleShow}>Add Team member</button>
              </>
            ) : (
              <>
                
              </>
            )}
                  </div>
                </div>
          </div>
         
        </div>
        <div className="container">
          <div className="mt-1">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {displayPage?.map((item, i) => {
                const serialNumber = i + 1 + visitedPage;

                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>
                        {" "}
                        <Image
                          src={`https://kanagamultiservice.in/OurTeam/${item?.AbImg}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>
                      <td>{item?.AbName}</td>
                      <td>{item?.AbDesignation}</td>
                      <td>
                        {" "}
                        <button className="btn btn-success">
                          <AiTwotoneEdit onClick={() => handleShow3(item)} />
                        </button>
                      </td>
                      <td>
                        {" "}
                        <button className="btn btn-danger">
                          <AiTwotoneDelete
                            style={{
                              // color: "red",
                              fontSize: "20px",
                              // paddingTop: "20px",
                            }}
                            onClick={() => {
                              handleShow5(item?._id);
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination style={{ float: "right" }}>
                <Pagination.First onClick={() => setPageNumber(0)} />
                <Pagination.Prev
                  onClick={() => setPageNumber((prev) => Math.max(prev - 1, 0))}
                />
                {Array.from({ length: pageCount }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === pageNumber}
                    onClick={() => setPageNumber(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                  }
                />
                <Pagination.Last onClick={() => setPageNumber(pageCount - 1)} />
              </Pagination>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Our Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setAbImg(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter Text Here..."
              className="vi_0"
              onChange={(e) => setAbName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Designation</label>
            <input
              type="Designation"
              placeholder="Enter Designation Here..."
              className="vi_0"
              onChange={(e) => setAbDesignation(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddGrapph}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Add Header</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Header</label>
            <input
              type="text"
              placeholder="Enter Header Here..."
              className="vi_0"
              onChange={(e) => setEheader(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddTestimonials}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Update Team </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Text Here..."
              className="vi_0"
              onChange={(e) => setAbImg(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter Text Here..."
              className="vi_0"
              value={AbName}
              onChange={(e) => setAbName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Designation</label>
            <input
              type="Designation"
              placeholder="Enter Designation Here..."
              className="vi_0"
              value={AbDesignation}
              onChange={(e) => setAbDesignation(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editgallery}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={Deletegallery}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show7} onHide={handleClose7}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose7}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteTestimonials}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Header</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Header</label>
            <input
              type="text"
              placeholder="Enter Header Here..."
              className="vi_0"
              value={Eheader}
              onChange={(e) => setEheader(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editTestimonials}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default AdminOurteam;
