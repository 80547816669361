import React, { useEffect, useState } from "react";
import { Button, Table, Image, Pagination, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiTwotoneDelete } from "react-icons/ai";
import { useLocation } from "react-router-dom";

const AdminServiceEnquiry = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleShow = (item) => {
    setShow(true);
    setservicedata(item);
  };
  // integrating post method
  const [SFName, setSFName] = useState("");
  const [SLName, setSLName] = useState("");
  const [SPhone, setSPhone] = useState("");
  const [SEmail, setSEmail] = useState("");
  const [SDesiFrom, setSDesiFrom] = useState("");
  const [SDesiTo, setSDesiTo] = useState("");
  const [SRquire, setSRquire] = useState("");
  const [servicedata, setservicedata] = useState({});

  const EnquiryS = async () => {
    try {
      if(!SFName) return alert("Please Enter Your First Name");
      if(!SLName) return alert("Please Enter Your Last Name");
      if(!SPhone) return alert("Please Enter Your Phone no");
      if(!SEmail) return alert("Please Enter Your Email id");
      if(!SDesiFrom) return alert("Please Select From Date");
      if(!SDesiTo) return alert("Please Select To Date");
      if(!SRquire) return alert("Please Enter Your Requirement");
      const config = {
        url: "/user/Senquiry",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          // ServicetName: item.ServicetName,
          ServiceName: servicedata?.SHeader,
          SFName: SFName,
          SLName: SLName,
          SPhone: SPhone,
          SEmail: SEmail,
          SDesiFrom: SDesiFrom,
          SDesiTo: SDesiTo,
          SRquire: SRquire,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  // const EnquiryS = async () => {
  //   const location = useLocation();
  //   const { item } = location.state;

  // console.log("item", item);
  //   try {
  //     const config = {
  //       url: "/user/Senquiry",
  //       method: "post",
  //       baseURL: "https://kanagamultiservice.in/api",
  //       header: { "content-type": "application/json" },
  //       data: {
  //         ServicetName: item.ServicetName,
  //         SFName: SFName,
  //         SLName: SLName,
  //         SPhone: SPhone,
  //         SEmail: SEmail,
  //         SDesiFrom: SDesiFrom,
  //         SDesiTo: SDesiTo,
  //         SRquire: SRquire,
  //       },
  //     };
  //     await axios(config).then((res) => {
  //       if (res.status === 200) {
  //         alert(res.data.success);
  //         getAddEnquirys();
  //         handleClose();
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     alert(error.response.data.msg);
  //   }
  // };
  //integrating get  method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [AddEnquiryS, setAddEnquiryS] = useState([]);
  const getAddEnquirys = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/user/getallSenquiry"
      );
      if (res.status === 200) {
        setAddEnquiryS(res.data.getSenquirynow);
        setnochangedataH(res.data.getSenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteEnquiryS = async () => {
    try {
      const config = {
        url: "user/deleteSenquiry/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddEnquirys();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  // Product Category
  // const [AddProductcate, setAddProductcate] = useState([]);
  // const getAddProductcate = async () => {
  //     try {
  //         // alert("fdffdd")
  //         let res = await axios.get(
  //             "https://kanagamultiservice.in/api/admin/getallProductCat"
  //         );
  //         if (res.status == 200) {
  //             setAddProductcate(res.data.getAddProductCat);
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };
  // Product Brand
  // const [AddProductss, setAddProductss] = useState([]);
  // const getProducts = async () => {
  //     try {
  //         let res = await axios.get(
  //             "https://kanagamultiservice.in/api/admin/getallProductList"
  //         );
  //         if (res.status === 200) {
  //             setAddProductss(res.data.getAddProductList);
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };
  useEffect(() => {
    getAddEnquirys();
    // getAddProductcate();
    // getProducts();
  }, []);
  console.log(AddEnquiryS);
  // console.log(AddProductcate)
  // console.log(AddProductss);

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 10;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = AddEnquiryS.slice(
    visitedPage,
    visitedPage + productPerPage
  );
  const pageCount = Math.ceil(AddEnquiryS.length / productPerPage);

  //search filter for service request
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAddEnquiryS([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAddEnquiryS([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = AddEnquiryS.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.SFName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  return (
    <>
      <div className="container">
        <div className="mo-gra mt-3 ms-5">
          <div className="row">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilterH}
                />
              </Form>
            </div>
            <div className="col-md-4">
              <div className="add-b text-end  mb-4 col-md-12">
                
              </div>
            </div>
            <div className="d-flex justify-content-between ">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
            Service Enquiry
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                  setSFName("");
                  setSLName("");
                  setSPhone("");
                  setSEmail("");
                  setSDesiFrom("");
                  setSDesiTo("");
                  setSRquire("");
                  handleShow()}}>Add Service Enquiry</button>
                  </div>
                </div>
          </div>

          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Service Type</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Destination(from)</th>
                <th>Destination(to)</th>
                <th>Message</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {displayPage.map((item, i) => {
                const serialNumber = i + 1 + visitedPage;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{item?.ServiceName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.SFName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.SLName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.SPhone}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.SEmail}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.SDesiFrom}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.SDesiTo}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.SRquire}</td>

                    <td>
                      <button className="btn btn-danger">
                        <AiTwotoneDelete
                          style={{
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            handleShow2(item?._id);
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination style={{ float: "right" }}>
            <Pagination.First onClick={() => setPageNumber(0)} />
            <Pagination.Prev
              onClick={() => setPageNumber((prev) => Math.max(prev - 1, 0))}
            />
            {Array.from({ length: pageCount }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index === pageNumber}
                onClick={() => setPageNumber(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
              }
            />
            <Pagination.Last onClick={() => setPageNumber(pageCount - 1)} />
          </Pagination>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Service Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>First Name</label>
            <input
              type="text"
              placeholder="Enter First Name"
              className="vi_0"
              onChange={(e) => setSFName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Enter Last Name"
              className="vi_0"
              onChange={(e) => setSLName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="vi_0"
              onChange={(e) => setSEmail(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Phone No.</label>
            <input
              type="number"
              placeholder="Enter Phone No."
              className="vi_0"
              onChange={(e) => setSPhone(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Destination(From)</label>
            <input
              type="date"
              placeholder="Enter Destination"
              className="vi_0"
              onChange={(e) => setSDesiFrom(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Destination(To)</label>
            <input
              type="date"
              placeholder="Enter Destination"
              className="vi_0"
              onChange={(e) => setSDesiTo(e.target.value)}
            />
          </div>
          {/* <div className="do-sear mt-2">
            <label>Service Type</label>
            <select name="" id="" className="vi_0" 
            onChange={(e) => setservicedata(e.target.value)}>
              <option value="">--select type--</option>
              {AddEnquiryS?.map((item, i) => {
                return <option value={item?.ServiceName}>{item?.ServiceName}</option>;
              })}
            </select>
          </div> */}
          <div className="do-sear mt-2">
            <label>Service Requirement</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="3"
              placeholder="Enter Requirement"
              className="vi_0"
              onChange={(e) => {
                setSRquire(e.target.value);
              }}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={EnquiryS}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={DeleteEnquiryS}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminServiceEnquiry;
