import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminHome = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method

  const [GrImage, setGrImage] = useState("");
  const [GrText, setGrText] = useState("");
  const [GrText2, setGrText2] = useState("");
  const [GrText3, setGrText3] = useState("");
  const AddGrapph = async () => {
    const formdata = new FormData();
    formdata.append("GrImage", GrImage);
    formdata.append("GrText", GrText);
    formdata.append("GrText2", GrText2);
    formdata.append("GrText3", GrText3);

    try { 
       if(!GrImage) return alert("Please Select the Image");
    if(!GrText) return alert("Please Enter the Text");
    if(!GrText2) return alert("Please Enter the Text2");
    if(!GrText3) return alert ("Please Enter the Text3");
      const config = {
        url: "/admin/graph",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getAddGraph();
        
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [AddGraph, setAddGraph] = useState([]);
  const getAddGraph = async () => {
    try {
      let res = await axios.get("https://kanagamultiservice.in/api/admin/getgraph");
      if (res.status === 200) {
        setAddGraph(res.data.getgraph);
        setnochangedataH(res.data.getgraph);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const Deletegallery = async () => {
    try {
      const config = {
        url: "admin/Deletegraph/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddGraph();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setGrText(item?.GrText);
    setGrText2(item?.GrText2);
    setGrText3(item?.GrText3);
  };

  const editgallery = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    // if (!GrImage) {
    //   alert("Select the image");
    // } else {
    formdata.append("GrImage", GrImage);
    formdata.append("GrText", GrText);
    formdata.append("GrText2", GrText2);
    formdata.append("GrText3", GrText3);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "/admin/editgraph",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddGraph();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddGraph();
  }, []);
  console.log(AddGraph);
   //search filter for graph
   const [searchH, setSearchH] = useState("");
   const handleFilterH = (e) => {
     if (e.target.value != "") {
       setSearchH(e.target.value);
       const filterTableH = nochangedataH.filter((o) =>
         Object.keys(o).some((k) =>
           String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
         )
       );
       setAddGraph([...filterTableH]);
     } else {
       setSearchH(e.target.value);
       setAddGraph([...nochangedataH]);
     }
   };
   const [searchTermH, setSearchTermH] = useState("");
   const searchedProductH = AddGraph.filter((item) => {
     if (searchTermH.value === "") {
       return item;
     }
     if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
       return item;
     } else {
       return console.log("not found");
     }
   });
  return (
    <>
      <div className="ms-5">
        <div className="">
          <div className="container">
          <div className="row mb-3 mt-3">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilterH}
                />
              </Form>
            </div>
          
            <div className="add-b d-flex justify-content-between mt-2">
              <div>
                <h2 style={{color:"#FC5E04",font:"bold"}}>Home Graph</h2>
              </div>
              <div className="">
               
              <button onClick={()=>{
                setGrText("");
                setGrText2("");
                setGrText3("");
                handleShow()}}>Add Graph</button>
                 
              </div>
            </div>
          
          </div>
         
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-4">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  <th>Text1</th>
                  <th>Text2</th>
                  <th>Text3</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {AddGraph.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          src={`https://kanagamultiservice.in/HomeGraph/${item?.GrImage}`}
                          alt="picd"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>

                      <td style={{ paddingTop: "20px" }}>{item.GrText}</td>
                      <td style={{ paddingTop: "20px" }}>{item.GrText2}</td>
                      <td style={{ paddingTop: "20px" }}>{item.GrText3}</td>

                      <td>
                        <button className="btn btn-success">
                          <AiTwotoneEdit onClick={() => handleShow1(item)} />
                        </button>

                        {/* <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => handleShow1(item)}
                          // title="edit"
                          ></i>
                        </span>
                      </div> */}
                      </td>
                      <td>
                        <button className="btn btn-danger">
                          <AiTwotoneDelete
                            style={{
                              // color: "red",
                              fontSize: "20px",
                              // paddingTop: "20px",
                            }}
                            onClick={() => {
                              handleShow2(item?._id);
                            }}
                          />
                        </button>
                        {/* <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                      // title="delete"
                      ></i> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Graph</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="file"
                placeholder="Enter Years"
                className="vi_0"
                onChange={(e) => setGrImage(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Text1</label>
              <input
                type="text"
                placeholder="Enter Text 1"
                className="vi_0"
                onChange={(e) => setGrText(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Text2</label>
              <input
                type="text"
                placeholder="Enter Text 2"
                className="vi_0"
                onChange={(e) => setGrText2(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Text3</label>
              <input
                type="text"
                placeholder="Enter Text 3"
                className="vi_0"
                onChange={(e) => setGrText3(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "#00a695", border: "none" }}
              onClick={AddGrapph}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>UPdate Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="file"
                placeholder="Enter Years"
                className="vi_0"
                onChange={(e) => setGrImage(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Text1</label>
              <input
                type="text"
                placeholder="Enter Years"
                value={GrText}
                className="vi_0"
                onChange={(e) => setGrText(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Text2</label>
              <input
                type="text"
                placeholder="Enter Years"
                value={GrText2}
                className="vi_0"
                onChange={(e) => setGrText2(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Text3</label>
              <input
                type="text"
                placeholder="Enter Years"
                value={GrText3}
                className="vi_0"
                onChange={(e) => setGrText3(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "#00a695", border: "none" }}
              onClick={editgallery}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={Deletegallery}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminHome;
