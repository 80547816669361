import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import Process from "./Process";
import { AiFillPushpin } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import { BsFillCaretRightFill } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import parse from "html-react-parser";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";

const Home = () => {
  const [counterOn, setCounterOn] = useState(false);
  const navigate = useNavigate();
  const [Viewmore, setViewmore] = useState(true);

  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };
  const toggleExpansion1 = () => {
    setExpanded1(!expanded1);
  };
  const toggleExpansion2 = () => {
    setExpanded2(!expanded2);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  const [AddGraphC, setAddGraphC] = useState([]);
  const getAddGraphC = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getgraph"
      );
      if (res.status === 200) {
        setAddGraphC(res.data.getgraph);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating Services List get method
  const [AddServiceLists, setAddServiceList] = useState([]);
  const getAddServiceList = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/Admin/getaddServiceList"
      );
      if (res.status == 200) {
        setAddServiceList(res.data.getadddServiceList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating services get  method
  const [AddGraphS, setAddGraphS] = useState([]);
  const getAddGraphS = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallService"
      );
      if (res.status === 200) {
        setAddGraphS(res.data.getAddService);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating products get  method
  const [AddProductss, setAddProductss] = useState([]);

  const getProducts = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallProducts"
      );
      if (res.status === 200) {
        setAddProductss(res.data.getAddProducts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating banner get method
  const [AddEventsbanner, setAddEventsbanner] = useState([]);
  const getAddEventsBanner = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddEBanners"
      );
      if (res.status == 200) {
        setAddEventsbanner(res.data.getadddEBanners);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method
  const [AddBlog, setAddBlog] = useState([]);
  const getAddBlog = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddblog"
    );
    if (res.status === 200) {
      setAddBlog(res.data.getAddBlog);
    }
  };

  // get method for addpoints
  const [addpoints, setaddpoints] = useState([]);
  const getaddpointss = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddPoints"
    );
    if (res.status === 200) {
      setaddpoints(res.data.getadddpoints);
    }
  };

  console.log("addpoints", addpoints);
  //integrating get  method for counts
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonials = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getCounts"
      );
      if (res.status == 200) {
        setAddTestimonial(res.data.getCounts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // get method for asia
  const [AsianCo, setAsianCo] = useState([]);
  const getaddAsianC = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddAsianC"
    );
    if (res.status === 200) {
      setAsianCo(res.data.getadddAsianC);
    }
  };
  //get method for africa
  const [AfricanCo, setAfricanCo] = useState([]);
  const getaddAfricanC = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddAfricanC"
    );
    if (res.status === 200) {
      setAfricanCo(res.data.getadddAfricanC);
    }
  };
  // get method
  const [HServices, setHServices] = useState([]);
  const getaddHService = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddHService"
    );
    if (res.status === 200) {
      setHServices(res.data.getadddHService);
    }
  };
  //integrating category get method
  const [AddProductcate, setAddProductcate] = useState([]);
  const getAddProductcate = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddProductCategories"
      );
      if (res.status == 200) {
        setAddProductcate(res.data.getadddProductCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Aos.init();
    getAddGraphC();
    getAddGraphS();
    getAddServiceList();
    getAddBlog();
    getaddpointss();
    getAddTestimonials();
    getProducts();
    getAddEventsBanner();
    getaddAsianC();
    getaddAfricanC();
    getaddHService();
    getAddProductcate();
  }, []);
  // console.log(AddGraphS);
  // console.log(AddBlog);
  // console.log(addpoints);
  return (
    <div>
      <div style={{ position: "relative" }}>
        <div className="container-fluid">
          <div
            className="row"
            style={{
              backgroundColor: "to-right rgb(248, 249, 250),rgb(0, 0, 142)",
            }}
          >
            <div
              className="col-md-4 bgcolor"
              style={{
                backgroundColor: "#F8F9FA",
                height: "35rem",
                width: "17.95%",
                paddingLeft: "unset",
                paddingRight: "unset",
              }}
            ></div>
            <div
              className="col-md-8 bgcolor"
              style={{
                backgroundImage: "linear-gradient(to right, #010161 , #00008e)",
                height: "35rem",
                width: " 82.03%",
              }}
            ></div>
          </div>
        </div>

        <div
          className="container carousal"
          style={{ position: "absolute", top: "25px", left: "106px" }}
        >
          <div className="row" style={{ marginRight: "unset" }}>
            <div className="col-md-12">
              <Carousel fade>
                {AddGraphC?.map((item) => {
                  return (
                    <Carousel.Item>
                      <img
                        src={`https://kanagamultiservice.in/HomeGraph/${item?.GrImage}`}
                        alt="pic"
                        style={{
                          width: "100%",
                          height: "500px",
                          position: "relative",
                        }}
                      />

                      <div className="bs-slider"></div>
                      <Carousel.Caption
                        data-aos="fade-up"
                        data-aos-duration="3000"
                        className="carousal"
                      >
                        <div style={{ backgroundColor: "#0a58ca36" }}>
                          <h4>{item.GrText}</h4>
                          <h1
                            style={{
                              fontSize: "70px",
                              fontFamily: "'Chivo', sans-serif",
                              color: "white",
                            }}
                          >
                            {item.GrText2}
                          </h1>

                          <p
                            style={{
                              fontSize: "20px",
                              fontFamily: " Arial, Helvetica, sans-serif;",
                            }}
                          >
                            {item.GrText3}
                          </p>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{backgroundColor:"#2C0093",paddingLeft:"100px",paddingRight:"100px",paddingTop:"30px",paddingBottom:"30px"}}>
      <Carousel fade>
        {AddGraphC?.map((item) => {
          return (
            <Carousel.Item>
              <img
                src={`https://kanagamultiservice.in/HomeGraph/${item?.GrImage}`}
                alt="pic"
                style={{ width: "100%", height: "500px", position: "relative" }}
              />

              <div className="bs-slider"></div>
              <Carousel.Caption
                data-aos="fade-up"
                data-aos-duration="3000"
                className="carousal"
              >
                <div style={{ backgroundColor: "#0a58ca36" }}>
                  <h3>{item.GrText}</h3>
                  <h1
                    style={{
                      fontSize: "70px",
                      fontFamily: "'Chivo', sans-serif",
                      color: "white",
                    }}
                  >
                    {item.GrText2}
                  </h1>

                  <p
                    style={{
                      fontSize: "20px",
                      fontFamily: " Arial, Helvetica, sans-serif;",
                    }}
                  >
                    {item.GrText3}
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
      </div> */}

      <div className="container mt-5 mb-5">
        <div
          className="row text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1 style={{ color: "#2D0290" }}>Our Services And Products</h1>
          <p>WE PROMISE YOU TO PROVIDE THE BEST SERVICES</p>
        </div>
        <div className="row">
          <div className="col-md-4">
            <h5>
              <span
                style={{
                  color: "#FD5E00",
                  fontSize: "27px",
                  fontWeight: "550",
                }}
              >
                Services
              </span>{" "}
            </h5>
            <hr
              style={{
                color: "#FE5E00",
                border: "1px solid #FE5E00",
              }}
            />
            {HServices?.map((item) => {
              return (
                <p style={{ textAlign: "justify" }}>
                  {parse(`<div>${item?.HService?.slice(0, 650)}</div>`)}
                  <a
                    href="/services"
                    style={{
                      float: "right",
                      marginTop: "-20px",
                      textDecoration: "underline",
                      fontSize: "15px",
                      color: "#FE5E00",
                    }}
                  >
                    Read More...
                  </a>
                </p>
              );
            })}
          </div>
          <div className="col-md-8">
            <div
              className="container-fluid homeser"
              style={{ padding: "25px" }}
            >
              <>
                <div className="row">
                  <div
                    className="col-3 col-sm-3 col-md-3 col-lg-3"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div className="text-end">
                      <img
                        src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[0]?.PIocn}`}
                        alt=""
                        width={80}
                        height={60}
                        onClick={() => navigate("/products")}
                      />
                      <br />

                      <h6
                        id="homeservicetext"
                        onClick={() => navigate("/products")}
                      >
                        {AddProductcate[0]?.Pcat}
                      </h6>
                    </div>
                  </div>
                  <div
                    className="col-3 col-sm-3 col-md-3 col-lg-3 text-center"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[1]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[1]?.Pcat}
                    </h6>
                  </div>
                  <div
                    className="col-3 col-sm-3 col-md-3 col-lg-3 text-start"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[2]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[2]?.Pcat}
                    </h6>
                  </div>
                  <div
                    className="col-3 col-sm-3 col-md-3 col-lg-3 text-start"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[3]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[3]?.Pcat}
                    </h6>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-2 col-sm-2 col-md-2 col-lg-2 text-center"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[4]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[4]?.Pcat}
                    </h6>
                    <br />
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[5]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[5]?.Pcat}
                    </h6>
                  </div>
                  <div
                    className="col-8 col-sm-8 col-md-8 col-lg-8 "
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <img
                      // src="services.jpg"
                      src="footerr-logoo.png"
                      alt=""
                      id="img-fluid"
                      style={{
                        // borderRadius: "50%",
                        width: "73%",
                        height: "13rem",
                        marginLeft: "5rem",
                      }}
                    />
                  </div>
                  <div
                    className="col-2 col-sm-2 col-md-2 col-lg-2 text-center"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[6]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[6]?.Pcat}
                    </h6>

                    <br />
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[7]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[7]?.Pcat}
                    </h6>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-3 col-sm-3 col-md-3 col-lg-3 text-end"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[8]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[8]?.Pcat}
                    </h6>
                  </div>
                  <div
                    className="col-3 col-sm-3 col-md-3 col-lg-3 text-center"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[9]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[9]?.Pcat}
                    </h6>
                  </div>
                  <div
                    className="col-3 col-sm-3 col-md-3 col-lg-3 text-center"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[10]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[10]?.Pcat}
                    </h6>
                  </div>
                  <div
                    className="col-3 col-sm-3 col-md-3 col-lg-3 text-start"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <img
                      src={`https://kanagamultiservice.in/ProductCategories/${AddProductcate[11]?.PIocn}`}
                      alt=""
                      width={80}
                      height={60}
                      onClick={() => navigate("/products")}
                    />
                    <br />
                    <h6
                      id="homeservicetext"
                      onClick={() => navigate("/products")}
                    >
                      {AddProductcate[11]?.Pcat}
                    </h6>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <OwlCarousel className="owl-theme" loop margin={10} nav {...options}>
            {AddGraphS?.map((item, i) => {
              return (
                <div data-aos="fade-up" data-aos-duration="3000">
                  <Card
                    style={{
                      width: "22rem",
                      height: "330px",
                      borderRadius: "0px",
                    }}
                    onClick={() => navigate("./services")}
                  >
                    <Card.Img
                      style={{
                        borderRadius: "0px",
                        width: "100%",
                        height: "200px",
                      }}
                      variant="top"
                      src={`https://kanagamultiservice.in/Services/${item?.SImage}`}
                    />
                    <Card.Body>
                      <Card.Title
                        className="homeservices"
                        style={{ color: "#FE5E00" }}
                      >
                        {item?.SHeader}
                      </Card.Title>

                      {/* <Card.Text>
                        <p>
                          {" "}
                          {parse(
                            `<div>${item?.SDesription.slice(0, 200)}</div>`
                          )}
                        </p>
                        <a
                          href="/services"
                          style={{
                            textDecoration: "underline",
                            fontSize: "15px",
                            color: "#FE5E00",
                          }}
                        >
                          Read More...
                        </a>
                      </Card.Text> */}
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </OwlCarousel>
          ;
        </div>
      </div>

      {/* <div
        className="container-fluid p-5"
        style={{ backgroundColor: "#2d029038" }}
      >
        <div
          className="row text-center mb-3"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1>
            Our Main <span style={{ color: "#FE5E00" }}> Products</span>
          </h1>
          <h6>
            "We see our customers as invited guests to a party, and we are the
            hosts. It's our job to make the customer experience a satisfied
            experience."
          </h6>
        </div>

        <div className="row" data-aos="fade-up" data-aos-duration="3000">
          <OwlCarousel className="owl-theme" loop margin={10} nav {...options1}>
            {AddProductss?.map((item, i) => {
              return (
                <div className="col-md-12">
                  <div className="row ">
                    <div className="col-md-4">
                      <img
                        src={`https://kanagamultiservice.in/Products/${item?.PImage}`}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-8 p-1">
                      <h5 style={{ color: "#FE5E00" }}>{item?.PName}</h5>
                      {/* <h6>Price : {item?.PPrice}</h6>
                      <h6>Min Order Quantity : {item?.PQuantity}</h6> 
                      <h6>
                        <span style={{ fontWeight: "550" }}>
                          {" "}
                          Place Of Origin :
                        </span>{" "}
                        {item?.POrigin}
                      </h6>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: "550" }}>Description</span>{" "}
                        {parse(`<div>${item?.PDesc?.slice(0, 235)}</div>`)}{" "}
                      </p>
                    </div>
                    <div className="col-md-12">
                      <p style={{ textAlign: "justify" }}>
                        {parse(`<div>${item?.PDesc?.slice(236, 310)}</div>`)}{" "}
                      </p>
                      <div style={{ float: "right" }}>
                        <a
                          href="/products"
                          onClick={() => {
                            toggleExpansion();
                            setViewmore(!Viewmore);
                          }}
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          View All...
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
          ;
        </div>
      </div> */}

      <div className="container ">
        <div
          className="row text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1>
            Our <span style={{ color: "#FE5E00" }}>Activities/Exhibition</span>
          </h1>
        </div>
        <div className="row" data-aos="fade-up" data-aos-duration="3000">
          <Carousel fade>
            {AddEventsbanner.map((item, i) => {
              return (
                <Carousel.Item>
                  <img
                    src={`https://kanagamultiservice.in/EventBanner/${item?.EBanners}`}
                    alt="First slide"
                    style={{
                      width: "100%",
                      height: "300px",
                      position: "relative",
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
          <div
            className="row text-center"
            data-aos="fade-up"
            data-aos-duration="3000"
            style={{ marginTop: "0px", width: "99%" }}
          >
            <div className="d-flex align-items-center pb-1 justify-content-center">
              <div className="" style={{ color: "white", marginTop: "20px" }}>
                <button
                  className="  btn-danger"
                  style={{ backgroundColor: "#FE5E00" }}
                  onClick={() => navigate("/events")}
                >
                  More About Events
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        <div className="row" data-aos="fade-up" data-aos-duration="3000">
          <div className="col-md-8">
            {AddBlog?.map((item) => {
              return (
                <div className="row">
                  <>
                    <div className="col-md-12">
                      <h4 style={{ color: "#2D0290" }}>
                        <strong>WHY CHOOSE US?</strong>
                      </h4>
                      <hr
                        style={{
                          color: "#FE5E00",
                          border: "1px solid #FE5E00",
                        }}
                      />
                    </div>
                    <div className="col-md-6 p-3">
                      <img
                        src={`https://kanagamultiservice.in/WhyChooseUs/${item?.AbImg}`}
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-6 p-3">
                      {/* <h4 style={{ color: "#2D0290" }}>
                        <strong>WHY CHOOSE US?</strong>
                      </h4>
                      <hr
                        style={{
                          color: "#FE5E00",
                          border: "1px solid #FE5E00",
                        }}
                      /> */}
                      <p style={{ textAlign: "justify" }}>
                        {parse(`<div>${item?.AbDesc?.slice(0, 350)}</div>`)}
                      </p>
                    </div>
                    <div className="col-md-12">
                      <p style={{ textAlign: "justify", marginTop: "-1rem" }}>
                        {parse(`<div>${item?.AbDesc?.slice(351)}</div>`)}
                        {/* <a
                          href="/aboutus"
                          style={{
                            float: "right",
                            marginTop: "-20px",
                            textDecoration: "underline",
                            fontSize: "15px",
                            color: "#FE5E00",
                          }}
                        >
                          Read More...
                        </a> */}
                      </p>
                    </div>
                  </>
                </div>
              );
            })}
          </div>

          <div className="col-md-4">
            <div className="wrapper">
              <div className="center-line"></div>
              {addpoints?.map((item) => {
                return (
                  <div className="row row-1">
                    <section>
                      <p className="icon">
                        <img
                          src={`https://kanagamultiservice.in/WhyAdditionalPoints/${item?.AddImg}`}
                          alt=""
                          style={{ width: "20px", height: "20px" }}
                        />
                      </p>
                      <div className="details">
                        <span className="tittle">
                          {" "}
                          <h2> {item?.Text}</h2>
                        </span>
                      </div>
                    </section>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid  mb-5"
        style={{ backgroundColor: "#C4C4C4" }}
      >
        <div
          className="row pt-5 text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1>Countries We Have Worked With</h1>
          <p>"Think Globally, Import Extensively"</p>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 globe"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img src="earth.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="row">
                <div
                  className="col-md-4"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <Card className="asia" style={{ width: "17rem" }}>
                    <Card.Header
                      style={{
                        backgroundColor: "#2D0290",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Asia
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div className="row">
                          {AsianCo?.map((item) => {
                            return (
                              <>
                                <div className="col-md-6 ">
                                  <h6 className="d-flex gap-2">
                                    {" "}
                                    <img
                                      src={`https://kanagamultiservice.in/AsianCountry/${item?.AFlag}`}
                                      alt=""
                                      style={{ width: "20px", height: "16px" }}
                                    />
                                    {item?.AsianC}
                                  </h6>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <Card
                    className="africa"
                    style={{ width: "37rem", marginLeft: "58px" }}
                  >
                    <Card.Header
                      style={{
                        backgroundColor: "#2D0290",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Africa
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div className="row">
                          {AfricanCo?.map((item) => {
                            return (
                              <>
                                <div className="col-md-4">
                                  <h6 className="d-flex gap-2">
                                    {" "}
                                    <img
                                      src={`https://kanagamultiservice.in/AfricanCountry/${item?.AfFlag}`}
                                      alt=""
                                      style={{ width: "20px", height: "16px" }}
                                    />
                                    {item?.AfricanC}
                                  </h6>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Process />

      <div
        className="container mt-5 mb-2 countssss"
        style={{
          backgroundImage: 'url("../countsback.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "200px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-sliderC"></div>

        <div className="row text-center">
          <h4 style={{ color: "white", marginTop: "10px" }}>
            We Have Great Achievements To Do...
          </h4>
        </div>
        <div className="row perfect">
          {AddTestimonial?.map((item) => {
            return (
              <>
                <div
                  className="col-md-3  exports"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div style={{ display: "flex", paddingLeft: "10px" }}>
                    <ScrollTrigger
                      onEnter={() => setCounterOn(true)}
                      onExit={() => setCounterOn(false)}
                    >
                      <h1
                        style={{
                          color: "#FE5E00",
                          fontSize: "40px",
                          borderBottom: "3px solid #FE5E00",
                        }}
                      >
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={item.Counts}
                            duration={2}
                            delay={0}
                          />
                        )}
                        {/* {item?.Counts} */}
                      </h1>
                    </ScrollTrigger>
                  </div>
                  <div>
                    <h5
                      style={{
                        color: "white",
                        paddingBottom: "10px",
                        paddingLeft: "10px",
                        marginTop: "-8px",
                      }}
                    >
                      {item?.Text}
                    </h5>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      {/* <div
        className="container mt-5 mb-2 countssss"
        style={{
          backgroundImage: 'url("../we-provide-bg.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "250px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-slider3"></div>
        {AddTestimonial?.map((item) => {
          return (
            <div className="row perfect">
              <div
                className="col-md-3  exports"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="row">
                  <div className="col-md-2 smallimages">
                    <AiFillPushpin
                      style={{
                        color: "#FE5E00",
                        fontSize: "35px",
                        left: "10px",
                      }}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div style={{ display: "flex", paddingLeft: "10px" }}>
                  <h1 style={{ color: "#FE5E00", fontSize: "40px" }}>
                    {item?.Counts}
                  </h1>
                  <h5 style={{ color: "black", padding: "10px" }}>
                    {item?.Text}
                  </h5>
                  <br />
                  <br />
                </div>
              </div>
              <div
                className="col-md-3  exports"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="row">
                  <div className="col-md-2 smallimages">
                    <AiFillPushpin
                      style={{
                        color: "#FE5E00",
                        fontSize: "35px",
                        left: "10px",
                      }}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div style={{ display: "flex", paddingLeft: "10px" }}>
                  <h1 style={{ color: "red", fontSize: "40px" }}>
                    {item?.Counts1}
                  </h1>
                  <h5 style={{ color: "black", padding: "10px" }}>
                    {item?.Text1}
                  </h5>
                  <br />
                  <br />
                </div>
              </div>
              <div
                className="col-md-3  exports"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="row">
                  <div className="col-md-2 smallimages">
                    <AiFillPushpin
                      style={{
                        color: "#FE5E00",
                        fontSize: "35px",
                        left: "10px",
                      }}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div style={{ display: "flex", paddingLeft: "10px" }}>
                  <h1 style={{ color: "red", fontSize: "40px" }}>
                    {item?.Counts2}
                  </h1>
                  <h5 style={{ color: "black", padding: "10px" }}>
                    {item?.Text2}
                  </h5>
                  <br />
                  <br />
                </div>
              </div>
              <div
                className="col-md-3  exports"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="row">
                  <div className="col-md-2 smallimages">
                    <AiFillPushpin
                      style={{
                        color: "#FE5E00",
                        fontSize: "35px",
                        left: "10px",
                      }}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div style={{ display: "flex", paddingLeft: "10px" }}>
                  <h1 style={{ color: "red", fontSize: "40px" }}>
                    {item?.Counts3}
                  </h1>
                  <h5 style={{ color: "black", padding: "10px" }}>
                    {item?.Text3}
                  </h5>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default Home;
