import React, { useEffect, useState } from "react";
import { Button, Table, Image, Pagination, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiTwotoneDelete } from "react-icons/ai";

const AdminEnquiry = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const [EFName, setEFName] = useState("");
  const [ELName, setELName] = useState("");
  const [EPhone, setEPhone] = useState("");
  const [EEmail, setEEmail] = useState("");
  const [EComName, setEComName] = useState("");
  const [ELoc, setELoc] = useState("");
  const [ERequire, setERequire] = useState("");

  const Enquiryf = async () => {
    if(!EFName) return alert("Please Enter Your First Name");
    if(!ELName) return alert("Please Enter Your Last Name");
    if(!EPhone) return alert("Please Enter Your Phone no");
    if(!EEmail) return alert("Please Enter Your Email id");
    if(!EComName) return alert("Please Enter Your Company Name");
    if(!ELoc) return alert("Please Enter Your Location");
    if(!ERequire) return alert("Please Enter Your Requirement");
    try {
      const config = {
        url: "/user/enquiry",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          EFName: EFName,
          ELName: ELName,
          EPhone: EPhone,
          EEmail: EEmail,
          EComName: EComName,
          ELoc: ELoc,
          ERequire: ERequire,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAddEnquiry();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [AddEnquiry, setAddEnquiry] = useState([]);
  const getAddEnquiry = async () => {
    try {
      let res = await axios.get("https://kanagamultiservice.in/api/user/getallenquiry");
      if (res.status === 200) {
        setAddEnquiry(res.data.getenquirynow);
        setnochangedataH(res.data.getenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteEnquiry = async () => {
    try {
      const config = {
        url: "user/deleteenquiry/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddEnquiry();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  useEffect(() => {
    getAddEnquiry();
  }, []);
  console.log(AddEnquiry);

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 10;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = AddEnquiry.slice(
    visitedPage,
    visitedPage + productPerPage
  );
  const pageCount = Math.ceil(AddEnquiry.length / productPerPage);

  //search filter for enquiry request
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAddEnquiry([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAddEnquiry([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = AddEnquiry.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.EFName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  return (
    <>
      <div className="container">
        <div className="mo-gra mt-3 ms-5">
          <div className="row">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilterH}
                />
              </Form>
            </div>
          
            <div className="d-flex justify-content-between ">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
             Enquiries
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                  setEFName("");
                  setELName("");
                  setEPhone("");
                  setEEmail("");
                  setEComName("");
                  setELoc("");
                  setERequire("");
                  handleShow()}}
                  >Add Enquiry</button>
                  </div>
                </div>
          </div>

          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Company Name</th>
                <th>Location</th>
                <th>Requirement</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {displayPage.map((item, i) => {
                const serialNumber = i + 1 + visitedPage;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EFName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.ELName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EEmail}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EPhone}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EComName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.ELoc}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.ERequire}</td>

                    <td>
                      <button className="btn btn-danger">
                        <AiTwotoneDelete
                          style={{
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            handleShow2(item?._id);
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination style={{ float: "right" }}>
            <Pagination.First onClick={() => setPageNumber(0)} />
            <Pagination.Prev
              onClick={() => setPageNumber((prev) => Math.max(prev - 1, 0))}
            />
            {Array.from({ length: pageCount }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index === pageNumber}
                onClick={() => setPageNumber(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
              }
            />
            <Pagination.Last onClick={() => setPageNumber(pageCount - 1)} />
          </Pagination>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>First Name</label>
            <input
              type="text"
              placeholder="Enter First Name"
              className="vi_0"
              onChange={(e) => setEFName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Enter Last Name"
              className="vi_0"
              onChange={(e) => setELName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="vi_0"
              onChange={(e) => setEEmail(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Phone No.</label>
            <input
              type="number"
              placeholder="Enter Phone No."
              className="vi_0"
              onChange={(e) => setEPhone(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Company Name</label>
            <input
              type="text"
              placeholder="Enter Company Name"
              className="vi_0"
              onChange={(e) => setEComName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="vi_0"
              onChange={(e) => setELoc(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Requirement</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="3"
              placeholder="Enter Requirement"
              className="vi_0"
              onChange={(e) => {
                setERequire(e.target.value);
              }}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={Enquiryf}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={DeleteEnquiry}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminEnquiry;
