import React, { useEffect, useState } from "react";
import { Button, Form, Pagination, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiTwotoneDelete } from "react-icons/ai";
import { useLocation } from "react-router-dom";

const AdminEventsEnquiry = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const [EEName, setEEName] = useState("");
  const [EEmail, setEEmail] = useState("");
  const [EPhone, setEPhone] = useState("");
  const [EComName, setEComName] = useState("");
  const [ELoc, setELoc] = useState("");
  const [EAltPhone, setEAltPhone] = useState("");
  const [ERequire, setERequire] = useState("");
  const [item,setItem] = useState("");

  const EnquiryE = async () => {
    if(!EEName) return alert("Please Enter Your  Name");
      if(!EEmail) return alert("Please Enter Your Email id");
      if(!EPhone) return alert("Please Enter Your Phone no");
      if(!EComName) return alert("Please Enter  Company ");
      if(!ELoc) return alert("Please Enter the Location");
      if(!EAltPhone) return alert("Please Enter the Alternate Phone no");
      if(!ERequire) return alert("Please Enter Your Requirement");
    try {
      const config = {
        url: "/user/Eenquiry",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "application/json" },
        data: {
          EventName: item.EName,
          EEName: EEName,
          EEmail: EEmail,
          EPhone: EPhone,
          EComName: EComName,
          ELoc: ELoc,
          EAltPhone: EAltPhone,
          ERequire: ERequire,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // const EnquiryE = async () => {
  //   const location = useLocation();
  //   const { item } = location.state;
  
  // console.log("item", item);
  //   try {
  //     const config = {
  //       url: "/user/Eenquiry",
  //       method: "post",
  //       baseURL: "https://kanagamultiservice.in/api",
  //       headers: { "content-type": "application/json" },
  //       data: {
  //         EventName: item.EName,
  //         EName: EName,
  //         EEmail: EEmail,
  //         EPhone: EPhone,
  //         EComName: EComName,
  //         ELoc: ELoc,
  //         EAltPhone: EAltPhone,
  //         ERequire: ERequire,
  //       },
  //     };
  //     await axios(config).then((res) => {
  //       if (res.status === 200) {
  //         alert(res.data.success);
  //         getAddEEnquiry();
  //         handleClose();
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     alert(error.response.data.msg);
  //   }
  // };
  //integrating get  method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [AddEEnquiry, setAddEEnquiry] = useState([]);
  const getAddEEnquiry = async () => {
    try {
      let res = await axios.get("https://kanagamultiservice.in/api/user/getallEenquiry");
      if (res.status === 200) {
        setAddEEnquiry(res.data.getEenquirynow);
        setnochangedataH(res.data.getEenquirynow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteEEnquiry = async () => {
    try {
      const config = {
        url: "user/deleteEenquiry/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddEEnquiry();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
 

  useEffect(() => {
    getAddEEnquiry();
  }, []);
  console.log(AddEEnquiry);
  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 10;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = AddEEnquiry.slice(visitedPage, visitedPage + productPerPage);
  const pageCount = Math.ceil(AddEEnquiry.length / productPerPage);

   //search filter for event request
   const [searchH, setSearchH] = useState("");
   const handleFilterH = (e) => {
     if (e.target.value != "") {
       setSearchH(e.target.value);
       const filterTableH = nochangedataH.filter((o) =>
         Object.keys(o).some((k) =>
           String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
         )
       );
       setAddEEnquiry([...filterTableH]);
     } else {
       setSearchH(e.target.value);
       setAddEEnquiry([...nochangedataH]);
     }
   };
   const [searchTermH, setSearchTermH] = useState("");
   const searchedProductH = AddEEnquiry.filter((item) => {
     if (searchTermH.value === "") {
       return item;
     }
     if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
       return item;
     } else {
       return console.log("not found");
     }
   });
  return (
    <>
     
      <div className="container">
        <div className="mo-gra mt-3 ms-5">
        <div className="row">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilterH}
                />
              </Form>
            </div>
            <div className="col-md-4">
            <div className="add-b text-end  mb-4 col-md-12">
        
        </div>
            </div>
            <div className="d-flex justify-content-between ">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
            Event Enquiry
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
          setEEName("");
          setEEmail("");
          setEPhone("");
          setEComName("");
          setELoc("");
          setEAltPhone("");
          setERequire("");
          handleShow()}}>Add Event Enquiry</button>
                  </div>
                </div>
          </div>
        
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Event Name</th>
                <th>First Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Alternative no</th>
                <th>Company Name</th>
                <th>Location</th>
                <th>Requirement</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {displayPage.map((item, i) => {
                const serialNumber = i + 1 + visitedPage;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{item?.EventName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EEName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EEmail}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EPhone}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EAltPhone}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.EComName}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.ELoc}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.ERequire}</td>

                    <td>
                      <button className="btn btn-danger">
                        <AiTwotoneDelete
                          style={{
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            handleShow2(item?._id);
                          }}
                        />
                      </button>
                     
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination style={{ float: "right" }}>
                <Pagination.First onClick={() => setPageNumber(0)} />
                <Pagination.Prev
                  onClick={() => setPageNumber((prev) => Math.max(prev - 1, 0))}
                />
                {Array.from({ length: pageCount }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === pageNumber}
                    onClick={() => setPageNumber(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                  }
                />
                <Pagination.Last onClick={() => setPageNumber(pageCount - 1)} />
              </Pagination>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Event Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter Name"
              className="vi_0"
              onChange={(e) => setEEName(e.target.value)}
            />
          </div>
        
          <div className="do-sear mt-2">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="vi_0"
              onChange={(e) => setEEmail(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Phone No.</label>
            <input
              type="number"
              placeholder="Enter Phone No."
              className="vi_0"
              onChange={(e) => setEPhone(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Alternative No.</label>
            <input
              type="number"
              placeholder="Enter Phone No."
              className="vi_0"
              onChange={(e) => setEAltPhone(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Company Name</label>
            <input
              type="text"
              placeholder="Enter Company Name"
              className="vi_0"
              onChange={(e) => setEComName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Location Name</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="vi_0"
              onChange={(e) => setELoc(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Requirment</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="3"
              placeholder="Enter Requirement"
              className="vi_0"
              onChange={(e) => {
                setERequire(e.target.value);
              }}
            ></textarea>
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={EnquiryE}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={DeleteEEnquiry}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminEventsEnquiry;
