import React from "react";
import SideBar from "./SideBar";
import AdminHeader from "./AdminHeader";

function Dashboard(props) {
  return (
    <div className="dash">
      <div className="row me-0">
        <div className="col-md-2">
          <div className="left-side">
            <SideBar />
          </div>
        </div>

        <div className="col-md-10 ps-4 right-h">
          <AdminHeader />
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
