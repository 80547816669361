import axios from "axios";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

const OurClients = () => {
  // get method for service
  const [AddSPartnerss, setAddSPartnerss] = useState([]);
  const getaddSPartnerss = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddSPartnerss"
    );
    if (res.status === 200) {
      setAddSPartnerss(res.data.getadddSPartners);
    }
  };
  // get method for Products
  const [AddPPartnerss, setAddPPartnerss] = useState([]);
  const getaddPPartnerss = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getaddPPartnerss"
    );
    if (res.status === 200) {
     setAddPPartnerss(res.data.getadddPPartners);
    }
  };
   // get method for events
   const [AddEPartnerss, setAddEPartnerss] = useState([]);
   const getaddEPartnerss = async () => {
     let res = await axios.get(
       "https://kanagamultiservice.in/api/admin/getaddEPartnerss"
     );
     if (res.status === 200) {
       setAddEPartnerss(res.data.getadddEPartners);
     }
   };
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  useEffect(() => {
    getaddSPartnerss();
    getaddPPartnerss();
    getaddEPartnerss();
  }, [])
  
  return (
    <div>
      <div
        className="container-fluid mt-5"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div className="row pt-3 text-center">
          <h1>Our Partners</h1>
          <h6>We Have Trusted Clients</h6>
        </div>
        <br />
        <div className="row">
          <OwlCarousel className="owl-theme" loop margin={10} nav {...options}>
           {AddSPartnerss?.map((item)=>{
            return(
              <div class="item">
              <img src={`https://kanagamultiservice.in/OurServicePartners/${item?.PImgS}`} alt="" />
            </div>
            )
           })}
           {AddPPartnerss?.map((item)=>{
            return(
              <div class="item">
              <img src={`https://kanagamultiservice.in/OurProductPartners/${item?.PImgP}`} alt="" />
            </div>
            )
           })}
           {AddEPartnerss?.map((item)=>{
            return(
              <div class="item">
              <img src={`https://kanagamultiservice.in/OurEventsPartners/${item?.EImg}`} alt="" />
            </div>
            )
           })}
           
          </OwlCarousel>
          
        </div>
      </div>
    </div>
  );
};

export default OurClients;
