import { WhatsApp } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const AdminSocialmedias = () => {
  const [whatsapp, setwhatsapp] = useState(true);
  const [Linkedin, setLinked] = useState(false);
  const [Instagram, setInstagram] = useState(false);
  const [Facebook, setFacebook] = useState(false);
  const [Twitter, setTwitter] = useState(false);

  const [show10, setShow10] = useState(false);

  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);
  //===================WhatsApp=====================
  // post method for experience
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [WSocial, setWSocial] = useState("");

  const AddWSocial = async () => {
    try {
      const config = {
        url: "/admin/addWSocial",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          WSocial: WSocial,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getaddWSocial();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [addWSocial, setaddWSocial] = useState([]);
  const getaddWSocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddWSocial");
    if (res.status === 200) {
      setaddWSocial(res.data.getaddWSocial);
    }
  };

  //delete method
  const [show1, setShow1] = useState(false);
  const [Data, setData] = useState("");

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData(item);
  };
  const deleteadddWSocial = async () => {
    try {
      const config = {
        url: "/admin/deleteaddWSocial/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddWSocial();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //================LinkedIn=================
  // post method for experience
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [LSocial, setLSocial] = useState("");

  const AddLSocial = async () => {
    try {
      const config = {
        url: "/admin/addLSocial",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          LSocial: LSocial,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose2();
        getaddLSocial();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [addLSocial, setaddLSocial] = useState([]);
  const getaddLSocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddLSocial");
    if (res.status === 200) {
      setaddLSocial(res.data.getaddLSocial);
    }
  };

  //delete method
  const [show3, setShow3] = useState(false);
  const [Data1, setData1] = useState("");

  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
  };
  const deleteadddLSocial = async () => {
    try {
      const config = {
        url: "/admin/deleteaddLSocial/" + Data1,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddLSocial();
          handleClose3();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //========InstGram=============
  // post method for experience
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [ISocial, setISocial] = useState("");

  const AddISocial = async () => {
    try {
      const config = {
        url: "/admin/addISocial",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          ISocial: ISocial,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose4();
        getaddISocial();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [addISocial, setaddISocial] = useState([]);
  const getaddISocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddISocial");
    if (res.status === 200) {
      setaddISocial(res.data.getaddISocial);
    }
  };

  //delete method
  const [show5, setShow5] = useState(false);
  const [Data2, setData2] = useState("");

  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setData2(item);
  };
  const deleteadddISocial = async () => {
    try {
      const config = {
        url: "/admin/deleteaddISocial/" + Data2,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getaddISocial();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //========Facebook=============
  // post method for experience
  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const [FSocial, setFSocial] = useState("");

  const AddFSocial = async () => {
    try {
      const config = {
        url: "/admin/addFSocial",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          FSocial: FSocial,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose6();
        getaddFSocial();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [addFSocial, setaddFSocial] = useState([]);
  const getaddFSocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddFSocial");
    if (res.status === 200) {
      setaddFSocial(res.data.getaddFSocial);
    }
  };

  //delete method
  const [show7, setShow7] = useState(false);
  const [Data3, setData3] = useState("");

  const handleClose7 = () => setShow7(false);
  const handleShow7 = (item) => {
    setShow7(true);
    setData3(item);
  };
  const deleteadddFSocial = async () => {
    try {
      const config = {
        url: "/admin/deleteaddFSocial/" + Data3,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddFSocial();
          handleClose7();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //=========Twitter==========
  // post method for experience
  const [show8, setShow8] = useState(false);

  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  const [TSocial, setTSocial] = useState("");

  const AddTSocial = async () => {
    try {
      const config = {
        url: "/admin/addTSocial",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          TSocial: TSocial,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose8();
        getaddTSocial();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [addTSocial, setaddTSocial] = useState([]);
  const getaddTSocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddTSocial");
    if (res.status === 200) {
      setaddTSocial(res.data.getaddTSocial);
    }
  };

  //delete method
  const [show9, setShow9] = useState(false);
  const [Data4, setData4] = useState("");

  const handleClose9 = () => setShow9(false);
  const handleShow9 = (item) => {
    setShow9(true);
    setData4(item);
  };
  const deleteadddTSocial = async () => {
    try {
      const config = {
        url: "/admin/deleteaddTSocial/" + Data4,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getaddTSocial();
          handleClose9();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  useEffect(() => {
    getaddWSocial();
    getaddLSocial();
    getaddISocial();
    getaddFSocial();
    getaddTSocial();
  }, []);

  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    console.log("ddkd1", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("ddkd2", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("ddkd3", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("ddkd4", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // const input = document.getElementById("pdf");
    // const options = { scrollY: -window.scrollY, useCORS: true };
    // const canvas = await html2canvas(input, options);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    pdf.save("QR_Code.pdf");
  };
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="ad-b mt-4 mb-4 col-md-12">
              <button
                onClick={() => {
                  setwhatsapp(true);
                  setLinked(false);
                  setInstagram(false);
                  setFacebook(false);
                  setTwitter(false);
                }}
              >
                WhatsApp
              </button>
              <button
                onClick={() => {
                  setwhatsapp(false);
                  setLinked(true);
                  setInstagram(false);
                  setFacebook(false);
                  setTwitter(false);
                }}
              >
                Linked In
              </button>
              <button
                onClick={() => {
                  setwhatsapp(false);
                  setLinked(false);
                  setInstagram(true);
                  setFacebook(false);
                  setTwitter(false);
                }}
              >
                Instagram
              </button>
              <button
                onClick={() => {
                  setwhatsapp(false);
                  setLinked(false);
                  setInstagram(false);
                  setFacebook(true);
                  setTwitter(false);
                }}
              >
                Facebook
              </button>
              <button
                onClick={() => {
                  setwhatsapp(false);
                  setLinked(false);
                  setInstagram(false);
                  setFacebook(false);
                  setTwitter(true);
                }}
              >
                Twitter
              </button>
              <button
                onClick={() => {
                  handleShow10();
                }}
              >
                Download QR_Code
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra ms-5 mt-2">
          {whatsapp ? (
            <>
             
              <div className="d-flex justify-content-between mt-2">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                     Whatsapp
                  </h2>
                  <div className="add-b">
                  {addWSocial?.length !== 0 ? (
                  ""
                ) : (
                  <>
                    <button>
                      <a
                        href="https://create.aisensy.com/generate-whatsapp-link"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        Generate Link
                      </a>
                    </button>

                    <button onClick={handleShow}>Add WhatsApp Link</button>
                  </>
                )}
                  </div>
                </div>
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>WhatsApp Link</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {addWSocial.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td style={{ paddingTop: "20px" }}>{item?.WSocial}</td>

                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleShow1(item?._id);
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              {Linkedin ? (
                <>
                 
                  <div className="d-flex justify-content-between mt-2">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                     Linked In
                  </h2>
                  <div className="add-b">
                  {addLSocial?.length !== 0 ? (
                      ""
                    ) : (
                      <>
                        <button onClick={handleShow2}>Add LinkedIn Link</button>
                      </>
                    )}
                  </div>
                </div>
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>LinkedIn Link</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addLSocial.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td style={{ paddingTop: "20px" }}>
                              {item?.LSocial}
                            </td>

                            <td>
                              <button className="btn btn-danger">
                                <AiTwotoneDelete
                                  style={{
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    handleShow3(item?._id);
                                  }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <>
                  {Instagram ? (
                    <>
                      
                      <div className="d-flex justify-content-between mt-2">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                     Instagram
                  </h2>
                  <div className="add-b">
                  {addISocial?.length !== 0 ? (
                          ""
                        ) : (
                          <>
                            <button onClick={handleShow4}>
                              Add Instagram Link
                            </button>
                          </>
                        )}
                  </div>
                </div>
                      <Table
                        striped
                        bordered
                        hover
                        style={{ textAlign: "center" }}
                      >
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Instgram Link</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addISocial.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td style={{ paddingTop: "20px" }}>
                                  {item?.ISocial}
                                </td>

                                <td>
                                  <button className="btn btn-danger">
                                    <AiTwotoneDelete
                                      style={{
                                        fontSize: "20px",
                                      }}
                                      onClick={() => {
                                        handleShow5(item?._id);
                                      }}
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <>
                      {Facebook ? (
                        <>
                          
                          <div className="d-flex justify-content-between mt-2">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                     Facebook
                  </h2>
                  <div className="add-b">
                  {addFSocial?.length !== 0 ? (
                              ""
                            ) : (
                              <>
                                <button onClick={handleShow6}>
                                  Add Facebook Link
                                </button>
                              </>
                            )}
                  </div>
                </div>
                          <Table
                            striped
                            bordered
                            hover
                            style={{ textAlign: "center" }}
                          >
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Facebook Link</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {addFSocial.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td style={{ paddingTop: "20px" }}>
                                      {item?.FSocial}
                                    </td>

                                    <td>
                                      <button className="btn btn-danger">
                                        <AiTwotoneDelete
                                          style={{
                                            fontSize: "20px",
                                          }}
                                          onClick={() => {
                                            handleShow7(item?._id);
                                          }}
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <>
                          {Twitter ? (
                            <>
                              <div className="add-b text-end mb-3  col-md-12">
                                
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                     Twitter
                  </h2>
                  <div className="add-b">
                  {addTSocial?.length !== 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <button onClick={handleShow8}>
                                      Add Twitter Link
                                    </button>
                                  </>
                                )}
                  </div>
                </div>
                              <Table
                                striped
                                bordered
                                hover
                                style={{ textAlign: "center" }}
                              >
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Twitter Link</th>
                                    <th>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {addTSocial.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td style={{ paddingTop: "20px" }}>
                                          {item?.TSocial}
                                        </td>

                                        <td>
                                          <button className="btn btn-danger">
                                            <AiTwotoneDelete
                                              style={{
                                                fontSize: "20px",
                                              }}
                                              onClick={() => {
                                                handleShow9(item?._id);
                                              }}
                                            />
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Whatsapp Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Whatsapp Link</label>
            <input
              type="text"
              placeholder="Paste the generated link"
              className="vi_0"
              onChange={(e) => setWSocial(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={AddWSocial}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add LinkedIn Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>LinkedIn Link</label>
            <input
              type="text"
              placeholder="Link..."
              className="vi_0"
              onChange={(e) => setLSocial(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={AddLSocial}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Instagram Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Instagram Link</label>
            <input
              type="text"
              placeholder="Link..."
              className="vi_0"
              onChange={(e) => setISocial(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={AddISocial}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show6}
        onHide={handleClose6}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Facebook Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Facebook Link</label>
            <input
              type="text"
              placeholder="Link..."
              className="vi_0"
              onChange={(e) => setFSocial(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={AddFSocial}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show8}
        onHide={handleClose8}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Twitter Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Twitter Link</label>
            <input
              type="text"
              placeholder="Link..."
              className="vi_0"
              onChange={(e) => setTSocial(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose8}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={AddTSocial}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddWSocial}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddLSocial}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show5}
        onHide={handleClose5}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddISocial}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show7}
        onHide={handleClose7}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose7}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddFSocial}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show9}
        onHide={handleClose9}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose9}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddTSocial}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show10}
        onHide={handleClose10}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Website QR_Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          <div className="text-center" id="pdf">
            <img
              src="QR_code.png"
              alt=""
              style={{
                width: "70%",
                height: "250px",
                marginTop: "10px",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="ad btn btn-outline mt-1"
            style={{ border: "1px solid #FE5E00", color: "#FE5E00" }}
            onClick={createPDF}
          >
            Download QR_Code
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminSocialmedias;
