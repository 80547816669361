import React, { useEffect, useState } from "react";
import { Button, Form, Table, Image, Pagination } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { BsSearch } from "react-icons/bs";

const AdminServices = () => {
  const [service, setService] = useState(true);
  const [SerList, setSerList] = useState(false);
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setSDesription(data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  //services
  // integrating post method
  const formdata = new FormData();
  const [SImage, setSImage] = useState("");
  const [SCat, setSCat] = useState("");
  const [SHeader, setSHeader] = useState("");
  const [SDesription, setSDesription] = useState("");

  const AddGrapph = async () => {
    formdata.append("SImage", SImage);
    formdata.append("SCat", SCat);
    formdata.append("SHeader", SHeader);
    formdata.append("SDesription", SDesription);

    try {
      if(!SImage) return alert("Please Select the Image");
      if(!SCat) return alert("Please Select the Category");
      if(!SHeader) return alert("Please Enter the Hedaer");
      if(!SDesription) return alert("Please Enter the Description");
      const config = {
        url: "/Admin/Service",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddGraph();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [AddGraph, setAddGraph] = useState([]);
  const getAddGraph = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/Admin/getallService"
      );
      if (res.status === 200) {
        setAddGraph(res.data.getAddService);
        setnochangedataH(res.data.getAddService);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const Deletegallery = async () => {
    try {
      const config = {
        url: "Admin/DeleteAddService/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddGraph();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setSHeader(item?.SHeader);
    setSCat(item?.SCat);
    setSDesription(item?.SDesription);
  };

  const editgallery = async (e) => {
    e.preventDefault();
    formdata.append("SImage", SImage);
    formdata.append("SCat", SCat);
    formdata.append("SHeader", SHeader);
    formdata.append("SDesription", SDesription);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "/Admin/editAddService",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddGraph();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };

  //service List
  const formdata1 = new FormData();
  const [SIcon, setSIcon] = useState("");
  const [Scat, setScat] = useState("");
  const AddServiceList = async () => {
    formdata1.set("SIcon", SIcon);
    formdata1.set("Scat", Scat);
    try {
      if(!SIcon) return alert("Please Select the Icon");
      if(!Scat) return alert ("Please Enter the Service Type");

      const config = {
        url: "/Admin/addServiceList",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose4();
          getAddServiceList();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating category get method
  const [nochangedata, setnochangedata] = useState([]);
  const [AddServiceLists, setAddServiceList] = useState([]);
  const getAddServiceList = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/Admin/getaddServiceList"
      );
      if (res.status == 200) {
        setAddServiceList(res.data.getadddServiceList);
        setnochangedata(res.data.getadddServiceList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating categories delete method
  const [Data2, setData2] = useState("");
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = (item) => {
    setShow11(true);
    setData2(item);
  };
  const deleteServiceList = async () => {
    try {
      const config = {
        url: "Admin/deleteaddServiceList/" + Data2,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        headers: { "content-type": "multipart/form-data" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getAddServiceList();
          handleClose11();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating categories update method
  const [Aexp1, setAexp1] = useState("");
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = (item) => {
    setShow6(true);
    setAexp1(item);
    setScat(item?.Scat);
  };
  const editServiceList = async (e) => {
    e.preventDefault();

    formdata1.set("SIcon", SIcon);
    formdata1.set("Scat", Scat);
    formdata1.set("id", Aexp1?._id);
    try {
      const config = {
        url: "/Admin/updateServiceList",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Updated");
          getAddServiceList();
          handleClose6();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAddGraph();
    getAddServiceList();
  }, []);
  console.log(AddGraph);

  const [Viewmore, setViewmore] = useState(true);
  const [SelectedId, setSelectedId] = useState();

  //   Row Filter
  const [itempage, setItempage] = useState(5);

  //   DateRange Filter
  const [datap, setDatap] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const filterData = () => {
    if (!startDate) return alert("Please select from date");
    if (!endDate) return alert("Please select to date");
    const filteredData = datap.filter((item) => {
      const itemDate = new Date(item?.createdAt);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      return itemDate >= startDateObj && itemDate <= endDateObj;
    });
    setDatap([...filteredData]);
  };
  // Pagination for service
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = AddGraph.slice(visitedPage, visitedPage + productPerPage);
  const pageCount = Math.ceil(AddGraph.length / productPerPage);

  // Pagination for service list
  const [pageNumberP, setPageNumberP] = useState(0);
  const productPerPageP = 5;
  const visitedPageP = pageNumberP * productPerPageP;
  const displayPageP = AddServiceLists.slice(visitedPageP, visitedPageP + productPerPageP);
  const pageCountP = Math.ceil(AddServiceLists.length / productPerPageP);


 //search filter for service category
 const [search, setSearch] = useState("");
 const handleFilter = (e) => {
   if (e.target.value != "") {
     setSearch(e.target.value);
     const filterTable = nochangedata.filter((o) =>
       Object.keys(o).some((k) =>
         String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
       )
     );
     setAddServiceList([...filterTable]);
   } else {
     setSearch(e.target.value);
     setAddServiceList([...nochangedata]);
   }
 };
 const [searchTerm, setSearchTerm] = useState("");
 const searchedProduct = AddServiceLists.filter((item) => {
   if (searchTerm.value === "") {
     return item;
   }
   if (item?.ConAddress?.toLowerCase().includes(searchTerm?.toLowerCase())) {
     return item;
   } else {
     return console.log("not found");
   }
 });

 //search filter for services
 const [searchH, setSearchH] = useState("");
 const handleFilterH = (e) => {
   if (e.target.value != "") {
     setSearchH(e.target.value);
     const filterTableH = nochangedataH.filter((o) =>
       Object.keys(o).some((k) =>
         String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
       )
     );
     setAddGraph([...filterTableH]);
   } else {
     setSearchH(e.target.value);
     setAddGraph([...nochangedataH]);
   }
 };
 const [searchTermH, setSearchTermH] = useState("");
 const searchedProductH = AddGraph.filter((item) => {
   if (searchTermH.value === "") {
     return item;
   }
   if (item?.ConAddress?.toLowerCase().includes(searchTermH?.toLowerCase())) {
     return item;
   } else {
     return console.log("not found");
   }
 });
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="ad-b mt-4 mb-3 col-md-12">
              <button
                onClick={() => {
                  setService(true);
                  setSerList(false);
                }}
              >
                Services
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setService(false);
                  setSerList(true);
                }}
              >
                Service Lists
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="mo-gra ps-5">
          {service ? (
            <>
              {" "}
              <div className="container">
                <div className="row mb-1">
                  <div className="col-8 col-sm-8 col-md-8 col-lg-8" style={{paddingLeft:"unset"}}>
                    <Form inline>
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className=" mr-sm-2"
                        onChange={handleFilterH}
                      />
                    </Form>
                  </div>
                 
                  <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                Services
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                  setSImage("");
                  setSCat("");
                  setSHeader("");
                  setSDesription("");
                  handleShow()}}>+ Service </button>
                  </div>
                </div>
                </div>
              </div>

            
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Service Type</th>
                    <th>Header</th>
                    <th>Description</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {displayPage.map((item, i) => {
                        const serialNumber = i + 1 + visitedPage;

                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>
                          <Image
                            src={`https://kanagamultiservice.in/Services/${item?.SImage}`}
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          />
                        </td>
                        <td style={{ paddingTop: "20px" }}>{item?.SCat}</td>
                        <td style={{ paddingTop: "20px" }}>{item?.SHeader}</td>
                        <td style={{ paddingTop: "20px" }}>
                          {SelectedId === item?._id && Viewmore
                            ? parse(`<div>${item?.SDesription}</div>`)
                            : parse(
                                `<div>${item?.SDesription.slice(0, 270)}</div>`
                              )}
                          {SelectedId === item?._id && Viewmore ? (
                            <>
                              <div
                                style={{ float: "right", marginTop: "-10px" }}
                              >
                                <a
                                  onClick={() => {
                                    setSelectedId();
                                    setViewmore(!Viewmore);
                                  }}
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  Show less...
                                </a>
                              </div>
                            </>
                          ) : (
                            <div style={{ float: "right", marginTop: "-10px" }}>
                              <a
                                onClick={() => {
                                  setSelectedId(item?._id);
                                  setViewmore(!Viewmore);
                                }}
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Show more...
                              </a>
                            </div>
                          )}
                        </td>
                        <td>
                          <button className="btn btn-success">
                            <AiTwotoneEdit onClick={() => handleShow1(item)} />
                          </button>
                        </td>
                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleShow2(item?._id);
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <Pagination style={{ float: "right" }}>
                <Pagination.First onClick={() => setPageNumber(0)} />
                <Pagination.Prev
                  onClick={() => setPageNumber((prev) => Math.max(prev - 1, 0))}
                />
                {Array.from({ length: pageCount }, (_,index) => (
                  <Pagination.Item
                    key={index}
                    active={index === pageNumber}
                    onClick={() => setPageNumber(index )}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                  }
                />
                <Pagination.Last onClick={() => setPageNumber(pageCount - 1)} />
              </Pagination>
            </>
          ) : (
            <>
              {SerList ? (
                <>
                 <div className="container">
                <div className="row mb-1">
                  <div className="col-8 col-sm-8 col-md-8 col-lg-8" style={{paddingLeft:"unset"}}>
                    <Form inline>
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className=" mr-sm-2"
                        onChange={handleFilter}
                      />
                    </Form>
                  </div>
                  <div className="col-md-4">
                  <div className="add-b text-end mb-1 col-md-12">
                    
                  </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                Service Lists 
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                      setSIcon("");
                      setScat("");
                      handleShow4()}}>+ Add Service List</button>
                  </div>
                </div>
                </div>
              </div>
                 
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Service List</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayPageP.map((item, i) => {
                        const serialNumberP = i + 1 + visitedPageP;
                         return (
                          <tr>
                            <td>{serialNumberP}</td>

                            <td>
                              <Image
                                src={`https://kanagamultiservice.in/ServiceList/${item?.SIcon}`}
                                alt="pic"
                                style={{ width: "75px", height: "75px" }}
                              />
                            </td>
                            <td>{item?.Scat}</td>
                            <td>
                              <button className="btn btn-success">
                                <AiTwotoneEdit
                                  onClick={() => handleShow6(item)}
                                />
                              </button>
                            </td>
                            <td>
                              <button className="btn btn-danger">
                                <AiTwotoneDelete
                                  style={{
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    handleShow11(item?._id);
                                  }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <Pagination style={{ float: "right" }}>
                <Pagination.First onClick={() => setPageNumberP(0)} />
                <Pagination.Prev
                  onClick={() => setPageNumberP((prev) => Math.max(prev - 1, 0))}
                />
                {Array.from({ length: pageCountP }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === pageNumberP}
                    onClick={() => setPageNumberP(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setPageNumberP((prev) => Math.min(prev + 1, pageCountP - 1))
                  }
                />
                <Pagination.Last onClick={() => setPageNumberP(pageCountP - 1)} />
              </Pagination>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setSImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Service Type</label>
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => setSCat(e.target.value)}
            >
              <option value="">----Select Service Type----</option>
              {AddServiceLists?.map((item) => {
                return <option value={item?.Scat}>{item?.Scat}</option>;
              })}
            </select>
          </div>

          <div className="do-sear mt-2">
            <label>Header</label>
            <input
              type="text"
              placeholder="Enter Header"
              className="vi_0"
              onChange={(e) => setSHeader(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor editor={ClassicEditor} onChange={handleChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddGrapph}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Add Service List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Icon</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setSIcon(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Service Type</label>
            <input
              type="text"
              placeholder="Enter Service Type"
              className="vi_0"
              onChange={(e) => setScat(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddServiceList}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>Update Service List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Icon</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setSIcon(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Service Type</label>
            <input
              type="text"
              placeholder="Enter Product Category"
              className="vi_0"
              value={Scat}
              onChange={(e) => setScat(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={(e) => {
              editServiceList(e);
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setSImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Service Type</label>
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => setSCat(e.target.value)}
            >
              <option value="">----Select Category----</option>
              {AddServiceLists?.map((item) => {
                return <option value={item?.Scat}>{item?.Scat}</option>;
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Header</label>
            <input
              type="text"
              placeholder="Enter Header"
              value={SHeader}
              className="vi_0"
              onChange={(e) => setSHeader(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={SDesription}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editgallery}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={Deletegallery}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show11} onHide={handleClose11}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose11}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteServiceList}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminServices;
