import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";

export const NavbarScrol = () => {
  return (
    <div className="nic">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12" style={{ paddingRight: "unset",paddingLeft:"unset" }}>
            <Navbar
              expand="lg"
              style={{ padding: "unset", background: "white" }}
            >
              <Container fluid style={{ paddingRight: "unset",paddingLeft:"unset" }}>
                <Navbar.Brand href="">
                  <img
                    src="kanag-logo.jpg"
                    alt=""
                    style={{ width: "257px", height: "108px" }}
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav
                    className="navigation"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #010161 , #00008e)",
                      height: "7.7rem",
                      padding: "39px",
                      width: "100%",
                    }}
                  >
                    <Nav.Link href="/">
                      <h6>Welcome</h6>{" "}
                    </Nav.Link>
                    <Nav.Link href="/aboutus">
                      <h6>About Us</h6>
                    </Nav.Link>
                    <Nav.Link href="/services">
                      <h6>Services</h6>
                    </Nav.Link>
                    <Nav.Link href="/products">
                      <h6>Product</h6>
                    </Nav.Link>
                    <Nav.Link href="/ourbrochure">
                      <h6>Our Brochure</h6>
                    </Nav.Link>
                    <Nav.Link href="/events">
                      <h6>Events</h6>
                    </Nav.Link>
                    <Nav.Link href="/gallery">
                      <h6>Gallery</h6>
                    </Nav.Link>
                    <Nav.Link href="/contactus">
                      <h6>Contact Us</h6>
                    </Nav.Link>
                 
                   
                    {/* <div style={{marginTop:"21px"}}>
                        <span>
                          {" "}
                          <GrMail
                            style={{
                              fontSize: "19px",
                              color: "white",
                              marginRight: "5px",
                              marginLeft:"87px"
                            }}
                          />{" "}
                          <span style={{ color: "white" }}>leadhrconsultingsolutions@gmail.com</span>{" "}
                          <span style={{ color: "white" }}>|</span>{" "}
                          <span>
                            <BiSolidPhoneCall
                              style={{
                                fontSize: "19px",
                                color: "white",
                                marginRight: "4px",
                              }}
                            />
                          </span>
                          <span style={{ fontSize: "16px", color: "white" }}>
                            97434&nbsp;03928
                          </span>{" "}
                        </span>
                      </div> */}
                  </Nav>
                  {/* <Nav  style={{
                      backgroundImage:
                        "linear-gradient(to right, #010161 , #00008e)",
                      height: "7.7rem",
                      padding: "39px",
                      width: "28%",
                    }}>
                  <div id="google_translate_element"></div>
                  </Nav> */}
                  {/* <Navbar >
                    <Nav className="m-auto" >
                     
                    </Nav>
                  </Navbar> */}
                </Navbar.Collapse>
               
              </Container>
            </Navbar>
          </div>
          {/* <div
            className="col-md-4"
            style={{ backgroundColor: "#010161", padding: "51px" }}
          >
            <div className="d-flex gap-3">
              <div>
                <span></span>
                <span style={{ fontSize: "16px", color: "white" }}></span>
              </div>

              <div></div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <Navbar expand="lg" className="bg-body-tertiary ">
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              src="kanag-logo.jpg"
              alt=""
              style={{ width: "257px", height: "108px" }}
            />
           
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="m-auto my-2 my-lg-0 navigation"
              style={{ maxHeight: "100%" }}
              navbarScroll
            >
              <Nav.Link href="/">Welcome</Nav.Link>
              <Nav.Link href="/aboutus">About Us</Nav.Link>
              <Nav.Link href="/services">Services</Nav.Link>
              <Nav.Link href="/products">Product</Nav.Link>
              <Nav.Link href="/ourbrochure">Our Brochure</Nav.Link>
              <Nav.Link href="/events">Events</Nav.Link>
              <Nav.Link href="/gallery">Gallery</Nav.Link>
              <Nav.Link href="/contactus">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
    </div>
  );
};
