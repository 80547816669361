import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
const Gallery = () => {
  // get method for image
  const [addgallery, setaddgallery] = useState([]);
  const getaddgallerys = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddGallery");
    if (res.status === 200) {
      setaddgallery(res.data.getadddgallery);
    }
  };
  // get method for video
  const [addVgallery, setaddVgallery] = useState([]);
  const getaddVgallerys = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddVGallery");
    if (res.status === 200) {
      setaddVgallery(res.data.getadddVgallery);
    }
  };

  useEffect(() => {
    window.scroll(0,0);
    getaddgallerys();
    getaddVgallerys();
  }, []);
  console.log(addgallery);
  return (
    <div>
      <div
        className="container mt-3 mb-2"
        style={{
          backgroundImage: 'url("../we-provide-bg.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "200px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-slider3 text-center">
          <h1
            style={{
              color: "white",
              marginTop: "66px",
              fontSize: "50px",
            }}
          >
            Gallery
          </h1>
        </div>
      </div>

      <div className="container pt-4 mb-3">
        <div className="row text-center" 
        style={{backgroundColor:"#F5F5F5",padding:"20px"}}
        >
          <h2 style={{color:"#FE5E00"}}>Images</h2>
        </div>
        <div className="row mt-2">
          {addgallery.map((item, i) => {
            return (
              <div className="col-md-4 text-center">
                
                <img
                  src={`https://kanagamultiservice.in/Gallery/${item?.AddImg}`}
                  alt=""
                  style={{ width: "100%",height:"250px" }}
                />
                <h5>{item?.AddText}</h5>
              </div>
            );
          })}
        </div>
        <br />
      </div>

      <div className="container pt-4 mb-3">
      <div className="row text-center" 
        style={{backgroundColor:"#F5F5F5",padding:"20px"}}
        >
          <h2 style={{color:"#FE5E00"}}>Videos</h2>
        </div>
        <div className="row mt-2">
          {addVgallery.map((item, i) => {
            return (
              <div className="col-md-4 text-center">
               <object
                  data={`https://kanagamultiservice.in/Video/${item?.VAddImg}`}
                  type="video/mp4"
                  style={{ width: "100%", height: "250px" }}
                >
                </object>
               
                <h5>{item?.VAddText}</h5>
              </div>
            );
          })}
        </div>
        <br />
      </div>
    </div>
  );
};

export default Gallery;
