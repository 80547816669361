import React, { useEffect, useState } from "react";
import axios from "axios";
import { SlSocialInstagram } from "react-icons/sl";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AiFillLinkedin } from "react-icons/ai";
import { useNavigate } from "react-router-dom";


function Footer() {
  const navigate = useNavigate();
  //integrating get  method
  //integrating get  method of head office
  const [AddContactuss, setAddContactuss] = useState([]);
  const getAddContactuss = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddContactUs"
      );
      if (res.status === 200) {
        setAddContactuss(res.data.getaddContactUs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method of branch office
  const [AddBContactuss, setAddBContactuss] = useState([]);
  const getAddBContactuss = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddBContactUs"
      );
      if (res.status === 200) {
        setAddBContactuss(res.data.getaddBContactUs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //get method for linkedin
  const [addLSocial, setaddLSocial] = useState([]);
  const getaddLSocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddLSocial");
    if (res.status === 200) {
      setaddLSocial(res.data.getaddLSocial);
    }
  };
  //get methof for Instagram
  const [addISocial, setaddISocial] = useState([]);
  const getaddISocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddISocial");
    if (res.status === 200) {
      setaddISocial(res.data.getaddISocial);
    }
  };
  // get method for facebook
  const [addFSocial, setaddFSocial] = useState([]);
  const getaddFSocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddFSocial");
    if (res.status === 200) {
      setaddFSocial(res.data.getaddFSocial);
    }
  };
  // get method
  const [addTSocial, setaddTSocial] = useState([]);
  const getaddTSocial = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddTSocial");
    if (res.status === 200) {
      setaddTSocial(res.data.getaddTSocial);
    }
  };
  
  useEffect(() => {
    getAddContactuss();
    getAddBContactuss();
    getaddLSocial();
    getaddISocial();
    getaddFSocial();
    getaddTSocial();
  }, []);
  return (
    <>
      <div className="wefre">
        <div
          className="udhciwe"
          style={{
            backgroundColor: "#141414",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="ejfie pb-4" style={{ paddingTop: "70px" }}>
              <div className="row">
                {AddContactuss.map((item, i) => {
                  return (
                    <div className="col-md-4">
                      <h5
                        className="text-uppercase mb-4 font-weight-bold"
                        style={{ color: "#FE5E00" }}
                      >
                        HEAD OFFICE
                      </h5>

                      <p style={{ color: "whitesmoke" }}>
                        <h5 style={{ color: "#F5F5F5" }}>Head Office</h5>
                        <hr style={{ width: "50%" }} />
                        <p>{item?.ConAddress}</p>
                      </p>
                      <p style={{ color: "whitesmoke" }}>
                        <p>
                          {item?.AddPhone}
                          <br />
                          {item?.AddEmail}
                        </p>
                      </p>
                    </div>
                  );
                })}
                {AddBContactuss.map((item, i) => {
                  return (
                    <div className="col-md-4">
                      <h5
                        className="text-uppercase mb-4 font-weight-bold"
                        style={{ color: "#FE5E00" }}
                      >
                        BRANCHES
                      </h5>

                      <p style={{ color: "whitesmoke" }}>
                        <h5 style={{ color: "#F5F5F5" }}>Branch Office</h5>
                        <hr style={{ width: "50%" }} />
                        <p>
                          {item?.BConAddress}
                          <br />
                          {item?.BAddPhone}
                          <br />
                          {item?.BAddEmail}
                        </p>
                      </p>
                    </div>
                  );
                })}
                <div className="col-md-2">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold "
                    style={{ color: "#FE5E00" }}
                  >
                    QUICK ACCESS
                  </h5>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <p
                          style={{ color: "whitesmoke", cursor: "pointer" }}
                          onClick={() => navigate("/aboutus")}
                        >
                          {" "}
                          <KeyboardArrowRightIcon />
                          About Us
                        </p>
                        <p
                          style={{ color: "whitesmoke", cursor: "pointer" }}
                          onClick={() => navigate("/services")}
                        >
                          <KeyboardArrowRightIcon />
                          Our Services
                        </p>
                        <p
                          style={{ color: "whitesmoke", cursor: "pointer" }}
                          onClick={() => navigate("/contactus")}
                        >
                          <KeyboardArrowRightIcon />
                          Contact us
                        </p>
                        <p
                          style={{ color: "whitesmoke", cursor: "pointer" }}
                          onClick={() => navigate("/ourbrochure")}
                        >
                          {" "}
                          <KeyboardArrowRightIcon />
                          Our Brochure
                        </p>
                        <p
                          style={{ color: "whitesmoke", cursor: "pointer" }}
                          onClick={() => navigate("/products")}
                        >
                          {" "}
                          <KeyboardArrowRightIcon />
                          Product
                        </p>
                        <p
                          style={{ color: "whitesmoke", cursor: "pointer" }}
                          onClick={() => navigate("/events")}
                        >
                          {" "}
                          <KeyboardArrowRightIcon />
                          Events
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold"
                    style={{ color: "#FE5E00" }}
                  >
                    SOCIAL MEDIA
                  </h5>

                  <div className="d-flex justify-content-between ">
                    <p>
                      {addLSocial?.map((item) => {
                        return (
                          <a
                            className="text-white"
                            style={{ textDecoration: "none" }}
                            href={item?.LSocial}
                          >
                            <AiFillLinkedin
                              style={{ fontSize: "30px", color: "whitesmoke" }}
                            />
                          </a>
                        );
                      })}
                    </p>
                    <p>
                      {addISocial?.map((item) => {
                        return (
                          <a
                            className="text-white"
                            style={{ textDecoration: "none" }}
                            href={item?.ISocial}
                          >
                            <SlSocialInstagram
                              style={{ fontSize: "30px", color: "whitesmoke" }}
                            />
                          </a>
                        );
                      })}
                    </p>
                    <p>
                      {addFSocial?.map((item) => {
                        return (
                          <a
                            className="text-white"
                            style={{ textDecoration: "none" }}
                            href={item?.FSocial}
                          >
                            <TiSocialFacebookCircular
                              style={{ fontSize: "30px", color: "whitesmoke" }}
                            />
                          </a>
                        );
                      })}
                    </p>
                    <p>
                      {addTSocial?.map((item) => {
                        return (
                          <a
                            className="text-white"
                            style={{ textDecoration: "none" }}
                            href={item?.TSocial}
                          >
                            <TiSocialTwitter
                              style={{ fontSize: "30px", color: "whitesmoke" }}
                            />
                          </a>
                        );
                      })}
                    </p>
                  </div>
                
                  <img
                    src="kanag-logo.jpg"
                    alt=""
                    style={{ width: "100%", height: "108px" }}
                  />
                  
                </div>
              </div>
            </div>
            <hr style={{ color: "whitesmoke" }} />
            <div className="container mb-3">
              <div className="row text-center">
                <div className="col">
                  <p style={{ color: "whitesmoke" }}>
                    Kanaga Multi Services - Trusted by over thousands of traders for
                    successfull services provided.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="aefe"
              style={{ display: "flex", justifyContent: "space-between" }}
            ></div>
            <div style={{ backgroundColor: "red" }}></div>
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <p style={{ color: "whitesmoke" }}>
                    Copyright © Kanaga Multi Services, All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
