import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import Form from "react-bootstrap/Form";

const AdminContactUs = () => {
  const [HeadCon, setHeadCon] = useState(true);
  const [BrCon, setBrCon] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // integrating post method
  const formdata = new FormData();
  const [CImg, setCImg] = useState("");
  const [ConAddress, setConAddress] = useState("");
  const [AddPhone, setAddPhone] = useState("");
  const [AddEmail, setAddEmail] = useState("");

  const AddContactus = async () => {
    formdata.append("CImg", CImg);
    formdata.append("ConAddress", ConAddress);
    formdata.append("AddPhone", AddPhone);
    formdata.append("AddEmail", AddEmail);
    try {
      if(!CImg) return alert("Please Select the Image");
      if(!ConAddress) return alert("Please Enter the Address");
      if(!AddPhone) return alert("Please Enter the Phone no");
      if(!AddEmail) return alert("Please Enter the Email id");

      const config = {
        url: "/admin/addContactUs",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddContactuss();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddContactuss, setAddContactuss] = useState([]);
  const [nochangedataH, setnochangedataH] = useState([]);

  const getAddContactuss = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddContactUs"
      );
      if (res.status === 200) {
        setAddContactuss(res.data.getaddContactUs);
        setnochangedataH(res.data.getaddContactUs);

      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteAddcontact = async () => {
    try {
      const config = {
        url: "admin/DeleteAddContactUs/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        headers: { "content-type": "multipart/form-data" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getAddContactuss();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setConAddress(item?.ConAddress);
    setAddPhone(item?.AddPhone);
    setAddEmail(item?.AddEmail);
  };

  const editContactus = async (e) => {
    e.preventDefault();

    formdata.append("ConAddress", ConAddress);
    formdata.append("AddPhone", AddPhone);
    formdata.append("AddEmail", AddEmail);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "/admin/updateContactUs",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Updated");
          handleClose1();
          getAddContactuss();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  //Branch office
  // integrating post method
  const [BConAddress, setBConAddress] = useState("");
  const [BAddPhone, setBAddPhone] = useState("");
  const [BAddEmail, setBAddEmail] = useState("");

  const AddBContactus = async () => {
    try {
      if(!BConAddress) return alert("Plesae Enter the Address");
      if(!BAddPhone) return alert("Plesae Enter the Phone no");
      if(!BAddEmail) return alert("Plesae Enter the Email id");
      const config = {
        url: "/admin/addBContactUs",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "application/json" },
        data: {
          BConAddress: BConAddress,
          BAddPhone: BAddPhone,
          BAddEmail: BAddEmail,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddBContactuss();
        handleClose10();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddBContactuss, setAddBContactuss] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAddBContactuss = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddBContactUs"
      );
      if (res.status === 200) {
        setAddBContactuss(res.data.getaddBContactUs);
        setnochangedata(res.data.getaddBContactUs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data2, setData2] = useState("");
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = (item) => {
    setShow12(true);
    setData2(item);
  };
  const DeleteAddBcontact = async () => {
    try {
      const config = {
        url: "admin/deleteaddBContactUs/" + Data2,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getAddBContactuss();
          handleClose12();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data3, setData3] = useState("");
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = (item) => {
    setShow11(true);
    setData3(item);
    setBConAddress(item?.BConAddress);
    setBAddPhone(item?.BAddPhone);
    setBAddEmail(item?.BAddEmail);
  };

  const editBContactus = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/updateBContactUs",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "application/json" },
        data: {
          BConAddress: BConAddress,
          BAddPhone: BAddPhone,
          BAddEmail: BAddEmail,
          id: Data3?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Updated");
          handleClose11();
          getAddBContactuss();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAddContactuss();
    getAddBContactuss();
  }, []);
  console.log(AddContactuss);
  console.log(getAddBContactuss);

  //search filter for branch office
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAddBContactuss([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAddBContactuss([...nochangedata]);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  const searchedProduct = AddBContactuss.filter((item) => {
    if (searchTerm.value === "") {
      return item;
    }
    if (item?.ConAddress?.toLowerCase().includes(searchTerm?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });

   //search filter for head office
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAddContactuss([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAddContactuss([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = AddContactuss.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.ConAddress?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });

  
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            <button
              onClick={() => {
                setHeadCon(true);
                setBrCon(false);
              }}
            >
              Head Office
            </button>
            &nbsp;
            <button
              onClick={() => {
                setHeadCon(false);
                setBrCon(true);
              }}
            >
              Branch Office
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-3 ms-5">
          {HeadCon ? (
            <>
              <div className="container-fluid">
                <div className="row mb-3">
                  <div className="col-8 col-sm-8 col-md-8 col-lg-8" style={{paddingLeft:"unset"}}>
                    <Form inline>
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className=" mr-sm-2"
                        onChange={handleFilterH}
                      />
                    </Form>
                  </div>
                  
                  <div className="d-flex justify-content-between mt-2">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                     Head Office Details
                  </h2>
                  <div className="add-b">
                  {AddContactuss?.length !== 0 ? (
                      ""
                    ) : (
                      <>
                        <div className="add-b text-end  mb-4 col-md-12">
                          <button onClick={()=>{
                            setCImg("");
                            setConAddress("");
                            setAddPhone("");
                            setAddEmail("");
                            handleShow()}}>
                            Add Head Office Contact Details
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                </div>
              </div>

              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Head Office Address</th>
                    <th> Contact No.</th>
                    <th>Email</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {AddContactuss.map((item, i) => {
                    return (
                      <tr>
                        <td style={{ paddingTop: "20px" }}> {i + 1}</td>
                        <td>
                          <Image
                            src={`https://kanagamultiservice.in/ContactHead/${item?.CImg}`}
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          />
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item?.ConAddress}
                        </td>
                        <td style={{ paddingTop: "20px" }}>{item?.AddPhone}</td>
                        <td style={{ paddingTop: "20px" }}>{item?.AddEmail}</td>
                        <td>
                          {" "}
                          <button className="btn btn-success">
                            <AiTwotoneEdit
                              onClick={() => {
                                handleShow1(item);
                              }}
                            />
                          </button>
                        </td>

                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleShow2(item?._id);
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              {BrCon ? (
                <>
                  <div className="container-fluid">
                    <div className="row mb-3">
                      <div className="col-8 col-sm-8 col-md-8 col-lg-8" style={{paddingLeft:"unset"}}>
                        <Form inline>
                          <Form.Control
                            type="search"
                            placeholder="Search"
                            className=" mr-sm-2"
                            onChange={handleFilter}
                          />
                        </Form>
                      </div>
                      
                      <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
            Branch Office Details
                  </h2>
                  <div className="add-b">
                  {AddBContactuss?.length !== 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="add-b text-end  mb-4 col-md-12">
                              <button onClick={()=>{
                                setBConAddress("");
                                setBAddPhone("");
                                setBAddEmail("");
                                handleShow10()}}>
                                Add Branch Office Contact Details
                              </button>
                            </div>
                          </>
                        )}
                  </div>
                </div>
                    </div>
                  </div>
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Branch Address</th>
                        <th> Contact No.</th>
                        <th>Email</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AddBContactuss.map((item, i) => {
                        return (
                          <tr>
                            <td style={{ paddingTop: "20px" }}> {i + 1}</td>

                            <td style={{ paddingTop: "20px" }}>
                              {item?.BConAddress}
                            </td>
                            <td style={{ paddingTop: "20px" }}>
                              {item?.BAddPhone}
                            </td>
                            <td style={{ paddingTop: "20px" }}>
                              {item?.BAddEmail}
                            </td>
                            <td>
                              {" "}
                              <button className="btn btn-success">
                                <AiTwotoneEdit
                                  onClick={() => {
                                    handleShow11(item);
                                  }}
                                />
                              </button>
                            </td>

                            <td>
                              <button className="btn btn-danger">
                                <AiTwotoneDelete
                                  style={{
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    handleShow12(item?._id);
                                  }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Head Office Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setCImg(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Address</label>
            <textarea
              name=""
              className="vi_0"
              id=""
              cols="30"
              rows="3"
              placeholder="Enter Address"
              onChange={(e) => {
                setConAddress(e.target.value);
              }}
            ></textarea>
          </div>

          <div className="do-sear mt-2">
            <label>Mobile no</label>
            <input
              type="text"
              placeholder="Enter Mobile No."
              className="vi_0"
              onChange={(e) => setAddPhone(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="vi_0"
              onChange={(e) => setAddEmail(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={AddContactus}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show10} onHide={handleClose10}>
        <Modal.Header closeButton>
          <Modal.Title>Add Branch Office Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Address</label>
            <textarea
              name=""
              className="vi_0"
              id=""
              cols="30"
              rows="3"
              placeholder="Enter Address"
              onChange={(e) => {
                setBConAddress(e.target.value);
              }}
            ></textarea>
          </div>

          <div className="do-sear mt-2">
            <label>Mobile no</label>
            <input
              type="text"
              placeholder="Enter Mobile No."
              className="vi_0"
              onChange={(e) => setBAddPhone(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="vi_0"
              onChange={(e) => setBAddEmail(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose10}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={AddBContactus}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Head Office Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setCImg(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Address</label>
            <textarea
              name=""
              className="vi_0"
              id=""
              cols="30"
              rows="3"
              placeholder="Enter Address"
              onChange={(e) => {
                setConAddress(e.target.value);
              }}
              value={ConAddress}
            ></textarea>
          </div>

          <div className="do-sear mt-2">
            <label>Mobile no</label>
            <input
              type="text"
              placeholder="Enter Mobile No."
              className="vi_0"
              value={AddPhone}
              onChange={(e) => setAddPhone(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="vi_0"
              value={AddEmail}
              onChange={(e) => setAddEmail(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={editContactus}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show11} onHide={handleClose11}>
        <Modal.Header closeButton>
          <Modal.Title>Update Branch Office Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Address</label>
            <textarea
              name=""
              className="vi_0"
              id=""
              cols="30"
              rows="3"
              placeholder="Enter Address"
              onChange={(e) => {
                setBConAddress(e.target.value);
              }}
              value={BConAddress}
            ></textarea>
          </div>

          <div className="do-sear mt-2">
            <label>Mobile no</label>
            <input
              type="text"
              placeholder="Enter Mobile No."
              className="vi_0"
              value={BAddPhone}
              onChange={(e) => setBAddPhone(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="vi_0"
              value={BAddEmail}
              onChange={(e) => setBAddEmail(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose11}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={editBContactus}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={DeleteAddcontact}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show12} onHide={handleClose12}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose12}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ backgroundColor: "#E81B24", border: "none" }}
            onClick={DeleteAddBcontact}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AdminContactUs;
