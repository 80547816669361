import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminHomeServiceList = () => {
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setSText2(data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [service, setService] = useState(true);
  // integrating post method
  const formdata = new FormData();
  const [SImage, setSImage] = useState("");
  const [GrText, setGrText] = useState("");
  const [SText1, setSText1] = useState("");
  const [SText2, setSText2] = useState("");
  const AddGrapph = async () => {
    formdata.append("SImage", SImage);
    formdata.append("GrText", GrText);
    formdata.append("SText1", SText1);
    formdata.append("SText2", SText2);

    try {
      const config = {
        url: "/admin/Service",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddGraph();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddGraph, setAddGraph] = useState([]);
  const getAddGraph = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallService"
      );
      if (res.status === 200) {
        setAddGraph(res.data.getAddService);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const Deletegallery = async () => {
    try {
      const config = {
        url: "admin/DeleteAddService/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddGraph();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setGrText(item?.GrText);
    setSText1(item?.SText1);
    setSText2(item?.SText2);
  };

  const editgallery = async (e) => {
    e.preventDefault();
    formdata.append("SImage", SImage);
    formdata.append("SText1", SText1);
    formdata.append("SText2", SText2);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "/admin/editAddService",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddGraph();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddGraph();
  }, []);
  console.log(AddGraph);
  const [ServiceB, setServiceB] = useState("");

 
  //integrating get  method
  const [AddServiceB, setAddServiceB] = useState([]);
  const getAddServiceBs = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get("https://kanagamultiservice.in/api/admin/getServiceB");
      if (res.status == 200) {
        setAddServiceB(res.data.getServiceB);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data4, setData4] = useState("");
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = (item) => {
    setShow7(true);
    setData4(item);
  };
  const deleteServiceBs = async () => {
    try {
      const config = {
        url: "admin/DeleteServiceB/" + Data4,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddServiceBs();
          handleClose7();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Aexp, setAexp] = useState("");
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = (item) => {
    setShow6(true);
    setAexp(item);
    setServiceB(item?.ServiceB);
  };
  const editServiceBs = async (e) => {
    e.preventDefault();
    formdata.append("ServiceB", ServiceB);
    formdata.append("id", Aexp?._id);

    try {
      const config = {
        url: "/admin/editServiceB",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose6();
          getAddServiceBs();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAddServiceBs();
  }, []);
  console.log(AddServiceB);
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="ad-b mt-4 mb-4 col-md-12">
              <button onClick={handleShow}>+ Service</button>&nbsp;
              &nbsp;
              {service ? (
                <>
                  {" "}
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setService(!service);
                    }}
                  >
                    Service List
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5 ps-5">
          {service ? (
            <>
              {" "}
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Header</th>
                    <th>Description</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {AddGraph.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          <Image
                            src={`https://kanagamultiservice.in/Service/${item?.SImage}`}
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          />
                        </td>

                        <td style={{ paddingTop: "20px" }}>{item.SText1}</td>
                        <td style={{ paddingTop: "20px" }}>{item.SText2}</td>
                        <td>
                          <button className="btn btn-success">
                            <AiTwotoneEdit onClick={() => handleShow1(item)} />
                          </button>

                        </td>
                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleShow2(item?._id);
                              }}
                            />
                          </button>
                          
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              {" "}
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Brand Logo</th>

                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {AddServiceB.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          <Image
                            src={`https://kanagamultiservice.in/ServiceBrand/${item?.ServiceB}`}
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          />
                        </td>
                        <td>
                          <button className="btn btn-success">
                            <AiTwotoneEdit onClick={() => handleShow6(item)} />
                          </button>

                        </td>
                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleShow7(item?._id);
                              }}
                            />
                          </button>
                         
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setSImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Header</label>
            <input
              type="text"
              placeholder="Enter Text 1"
              className="vi_0"
              onChange={(e) => setSText1(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddGrapph}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setSImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Header</label>
            <input
              type="text"
              placeholder="Enter Years"
              value={SText1}
              className="vi_0"
              onChange={(e) => setSText1(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={SText2}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editgallery}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={Deletegallery}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show7} onHide={handleClose7}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose7}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteServiceBs}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminHomeServiceList;
