import axios from "axios";
import React, { useState } from "react";
import { BiSolidLockAlt } from "react-icons/bi";
import InputGroup from "react-bootstrap/InputGroup";
import { HiMail } from "react-icons/hi";
import { FaRegKeyboard } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaHeadphones } from "react-icons/fa";

function AdminLogin() {
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  //otp
  const [email, setemail] = useState("");

  const [emailv, setemailv] = useState("");
  const [otp, setotp] = useState("");
  const [admindata, setadmindata] = useState({});

  const sendOTP = async () => {
    try {
      const config = {
        url: "/admin/sendOtp",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          email: email,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          setemailv(res.data.success);
          alert("OTP sent to your Email ID");
          handleClose7();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //verify OTP

  const verifyOTP = async () => {
    try {
      const config = {
        url: "/admin/VerifyOtp",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          email: emailv,
          otp: otp,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          setadmindata(res.data.success);
          handleClose8();
          alert("OTP verified successfully");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  console.log("admindata", admindata);
  //changepassword
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [changepassword, setchangepassword] = useState("");

  const ChangePassword = async () => {
    try {
      const config = {
        url: "/admin/ChangePassword",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          AdminId: admindata?._id,
          RPassword: changepassword,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose7();
          handleClose8();
          handleClose9();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  // // integrating post method for  admin login
  const [RPassword, setRPassword] = useState("");
  const [REmail, setREmail] = useState("");

  const login = async (e) => {
    e.preventDefault();

    try {
      if (!REmail) return alert("Please enter email id");
      if (!RPassword) return alert("Please enter password");
      const config = {
        url: "/admin/adminLogin",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          RPassword: RPassword,
          REmail: REmail,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.setItem("admin", JSON.stringify(res.data.adminlogin));
          window.location.assign("/Admin_home_graph");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [PasswordShow, setPasswordShow] = useState(false);
  return (
    <>
      <div>
        <div className="container p-5">
          <div className="row ">
            <div className="col-md-6">
              <div
                className="container counts_bg"
                style={{
                  backgroundImage: 'url("../slide-bg-2.jpg")',
                  backgroundSize: "cover",
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  height: "400px",
                  backgroundRepeat: "no-repeat",
                  position: " relative",
                  left: "-3px",
                  boxSizing: "border-box",
                  maxWidth: "100vw",
                }}
              >
                <div className="bs-slider"></div>
                <div className="row text-center ">
                  <div className="col-md-12 bs-slider2">
                    <div className="login">
                      <BiSolidLockAlt
                        style={{
                          border: "10px solid #FE5E00",
                          borderRadius: "50%",
                          padding: "20px",
                          fontSize: "118px",
                        }}
                      />
                    </div>
                    <h4 style={{ color: "black" }}>Welcome To Our Account</h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 p-5"
              style={{ backgroundColor: "#E8E8E8", marginLeft: "-15px" }}
            >
              <h1>Admin Login</h1>
              <hr
                style={{
                  color: "red",
                  border: "1px solid #FE5E00",
                  width: "20%",
                }}
              />
              <Form inline>
                <InputGroup className=" pt-3" style={{ width: "100%" }}>
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ backgroundColor: "#FE5E00", color: "white" }}
                  >
                    <HiMail />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => setREmail(e.target.value)}
                    required
                  />
                </InputGroup>
                <InputGroup className=" pt-4" style={{ width: "100%" }}>
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ backgroundColor: "#FE5E00", color: "white" }}
                  >
                    <FaRegKeyboard />
                  </InputGroup.Text>
                  <Form.Control
                    type={PasswordShow ? "text" : "password"}
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    onChange={(e) => setRPassword(e.target.value)}
                    required
                  />

                  <InputGroup.Text
                    id="basic-addon1"
                    onClick={() => setPasswordShow(!PasswordShow)}
                  >
                    {PasswordShow ? (
                      <FaEye className="passbtn" />
                    ) : (
                      <FaEyeSlash className="passbtn" />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                <p
                  onClick={() => {
                    handleShow9();
                  }}
                  style={{
                    float: "right",
                    fontSize: "14px",
                    marginTop: "16px",
                    cursor: "pointer",
                    color:"blue",
                    textDecoration:"underline"

                  }}
                >
                  Forgot Password?
                </p>
                {/* <a href={} >
                  
                </a> */}
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={(e) => login(e)}
                  style={{ backgroundColor: "#FE5E00", marginTop: "30px" }}
                >
                  Login
                </button>{" "}
              </Form>
            </div>
          </div>
        </div>

        {/* modals */}
        <Modal style={{ zIndex: "9999999" }} show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>New Password</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <label
                    htmlFor=""
                    style={{
                      color: "#083294",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Enter New Password
                  </label>
                  <InputGroup className="mb-3">
                    {/* <InputGroup.Text id="inputGroup-sizing-default">
                      <FaHeadphones style={{ color: "#083294" }} />
                    </InputGroup.Text> */}
                    <Form.Control
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setchangepassword(e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                ChangePassword();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal style={{ zIndex: "9999999" }} show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>OTP Verification</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <label
                    htmlFor=""
                    style={{
                      color: "#083294",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Enter OTP
                  </label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setotp(e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                verifyOTP();
                handleShow7();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal style={{ zIndex: "9999999" }} show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title> Reset Password</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <label
                    htmlFor=""
                    style={{
                      color: "#083294",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Enter mail id
                  </label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="mail"
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                sendOTP();
                handleShow8();
              }}
            >
              Send OTP
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AdminLogin;
