import React, { useEffect, useState } from "react";
import { Button, Table, Image, Pagination, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { BsSearch } from "react-icons/bs";
import moment from "moment/moment";
import { DatePicker, Space } from "antd";

const AdminEvents = () => {
  const [EBanner, setEBanner] = useState(true);
  const [EList, setEList] = useState(false);
  const [UpcomingEvents, setUpcomingEvents] = useState(false);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setEDesc(data);
  };
  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setUEDesc(data);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [show100, setShow100] = useState(false);
  const handleClose100 = () => setShow100(false);
  const handleShow100 = () => setShow100(true);
  //Events List
  // integrating post method
  const formdata = new FormData();
  const [EImage, setEImage] = useState("");
  const [EName, setEName] = useState("");
  const [ESDate, setESDate] = useState("");
  const [EEDate, setEEDate] = useState("");
  const [ELoc, setELoc] = useState("");
  const [EDesc, setEDesc] = useState("");
  const AddEventsList = async () => {
    formdata.append("EImage", EImage);
    formdata.append("EName", EName);
    formdata.append("ESDate", ESDate);
    formdata.append("EEDate", EEDate);
    formdata.append("ELoc", ELoc);
    formdata.append("EDesc", EDesc);

    try {
      if (!EImage) return alert("Please Select the Image");
      if (!EName) return alert("Please Enter the Event Name");
      if (!ESDate) return alert("Please Select the Start Date");
      if (!EEDate) return alert("Please Select the End Date");
      if (!ELoc) return alert("Please Enter the Location");
      if (!EDesc) return alert("Please Entre the Description");
      const config = {
        url: "/admin/EventsList",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getEventsList();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [EventsList, setEventsList] = useState([]);
  const getEventsList = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallEventsList"
      );
      if (res.status === 200) {
        setnochangedataH(res.data.getAddEventsList);
        setEventsList(res.data.getAddEventsList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteEventsList = async () => {
    try {
      const config = {
        url: "admin/DeleteAddEventsList/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "multipart/form-data" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getEventsList();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setEName(item?.EName);
    setESDate(item?.ESDate);
    setEEDate(item?.EEDate);
    setELoc(item?.ELoc);
    setEDesc(item?.EDesc);
  };

  const editEventsList = async (e) => {
    e.preventDefault();
    formdata.append("EImage", EImage);
    formdata.append("EName", EName);
    formdata.append("ESDate", ESDate);
    formdata.append("EEDate", EEDate);
    formdata.append("ELoc", ELoc);
    formdata.append("EDesc", EDesc);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "/admin/editAddEventsList",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getEventsList();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // Events Banner
  // post method
  const formdata1 = new FormData();
  const [EBanners, setEBanners] = useState("");
  const AddEventsBanner = async () => {
    formdata1.set("EBanners", EBanners);
    try {
      if (!EBanners) return alert("Please Select the Image");
      const config = {
        url: "/admin/addEBanners",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose4();
          getAddEventsBanner();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating category get method
  const [AddEventsbanner, setAddEventsbanner] = useState([]);
  const getAddEventsBanner = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddEBanners"
      );
      if (res.status == 200) {
        setAddEventsbanner(res.data.getadddEBanners);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating categories delete method
  const [Data2, setData2] = useState("");
  const [show33, setShow33] = useState(false);
  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setData2(item);
  };
  const deleteEventsbanner = async () => {
    try {
      const config = {
        url: "admin/deleteaddEBanners/" + Data2,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        headers: { "content-type": "multipart/form-data" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          handleClose33();
          getAddEventsBanner();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //Upcoming events
  // integrating post method
  const formdata2 = new FormData();
  const [UEImage, setUEImage] = useState("");
  const [UEName, setUEName] = useState("");
  const [UESDate, setUESDate] = useState("");
  const [UEEDate, setUEEDate] = useState("");
  const [UELoc, setUELoc] = useState("");
  const [UEDesc, setUEDesc] = useState("");
  const AddUEventsList = async () => {
    formdata2.append("UEImage", UEImage);
    formdata2.append("UEName", UEName);
    formdata2.append("UESDate", UESDate);
    formdata2.append("UEEDate", UEEDate);
    formdata2.append("UELoc", UELoc);
    formdata2.append("UEDesc", UEDesc);

    try {
      const config = {
        url: "/admin/UEventsList",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata2,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getUEventsList();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [UEventsList, setUEventsList] = useState([]);
  const getUEventsList = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallUEventsList"
      );
      if (res.status === 200) {
        setUEventsList(res.data.getAddUEventsList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data4, setData4] = useState("");
  const [show98, setShow98] = useState(false);
  const handleClose98 = () => setShow98(false);
  const handleShow98 = (item) => {
    setShow98(true);
    setData4(item);
  };
  const DeleteUEventsList = async () => {
    try {
      const config = {
        url: "admin/DeleteAddUEventsList/" + Data4,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api/",
        header: { "content-type": "multipart/form-data" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully deleted");
          getUEventsList();
          handleClose98();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data10, setData10] = useState("");
  const [show99, setShow99] = useState(false);
  const handleClose99 = () => setShow99(false);
  const handleShow99 = (item) => {
    setShow99(true);
    setData10(item);
    setUEName(item?.UEName);
    setUESDate(item?.UESDate);
    setUEEDate(item?.UEEDate);
    setUELoc(item?.UELoc);
    setUEDesc(item?.UEDesc);
  };

  const editUEventsList = async (e) => {
    e.preventDefault();
    formdata2.append("UEImage", UEImage);
    formdata2.append("UEName", UEName);
    formdata2.append("UESDate", UESDate);
    formdata2.append("UEEDate", UEEDate);
    formdata2.append("UELoc", UELoc);
    formdata2.append("UEDesc", UEDesc);
    formdata2.append("id", Data10?._id);
    try {
      const config = {
        url: "/admin/editAddUEventsList",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");

          getUEventsList();
          handleClose99();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getEventsList();
    getUEventsList();
    getAddEventsBanner();
  }, []);
  console.log(AddEventsList);
  console.log(AddEventsbanner);

  const [Viewmore, setViewmore] = useState(true);
  const [SelectedId, setSelectedId] = useState();
  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = EventsList.slice(
    visitedPage,
    visitedPage + productPerPage
  );
  const pageCount = Math.ceil(EventsList.length / productPerPage);

  //search filter for eventlist request
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setEventsList([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setEventsList([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = EventsList.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.EFName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  //   Row Filter
  const [itempage, setItempage] = useState(5);

  //   DateRange Filter
  const [datap, setDatap] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const filterData = () => {
    if (!ESDate) return alert("Please select from date");
    if (!EEDate) return alert("Please select to date");
    const filteredData = datap.filter((item) => {
      const itemDate = new Date(item?.createdAt);
      const startDateObj = new Date(ESDate);
      const endDateObj = new Date(EEDate);

      return itemDate >= startDateObj && itemDate <= endDateObj;
    });
    setDatap([...filteredData]);
  };

  //================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    // console.log("dateFrom: ", date?.$d);
    if (toDate !== "") {
      // if (date?.$y > toDate) {
      //   return alert("Invalid date filteration!!!");
      // } else {
      setFromDate(date?.$d);
      // }
    } else {
      setFromDate(date?.$d);
    }
  };
  // const onChangeTo = (date, dateString) => {
  //   console.log("dateTo: ", date);

  //   if (FromDate !== "") {
  //     // if (date?.$d < FromDate) {
  //     //   return alert("Invalid date filteration!!!");
  //     // } else {
  //     settoDate(date?.$d);
  //     // }
  //   } else {
  //     settoDate(date?.$d);
  //   }
  // };

  console.log(
    "just checking: ",
    displayPage.filter((val) => {
      if (FromDate && toDate) {
        console.log(
          moment(FromDate).format("DD-MM-YYYY"),
          moment(val?.ESDate).format("DD-MM-YYYY"),
          moment(toDate).format("DD-MM-YYYY"),
          moment(val?.EEDate).format("DD-MM-YYYY")
        );
        if (
          moment(FromDate).format("DD-MM-YYYY") ==
            moment(val?.ESDate).format("DD-MM-YYYY") &&
          moment(toDate).format("DD-MM-YYYY") ==
            moment(val?.EEDate).format("DD-MM-YYYY")
        ) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    })
  );

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="ad-b mt-4 mb-4 col-md-12">
              <button
                onClick={() => {
                  setEBanner(true);
                  setEList(false);
                  setUpcomingEvents(false);
                }}
              >
                Event Banner
              </button>
              <button
                onClick={() => {
                  setEBanner(false);
                  setEList(true);
                  setUpcomingEvents(false);
                }}
              >
                Events List
              </button>
              {/* <button
                onClick={() => {
                  setUpcomingEvents(true);
                  setEBanner(false);
                  setEList(false);
                }}
              >
                Upcoming Events 
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra  ms-5">
          {EBanner ? (
            <>
             
              <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
               Event Banners
                  </h2>
                  <div className="add-b">
                  <button
                  onClick={() => {
                    setEBanners("");
                    handleShow4();
                  }}
                >
                  + Add Event Banner
                </button>
                  </div>
                </div>
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Banner</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {AddEventsbanner.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          <Image
                            src={`https://kanagamultiservice.in/EventBanner/${item?.EBanners}`}
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          />
                        </td>
                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleShow33(item?._id);
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              {EList ? (
                <>
                  <div className="row p-2 mb-3">
                    {/* <div className="col-lg-2 " style={{ width: "fit-content" }}>
                      <label>Select :</label>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ height: "35px" }}
                        value={itempage}
                        onChange={(e) => setItempage(e.target.value)}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={15}>20</option>
                        <option value={15}>25</option>
                      </Form.Select>
                    </div> */}

                    {/* <div className="col-lg-2">
                      <label>From :</label>
                      <Form.Control
                        type="date"
                        aria-describedby="basic-addon1"
                        value={startDate}
                        onChange={(e) => setstartDate(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <label>To :</label>
                      <Form.Control
                        type="date"
                        aria-describedby="basic-addon1"
                        value={endDate}
                        onChange={(e) => setendDate(e.target.value)}
                      />
                    </div> */}

                    <div className=" col-lg-2 lab">
                      <label>From</label>
                      <br />
                      <DatePicker
                        onChange={onChangeFrom}
                        picker="date"
                        className="vi_0"
                      />
                    </div>
                    {/* <div className=" col-lg-2 lab">
                      <label>To</label>
                      <br />
                      <DatePicker
                        onChange={onChangeTo}
                        picker="date"
                        className="vi_0"
                      />
                    </div> */}

                    <div className="col-lg-2">
                      {/* <button className="btn btn-primary" onClick={filterData}>
                        Submit
                      </button> */}
                    </div>

                    {/* <div
                      className="input-group col-lg-4"
                      style={{
                        width: "auto",
                        height: "35px",
                        marginTop: "20px",
                      }}
                    >
                      <span class="input-group-text" id="basic-addon1">
                        <BsSearch />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        aria-describedby="basic-addon1"
                        onChange={filterData}
                      />
                    </div> */}
                  </div>
                  <div className="row mb-1">
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                      <Form inline>
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className=" mr-sm-2"
                          onChange={handleFilterH}
                        />
                      </Form>
                    </div>
                  
                    <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
               Events List
                  </h2>
                  <div className="add-b">
                  <button
                          onClick={() => {
                            setEImage("");
                            setEName("");
                            setESDate("");
                            setEEDate("");
                            setELoc("");
                            setEDesc("");
                            handleShow();
                          }}
                        >
                          + Add Events
                        </button>
                  </div>
                </div>
                  </div>

                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Event Name</th>
                        <th>Event Start Date</th>
                        <th>Event End Date</th>
                        <th>Event Location</th>
                        <th>Description</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayPage
                        .filter((val) => {
                          if (FromDate) {
                            console.log(
                              "check Date",
                              new Date(FromDate).toLocaleDateString() <=
                                new Date(val?.ESDate).toLocaleDateString(),
                              new Date(FromDate).toLocaleDateString(),
                              new Date(val?.ESDate).toLocaleDateString()
                            );
                            if (
                              new Date(FromDate).getFullYear() <
                              new Date(val?.ESDate).getFullYear()
                            ) {
                              return true;
                            } else if (
                              new Date(FromDate).getFullYear() ===
                              new Date(val?.ESDate).getFullYear()
                            ) {
                              if (
                                new Date(FromDate).getMonth() <
                                new Date(val?.ESDate).getMonth()
                              ) {
                                return true;
                              } else if (
                                new Date(FromDate).getMonth() ===
                                new Date(val?.ESDate).getMonth()
                              ) {
                                if (
                                  new Date(FromDate).getDate() <=
                                  new Date(val?.ESDate).getDate()
                                ) {
                                  return true;
                                } else {
                                  return false;
                                }
                              } else {
                                return false;
                              }
                            }
                            return false;
                          } else {
                            return true;
                          }
                        })
                        .map((item, i) => {
                          const serialNumber = i + 1 + visitedPage;
                          return (
                            <tr>
                              <td>{serialNumber}</td>
                              <td>
                                <Image
                                  src={`https://kanagamultiservice.in/Events/${item?.EImage}`}
                                  alt="pic"
                                  style={{ width: "75px", height: "75px" }}
                                />
                              </td>

                              <td style={{ paddingTop: "20px" }}>
                                {item?.EName}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {moment(item?.ESDate).format("MMM Do YY")}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {moment(item?.EEDate).format("MMM Do YY")}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {item?.ELoc}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                {/* {parse(`<div>${item?.EDesc}</div>`)} */}

                                {SelectedId === item?._id && Viewmore
                                  ? parse(`<div>${item?.EDesc}</div>`)
                                  : parse(
                                      `<div>${item?.EDesc.slice(0, 270)}</div>`
                                    )}
                                {SelectedId === item?._id && Viewmore ? (
                                  <>
                                    <div
                                      style={{
                                        float: "right",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      <a
                                        onClick={() => {
                                          setSelectedId();
                                          setViewmore(!Viewmore);
                                        }}
                                        style={{
                                          color: "blue",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Show less...
                                      </a>
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      float: "right",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    <a
                                      onClick={() => {
                                        setSelectedId(item?._id);
                                        setViewmore(!Viewmore);
                                      }}
                                      style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Show more...
                                    </a>
                                  </div>
                                )}
                              </td>
                              <td>
                                <button className="btn btn-success">
                                  <AiTwotoneEdit
                                    onClick={() => handleShow1(item)}
                                  />
                                </button>
                              </td>
                              <td>
                                <button className="btn btn-danger">
                                  <AiTwotoneDelete
                                    style={{
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleShow2(item?._id);
                                    }}
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <Pagination style={{ float: "right" }}>
                    <Pagination.First onClick={() => setPageNumber(0)} />
                    <Pagination.Prev
                      onClick={() =>
                        setPageNumber((prev) => Math.max(prev - 1, 0))
                      }
                    />
                    {Array.from({ length: pageCount }, (_, index) => (
                      <Pagination.Item
                        key={index}
                        active={index === pageNumber}
                        onClick={() => setPageNumber(index)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() =>
                        setPageNumber((prev) =>
                          Math.min(prev + 1, pageCount - 1)
                        )
                      }
                    />
                    <Pagination.Last
                      onClick={() => setPageNumber(pageCount - 1)}
                    />
                  </Pagination>
                </>
              ) : (
                <>
                  {UpcomingEvents ? (
                    <>
                      <div className="add-b text-end mb-4 col-md-12">
                        <button onClick={handleShow100}>
                          + Add Upcoming Events
                        </button>
                        &nbsp;
                      </div>
                      <Table
                        striped
                        bordered
                        hover
                        style={{ textAlign: "center" }}
                      >
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Image</th>
                            <th>Event Name</th>
                            <th>Event Start Date</th>
                            <th>Event End Date</th>
                            <th>Event Location</th>
                            <th>Description</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {UEventsList.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <Image
                                    src={`https://kanagamultiservice.in/UpcomingEvents/${item?.UEImage}`}
                                    alt="pic"
                                    style={{ width: "75px", height: "75px" }}
                                  />
                                </td>

                                <td style={{ paddingTop: "20px" }}>
                                  {item?.UEName}
                                </td>
                                <td style={{ paddingTop: "20px" }}>
                                  {moment(item?.UESDate).format("MMM Do YY")}
                                </td>
                                <td style={{ paddingTop: "20px" }}>
                                  {moment(item?.UEEDate).format("MMM Do YY")}
                                </td>
                                <td style={{ paddingTop: "20px" }}>
                                  {item?.UELoc}
                                </td>
                                <td style={{ paddingTop: "20px" }}>
                                  {parse(`<div>${item?.UEDesc}</div>`)}
                                </td>
                                <td>
                                  <button className="btn btn-success">
                                    <AiTwotoneEdit
                                      onClick={() => handleShow99(item)}
                                    />
                                  </button>
                                </td>
                                <td>
                                  <button className="btn btn-danger">
                                    <AiTwotoneDelete
                                      style={{
                                        fontSize: "20px",
                                      }}
                                      onClick={() => {
                                        handleShow98(item?._id);
                                      }}
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Events</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              className="vi_0"
              onChange={(e) => setEImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Name</label>
            <input
              type="text"
              placeholder="Enter Event Name "
              className="vi_0"
              onChange={(e) => setEName(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Event Date(start date)</label>
            <input
              type="date"
              className="vi_0"
              onChange={(e) => setESDate(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Date(end Date)</label>
            <input
              type="date"
              className="vi_0"
              onChange={(e) => setEEDate(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="vi_0"
              onChange={(e) => setELoc(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={EDesc}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddEventsList}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show100} onHide={handleClose100}>
        <Modal.Header closeButton>
          <Modal.Title>Add Upcoming Events</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              className="vi_0"
              onChange={(e) => setUEImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Name</label>
            <input
              type="text"
              placeholder="Enter Event Name "
              className="vi_0"
              onChange={(e) => setUEName(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Event Date(start date)</label>
            <input
              type="date"
              className="vi_0"
              onChange={(e) => setUESDate(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Date(end Date)</label>
            <input
              type="date"
              className="vi_0"
              onChange={(e) => setUEEDate(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="vi_0"
              onChange={(e) => setUELoc(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={UEDesc}
              onChange={handleChange1}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose100}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddUEventsList}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Add Events Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Banner</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setEBanners(e.target.files[0])}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddEventsBanner}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Events </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setEImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Name</label>
            <input
              type="text"
              placeholder="Enter Product Name "
              className="vi_0"
              value={EName}
              onChange={(e) => setEName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Start Date</label>
            <input
              type="date"
              placeholder="Enter Event Start Date "
              className="vi_0"
              value={EName}
              onChange={(e) => setESDate(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event End Date</label>
            <input
              type="date"
              placeholder="Enter Event End Date "
              className="vi_0"
              value={EName}
              onChange={(e) => setEEDate(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="vi_0"
              value={ELoc}
              onChange={(e) => setELoc(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={EDesc}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editEventsList}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show99} onHide={handleClose99}>
        <Modal.Header closeButton>
          <Modal.Title>Update Upcoming Events </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setUEImage(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Name</label>
            <input
              type="text"
              placeholder="Enter Event Name "
              className="vi_0"
              value={UEName}
              onChange={(e) => setUEName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event Start Date</label>
            <input
              type="date"
              placeholder="Enter Event Start Date "
              className="vi_0"
              value={UEName}
              onChange={(e) => setUESDate(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Event End Date</label>
            <input
              type="date"
              placeholder="Enter Event End Date "
              className="vi_0"
              value={UEName}
              onChange={(e) => setUEEDate(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="vi_0"
              value={UELoc}
              onChange={(e) => setUELoc(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={EDesc}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose99}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editUEventsList}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={DeleteEventsList}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show98} onHide={handleClose98}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose98}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={DeleteUEventsList}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show33} onHide={handleClose33}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteEventsbanner}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={show33}
        onHide={handleClose33}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteaddPartnerss}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default AdminEvents;
