import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { AiFillPushpin } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useEffect } from "react";
import Aos from "aos";
import axios from "axios";
import parse from "html-react-parser";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";

const AboutUS = () => {
  const [counterOn, setCounterOn] = useState(false);
  //integrating get  method for experts
  const [AddGraph, setAddGraph] = useState([]);
  const getAddGraph = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/Admin/getallExperts"
      );
      if (res.status === 200) {
        setAddGraph(res.data.getallExperts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for counts
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonials = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get("https://kanagamultiservice.in/api/admin/getCounts");
      if (res.status == 200) {
        setAddTestimonial(res.data.getCounts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // get method  for about us
  const [Adddaboutus, setAdddaboutus] = useState([]);
  const getaddaboutus = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallaboutus"
      );
      if (res.status === 200) {
        setAdddaboutus(res.data.getAddaboutus);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // get method for our success story
  const [Adddourstory, setAdddourstory] = useState([]);
  const getaddourstory = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallourstory"
      );
      if (res.status === 200) {
        setAdddourstory(res.data.getAddourstory);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // get method
  const [Addcertificates, setAddcertificates] = useState([]);
  const getaddCertificates = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getallcertificates"
    );
    if (res.status === 200) {
      setAddcertificates(res.data.getAddcertificates);
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive1 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    Aos.init();
    getaddaboutus();
    getaddourstory();
    getaddCertificates();
    getAddTestimonials();
    getAddGraph();
    window.scroll(0,0);
  }, []);
  console.log(Adddaboutus);
  console.log(Adddourstory);
  console.log(Addcertificates);
  console.log(AddTestimonial);
  console.log(AddGraph);
  return (
    <div>
      <div
        className="container mt-3 mb-2"
        style={{
          backgroundImage: 'url("../we-provide-bg.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "200px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-slider3 text-center">
          <h1
            style={{
              color: "white",
              marginTop: "66px",
              fontSize: "50px",
            }}
          >
            About Us
          </h1>
        </div>
      </div>

      <div className="container mt-4 mb-3">
        {Adddaboutus?.map((item, i) => {
          return (
            <div className="row " data-aos="fade-up" data-aos-duration="3000">
              <div className="col-md-12 text-center">
                <h3 style={{ color: "#2D0290" }}>{item?.AbTitle}</h3>
                <p className="fs-4">{item?.AbSubtitle}</p>
              </div>
              <br />

              <div className="col-md-4">
                <img
                  src={`https://kanagamultiservice.in/AddAboutus/${item?.AbImg}`}
                  alt=""
                  style={{ width: "100%", height: "342px" }}
                />
              </div>

              <div className="col-md-8 ">
                <p style={{ textAlign: "justify" }}>
                  {parse(`<div>${item?.AbDescription?.slice(0, 1165)}</div>`)}
                </p>
              </div>
              <div className="col-md-12">
                <p style={{ textAlign: "justify" }}>
                  {parse(`<div>${item?.AbDescription2}</div>`)}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className="container-fluid p-4 mb-1"
        style={{ backgroundColor: "#2d029050" }}
      >
        <div
          className="row text-center mb-3"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1 style={{ color: "#2D0290" }}>Our Certificates</h1>
          <p style={{ color: "#2D0290" }}>
            "Success is often achieved by those who don't know that failure is
            inevitable"
          </p>
        </div>
        <div
          className="row certificates"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <Carousel responsive={responsive1} autoPlay="true">
            {Addcertificates?.map((item) => {
              return (
                <div>
                  <img
                    src={`https://kanagamultiservice.in/Certificates/${item?.AbCertificate}`}
                    alt=""
                    style={{
                      width: "480px",
                      height: "250px",
                      marginLeft: "100px",
                    }}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>

      <div
        className="container mt-5 p-4"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div
          className="row text-center mb-3"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1>Our Successfull Stories</h1>
          <p>
            “Feedback is the compass for greatness; it tells you what to avoid,
            what to learn, and where to excel.”
          </p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-duration="3000">
          <Carousel responsive={responsive}>
            {Adddourstory?.map((item) => {
              return (
                <div>
                  <Card
                    className="successstory"
                    style={{ width: "22rem", height: "19rem" }}
                  >
                    <Card.Body>
                      <Card.Title style={{ color: "#2D0290" }}>
                        <strong>{item?.AbName}</strong>
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        Location : {item?.AbLocation}
                      </Card.Subtitle>
                      <Card.Text>
                        <p style={{ textAlign: "justify" }}>
                          {parse(`<div>${item?.AbFeedback}</div>`)}
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>

      <div
        className="container mt-5 mb-2 countssss"
        style={{
          backgroundImage: 'url("../countsback.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "200px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-sliderC"></div>

        <div className="row text-center">
          <h4 style={{ color: "white", marginTop: "20px" }}>
            We Have Great Achievements To Do...
          </h4>
        </div>
        <div className="row perfect">
          {AddTestimonial?.map((item) => {
            return (
              <>
                <div
                  className="col-md-3  exports"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div style={{ display: "flex", paddingLeft: "10px" }}>
                    <ScrollTrigger
                      onEnter={() => setCounterOn(true)}
                      onExit={() => setCounterOn(false)}
                    >
                      <h1
                        style={{
                          color: "#FE5E00",
                          fontSize: "40px",
                          borderBottom: "3px solid #FE5E00",
                        }}
                      >
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={item.Counts}
                            duration={2}
                            delay={0}
                          />
                        )}
                        {/* {item?.Counts} */}
                      </h1>
                    </ScrollTrigger>
                    {/* <h1
                      style={{
                        color: "#FE5E00",
                        fontSize: "40px",
                        borderBottom: "3px solid #FE5E00",
                      }}
                    >
                      {item?.Counts} 
                    </h1> */}
                  </div>
                  <div>
                    <h5
                      style={{
                        color: "white",
                        paddingBottom: "10px",
                        paddingLeft: "10px",
                        marginTop: "-8px",
                      }}
                    >
                      {item?.Text}
                    </h5>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className="container mt-5 mb-5 text-center">
        <h1 data-aos="fade-up" data-aos-duration="3000">
          MEET OUR <span style={{ color: "#2D0290" }}>TEAM</span>
        </h1>

        <h6 data-aos="fade-up" data-aos-duration="3000">
          “I'm a great believer in luck, and I find the harder I work, the more
          I have of it.”
        </h6>

        <br />
        <br />

        <div className="row " data-aos="fade-up" data-aos-duration="3000">
          <Carousel responsive={responsive}>
            {AddGraph?.map((item) => {
              return (
                <div>
                  <Card
                    className="Team"
                    style={{
                      width: "20rem",
                      height: "23rem",
                      background: "rgba(255,255,255,0.24)",
                      boxShadow: "8px 8px 32px 0 rgba(19,19,20,0.37)",
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src={`https://kanagamultiservice.in/OurTeam/${item?.AbImg}`}
                      style={{ width: "100%", height: "17rem" }}
                    />
                    <Card.Body>
                      <Card.Title>
                        <h4 style={{ color: "#2D0290" }}>{item?.AbName}</h4>
                        <h6>{item?.AbDesignation}</h6>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Control placeholder="First name" />
              </Col>
              <Col>
                <Form.Control placeholder="Last name" />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Control placeholder="Email" />
              </Col>
              <Col>
                <Form.Control placeholder="Mobile" />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Control placeholder="Destination From" />
              </Col>
              <Col>
                <Form.Control placeholder="Destination To" />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Select>
                  <option>---Select the service type---</option>
                  <option>Consulting services</option>
                  <option>Coordination services</option>
                  <option>Business Facilitation Services</option>
                  <option>Assistance in choosing sites</option>
                  <option>Visits by investor delegations</option>
                  <option>Project launch services</option>
                  <option>Assistance in establishing partnerships</option>
                  <option>Tracking Services</option>
                  <option>
                    Advance your project and your initiatives in a collaborative
                    dynamic
                  </option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Control type="date" placeholder="Date" />
              </Col>
            </Row>
            <br />

            <Form.Control
              as="textarea"
              placeholder="Message"
              style={{ height: "100px" }}
            />

            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="btn-danger"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={handleClose}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AboutUS;
