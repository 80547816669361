import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FaLocationDot } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import axios from "axios";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";

const ContactUS = () => {
  const form = useRef();
  const { id } = useParams();

  const [EFName, setEFName] = useState("");
  const [ELName, setELName] = useState("");
  const [EPhone, setEPhone] = useState("");
  const [EEmail, setEEmail] = useState("");
  const [EComName, setEComName] = useState("");
  const [ELoc, setELoc] = useState("");
  const [ERequire, setERequire] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_6oxt0dj",
        "template_mkwe6a3",
        form.current,
        "TBO6xmtenGkRrwbze"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          Enquiryf();
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      );
  };

  const Enquiryf = async () => {
    try {
      if (!EFName) return alert("Please Enter Your First Name");
      if (!ELName) return alert("Please Enter Your Last Name");
      if (!EPhone) return alert("Please Enter Your Phone no");
      if (!EEmail) return alert("Please Enter Your Email id");
      if (!EComName) return alert("Please Enter Your Company Name");
      if (!ELoc) return alert("Please Enter Your Location");
      if (!ERequire) return alert("Please Enter Your Requirement");

      const config = {
        url: "/user/enquiry",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "application/json" },
        data: {
          EFName: EFName,
          ELName: ELName,
          EPhone: EPhone,
          EEmail: EEmail,
          EComName: EComName,
          ELoc: ELoc,
          ERequire: ERequire,
        },
      };

      const res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        setEFName("");
        setELName("");
        setEPhone("");
        setEEmail("");
        setEComName("");
        setELoc("");
        setERequire("");
        window.location.assign("/events");
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const [AddContactuss, setAddContactuss] = useState([]);
  const getAddContactuss = async () => {
    try {
      const res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddContactUs"
      );
      if (res.status === 200) {
        setAddContactuss(res.data.getaddContactUs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AddBContactuss, setAddBContactuss] = useState([]);
  const getAddBContactuss = async () => {
    try {
      const res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddBContactUs"
      );
      if (res.status === 200) {
        setAddBContactuss(res.data.getaddBContactUs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    getAddContactuss();
    getAddBContactuss();
  }, []);

  return (
    <div>
      <div
        className="container mt-3 mb-2"
        style={{
          backgroundImage: 'url("../we-provide-bg.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "200px",
          backgroundRepeat: "no-repeat",
          position: "relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-slider3 text-center">
          <h1
            style={{
              color: "white",
              marginTop: "66px",
              fontSize: "50px",
            }}
          >
            Contact Us
          </h1>
        </div>
      </div>

      <div className="container pt-4">
        <div className="row ">
          {AddContactuss.map((item, i) => (
            <div className="col-md-8" key={i}>
              <h1>
                We Are Always With{" "}
                <span style={{ color: "#FE5E00" }}>YOU :)</span>
              </h1>
              <img
                src={`https://kanagamultiservice.in/ContactHead/${item?.CImg}`}
                alt="pic"
                style={{ width: "100%", height: "350px" }}
              />
              <br />
            </div>
          ))}
          <div className="col-md-4 mt-5">
            <br />
            {AddContactuss.map((item, i) => (
              <Card key={i}>
                <Card.Header
                  style={{
                    backgroundColor: "#2D0290",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  Head Office
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div className="row">
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                        <FaLocationDot style={{ color: "#2D0290" }} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                        Address:
                        <br />
                        {item?.ConAddress}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                        <BsFillTelephoneFill style={{ color: "#2D0290" }} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                        Contact No:
                        <br />
                        {item?.AddPhone}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                        <GrMail style={{ color: "#2D0290" }} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                        Email:
                        <br />
                        <a href={`mailto:${item?.AddEmail}`}>
                          {item?.AddEmail}
                        </a>
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <h4 style={{ marginTop: "10px", color: "#FE5E00" }}>
              Quick Contact Form
            </h4>
            <Form ref={form} onSubmit={sendEmail}>
              <Row>
                <Col>
                  <Form.Control
                    placeholder="First name"
                    type="text"
                    id="user_Fname"
                    name="user_fname"
                    onChange={(e) => setEFName(e.target.value)}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    id="user_Lname"
                    name="user_lname"
                    onChange={(e) => setELName(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    id="user_email"
                    name="user_email"
                    onChange={(e) => setEEmail(e.target.value)}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Mobile"
                    name="user_phone"
                    onChange={(e) => setEPhone(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Control
                    name="user_company"
                    placeholder="Company Name"
                    onChange={(e) => setEComName(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    name="user_location"
                    placeholder="Location"
                    onChange={(e) => setELoc(e.target.value)}
                  />
                </Col>
              </Row>
              <br />
              <Form.Control
                as="textarea"
                type="text"
                name="user_message"
                placeholder="Brief about your Requirement"
                onChange={(e) => {
                  setERequire(e.target.value);
                }}
                style={{ height: "100px" }}
              />

              <br />
              <div className="d-flex align-items-center pb-5 justify-content-center">
                <div className="p-1">
                  <Button
                    type="submit"
                    className="btn-danger p-3"
                    style={{
                      backgroundColor: "#FE5E00",
                      border: "1px solid #FE5E00",
                    }}
                  >
                    Send Enquiry
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="col-md-4">
            <br />
            {AddBContactuss.map((item, i) => (
              <Card key={i}>
                <Card.Header
                  style={{
                    backgroundColor: "#2D0290",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  Branch Office
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div className="row">
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                        <FaLocationDot style={{ color: "#2D0290" }} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                        Address:
                        <br /> {item?.BConAddress}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                        <BsFillTelephoneFill style={{ color: "#2D0290" }} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                        Contact No:
                        <br />
                        {item?.BAddPhone}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                        <GrMail style={{ color: "#2D0290" }} />
                      </div>
                      <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                        Email:
                        <br />
                        {item?.BAddEmail}
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUS;
