import React, { useEffect, useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { Tooltip } from "antd";
import axios from "axios";

const Whatsapp = () => {
   // get method
   const [addWSocial, setaddWSocial] = useState([]);
   const getaddWSocial = async () => {
     let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddWSocial");
     if (res.status === 200) {
       setaddWSocial(res.data.getaddWSocial);
     }
   };
   useEffect(() => {
    getaddWSocial();
   }, [])
   
  const text = (
    <span>
      Chat Now
      <br /> Click here..!
    </span>
  );
  const buttonWidth = 70;
  return (
    <div
      style={{
        marginLeft: buttonWidth,
        whiteSpace: "nowrap",
      }}
    >
      <Tooltip placement="leftTop" title={text}>
        <div
          className="scroll_top_button active_0 k9"
          style={{ backgroundColor: "#47c758" }}
        >
          {addWSocial?.map((item)=>{
            return(
              <a href={item?.WSocial} target="_blanck">
              <BsWhatsapp style={{ color: "white", fontSize: "29px" }}/>
           
            </a>
            )
          })}
        
        </div>
      </Tooltip>
    </div>
  );
};

export default Whatsapp;
