import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { FaRegHandshake } from "react-icons/fa6";
import emailjs from "@emailjs/browser";

const Partner = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const [JName, setJName] = useState("");
  const [JPhone, setJPhone] = useState("");
  const [JEmail, setJEmail] = useState("");
  const [JComName, setJComName] = useState("");
  const [JLoc, setJLoc] = useState("");
  const [JBusinessType, setJBusinessType] = useState("");
  const [JMessage, setJMessage] = useState("");

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_6oxt0dj",
        "template_mkwe6a3",
        form.current,
        "TBO6xmtenGkRrwbze"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          Enquiryf();
          window.location.assign("/");
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      );
  };

  const Enquiryf = async () => {
    try {
      if (!JName) return alert("Please Enter Your  Name");
      if (!JPhone) return alert("Please Enter Your Phone no");
      if (!JEmail) return alert("Please Enter Your Email id");
      if (!JComName) return alert("Please Enter  Company Name");
      if (!JLoc) return alert("Please Enter the Location");
      if (!JBusinessType) return alert("Please Enter the Business type");
      if (!JMessage) return alert("Please Enter the Message");
      const config = {
        url: "/user/Jenquiry",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          JName: JName,
          JPhone: JPhone,
          JEmail: JEmail,
          JComName: JComName,
          JLoc: JLoc,
          JBusinessType: JBusinessType,
          JMessage: JMessage,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          window.location.reload();
          // handleClose();
          setJName("");
          setJPhone("");
          setJEmail("");
          setJComName("");
          setJLoc("");
          setJBusinessType("");
          setJMessage("");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <div className="container p-5">
        <div className="row ">
          <div className="col-md-6">
            <div
              className="container counts_bg"
              style={{
                backgroundImage: 'url("../slide-bg-2.jpg")',
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
                height: "446px",
                backgroundRepeat: "no-repeat",
                position: " relative",
                left: "-3px",
                boxSizing: "border-box",
                maxWidth: "100vw",
              }}
            >
              <div className="bs-slider"></div>
              <div className="row text-center ">
                <div className="col-md-12 bs-slider2">
                  <div className="login">
                    <FaRegHandshake
                      style={{
                        border: "10px solid #FE5E00",
                        borderRadius: "50%",
                        padding: "20px",
                        fontSize: "118px",
                      }}
                    />
                  </div>
                  <h4 style={{ color: "white" }}>Join Our Team Now</h4>
                  <p>
                    "Individually we’re one drop, but together we’re an ocean"
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 p-5 join"
            style={{ backgroundColor: "#E8E8E8", marginLeft: "-15px" }}
          >
            <Form ref={form} onSubmit={sendEmail}>
              <Row>
                <Col>
                  <Form.Control
                    name="user_fname"
                    placeholder="Name"
                    onChange={(e) => setJName(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    name="user_email"
                    placeholder="Email"
                    onChange={(e) => setJEmail(e.target.value)}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Control
                    name="user_phone"
                    placeholder="Mobile"
                    onChange={(e) => setJPhone(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    name="user_company"
                    placeholder="Compant Name"
                    onChange={(e) => setJComName(e.target.value)}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Control
                    name="user_location"
                    placeholder="Location"
                    onChange={(e) => setJLoc(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Business Type"
                    onChange={(e) => setJBusinessType(e.target.value)}
                  />
                </Col>
              </Row>
              <br />
              <Form.Control
                as="textarea"
                name="user_message"
                placeholder="Message"
                onChange={(e) => setJMessage(e.target.value)}
                style={{
                  height: "100px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
              />
              <Col></Col>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-danger"
                  // onClick={sendEmail}
                  style={{
                    backgroundColor: "#FE5E00",
                    border: "1px solid #FE5E00",
                    marginTop: "20px",
                  }}
                >
                  Join Now
                </button>{" "}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
