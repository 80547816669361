import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import parse from "html-react-parser";
import Aos from "aos";
import Modal from "react-bootstrap/Modal";
import { Button, Image, Carousel } from "react-bootstrap";
import { IoEye } from "react-icons/io5";
import { Pagination } from "react-bootstrap";

const Products = () => {
  const [View, setView] = useState({});
  const [show100, setShow100] = useState(false);
  const handleClose100 = () => setShow100(false);
  const handleShow100 = () => setShow100(true);

  const [show50, setShow50] = useState(false);
  const handleClose50 = () => setShow50(false);
  const handleShow50 = () => setShow50(true);

  const navigate = useNavigate();
  const [Viewmore, setViewmore] = useState(true);
  const [SelectedId, setSelectedId] = useState();

  const [expanded, setExpanded] = useState(false);
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };
  const [Electric, setElectric] = useState("");
  const [Catfilter, setCatfilter] = useState([]);

  const [SortAll, setSortAll] = useState("");

  //integrating category get method
  const [AddProductcate, setAddProductcate] = useState([]);
  const getAddProductcate = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddProductCategories"
      );
      if (res.status == 200) {
        setAddProductcate(res.data.getadddProductCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("Catfilter", Catfilter);
  //integrating get  method
  const [AddProductss, setAddProductss] = useState([]);
  const [SelectedCat, setSelectedCat] = useState("");
  const [nochangedataH, setnochangedataH] = useState([]);
  const getProducts = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallProducts"
      );
      if (res.status === 200) {
        setAddProductss(res.data.getAddProducts);
        setnochangedataH(res.data.getAddProducts);
        setCatfilter(res.data.getAddProducts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("SelectedCat", SelectedCat);
  //search filter for product
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAddProductss([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAddProductss([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = AddProductss.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });

  // useEffect(() => {
  //   if (Electric) {
  //     const xyz = AddProductss?.filter((item) => item.PCat === Electric);
  //     setCatfilter(xyz);
  //   } else if (SortAll === "All") {
  //     setCatfilter(AddProductss);
  //   } else if (SortAll === "High to Low") {
  //     const xyz = AddProductss?.sort((a, b) => a.PPrice - b.PPrice);
  //     setCatfilter(xyz);
  //   } else if (SortAll === "Low to High") {
  //     const xyz = AddProductss?.sort((a, b) => b.PPrice - a.PPrice);
  //     setCatfilter(xyz);
  //   } else {
  //     setCatfilter(AddProductss);
  //   }
  // }, [Electric, SortAll]);

  // console.log("Catfilter", Catfilter, AddProductss);
  // console.log("SortAll", Electric, SortAll);

  // Pagination
  const [pageNumber, setpageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = AddProductss?.slice(
    visitedPage,
    visitedPage + productPerPage
  );
  const pageCount = Math?.ceil(AddProductss.length / productPerPage);

  useEffect(() => {
    window.scroll(0, 0);
    Aos.init();
    getAddProductcate();
    getProducts();
  }, []);

  useEffect(() => {
    if (Electric) {
      const xyz = AddProductss?.filter((item) => item.PCat === Electric);
      setCatfilter(xyz);
    } else {
      setCatfilter(AddProductss);
    }
  }, [Electric, AddProductss]);

  const [Length, setLength] = useState([]);
  return (
    <div>
      <div className="container-fluid mt-4">
        <div
          className="row text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1>
            Our <span style={{ color: "#FE5E00" }}>Products</span>
          </h1>
          <h6>
            "We see our customers as invited guests to a party, and we are the
            hosts. It's our job to make the customer experience a satisfied
            experience."
          </h6>
        </div>
        <div className="row " data-aos="fade-up" data-aos-duration="3000">
          <div
            className="col-md-12 mb-2"
            style={{ backgroundColor: "#F5F5F5" }}
          >
            <div className="row mt-1 " style={{ padding: "20px" }}>
              <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                <Form inline>
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className=" mr-sm-2"
                    onChange={handleFilterH}
                  />
                </Form>
              </div>
              {/* <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                <div style={{ float: "right" }}>
                  <Form.Select
                    onChange={(e) => {
                      setSortAll(e.target.value);
                      setElectric("");
                    }}
                  >
                    <option>---Sort By---</option>
                    <option value="All">All</option>
                    <option value="High to Low">High to Low</option>
                    <option value="Low to High">Low to High</option>
                  </Form.Select>
                </div>
              </div> */}
            </div>
          </div>

          <div
            className="col-md-4 pt-1"
            data-aos="fade-up"
            data-aos-duration="3000"
            style={{ backgroundColor: "#F5F5F5" }}
          >
            <Card style={{ position: "sticky", top: "158px" }}>
              <Card.Header
                style={{
                  backgroundColor: "#2D0290",
                  color: "white",
                  fontSize: "20px",
                }}
              >
                Category List
              </Card.Header>
              <Card.Body>
                <div className="si09">
                  <Card.Text>
                    {AddProductcate?.map((item) => {
                      return (
                        <>
                          <div
                            className="row p-2"
                            onClick={() => setSelectedCat(item?.Pcat)}
                          >
                            <div className="col-1 col-sm-1 col-md-1 col-lg-1 ">
                              <img
                                src={`https://kanagamultiservice.in/ProductCategories/${item?.PIocn}`}
                                alt=""
                                width={30}
                                height={25}
                              />
                            </div>
                            <div
                              className="col-11 col-sm-11 col-md-11 col-lg-11 categories"
                              onClick={() => {
                                setElectric(item?.Pcat);
                              }}
                              style={{ fontSize: "21px" }}
                            >
                              {item?.Pcat}
                              <span style={{ float: "right" }}>
                                  (
                                    {
                                  AddProductss?.filter(
                                    (ele) => ele.PCat === item?.Pcat
                                  )?.length
                                }
                                  )
                                
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </div>
          {Catfilter?.length > 0 ? (
            <>
              <div className="col-md-8">
                <h2>{SelectedCat}</h2>
                {Catfilter?.map((item, i) => {
                  return (
                    <>
                      {/* <h2>{item?.SCat}</h2> */}
                      <div className="row p-2 ps-4">
                        <div
                          className="row"
                          style={{
                            boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            className="col-md-4"
                            style={{ position: "relative" }}
                          >
                            <img
                              src={`https://kanagamultiservice.in/Products/${item?.PImage}`}
                              className="d-block w-100"
                              style={{ height: "200px" }}
                              alt="First slide"
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "150px",
                                left: "20px",
                                zIndex: "2",
                              }}
                            >
                              <button
                                className="btn btn-success"
                                style={{
                                  backgroundColor: "#FE5E00",
                                  border: "1px solid #FE5E00",
                                  borderRadius: "0px",
                                }}
                              >
                                <IoEye
                                  style={{ fontSize: "20px" }}
                                  onClick={() => {
                                    handleShow100();
                                    setView(item);
                                  }}
                                />
                              </button>
                            </div>
                          </div>

                          <div
                            className="col-md-7 p-3"
                            style={{ backgroundColor: "#F3F3F5" }}
                          >
                            <div className="row">
                              <div className="col-md-7">
                                <h5 style={{ color: "#FE5E00" }}>
                                  <strong>{item?.PName}</strong>
                                </h5>
                              </div>
                              <div className="col-md-5 text-end">
                                <Link
                                  to={`/productviewmore`}
                                  state={{ item: item }}
                                  style={{
                                    backgroundColor: "#FE5E00",
                                    padding: "6px",
                                  }}
                                  className="btn btn-danger"
                                >
                                  Ask For Price
                                </Link>
                              </div>
                            </div>

                            <h6 style={{ fontSize: "15px" }}>
                              <span style={{ fontWeight: "550" }}>
                                {" "}
                                Place Of Origin :
                              </span>{" "}
                              {item?.POrigin}
                            </h6>
                            <p
                              style={{ textAlign: "justify", fontSize: "15px" }}
                            >
                              <p
                                style={{
                                  textAlign: "justify",
                                  fontSize: "15px",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "550",
                                  }}
                                >
                                  Description
                                </span>
                                {item?.PDesc? parse(
                                
                                 item?.PDesc?.slice(0, 25)
                                ):""}
                                <Link
                                  style={{ float: "right", marginTop: "-38px" }}
                                  onClick={() => {
                                    handleShow50();
                                    setView(item);
                                  }}
                                >
                                  Read More...
                                </Link>
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {/* <div className="text-end">
                <Pagination style={{ float: "right" }}>
                  <Pagination.First onClick={() => setpageNumber(0)} />
                  <Pagination.Prev
                    onClick={() =>
                      setpageNumber((prev) => Math.max(prev - 1, 0))
                    }
                  />
                  {Array.from({ length: pageCount }, (_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index === pageNumber}
                      onClick={() => setpageNumber(index)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setpageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                    }
                  />
                  <Pagination.Last
                    onClick={() => setpageNumber(pageCount - 1)}
                  />
                </Pagination>
              </div> */}
            </>
          ) : (
            <>
              <div className="col-md-8">
                {displayPage?.map((item, i) => {
                  return (
                    <>
                      <h2>{item?.PCat}</h2>
                      <div className="row p-2 ps-4">
                        <div
                          className="row"
                          style={{
                            boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            className="col-md-4"
                            style={{ position: "relative" }}
                          >
                            <img
                              src={`https://kanagamultiservice.in/Products/${item?.PImage}`}
                              className="d-block w-100"
                              style={{ height: "200px" }}
                              alt="First slide"
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "150px",
                                left: "20px",
                                zIndex: "2",
                              }}
                            >
                              <button
                                className="btn btn-success"
                                style={{
                                  backgroundColor: "#FE5E00",
                                  border: "1px solid #FE5E00",
                                  borderRadius: "0px",
                                }}
                              >
                                <IoEye
                                  style={{ fontSize: "20px" }}
                                  onClick={() => {
                                    handleShow100();
                                    setView(item);
                                  }}
                                />
                              </button>
                            </div>
                          </div>

                          <div
                            className="col-md-7 p-3"
                            style={{ backgroundColor: "#F3F3F5" }}
                          >
                            <div className="row">
                              <div className="col-md-7">
                                <h5 style={{ color: "#FE5E00" }}>
                                  <strong>{item?.PName}</strong>
                                </h5>
                              </div>
                              <div className="col-md-5 text-end">
                                <Link
                                  to={`/productviewmore`}
                                  state={{ item: item }}
                                  style={{
                                    backgroundColor: "#FE5E00",
                                    padding: "6px",
                                  }}
                                  className="btn btn-danger"
                                >
                                  Ask For Price
                                </Link>
                              </div>
                            </div>

                            <h6 style={{ fontSize: "15px" }}>
                              <span style={{ fontWeight: "550" }}>
                                {" "}
                                Place Of Origin :
                              </span>{" "}
                              {item?.POrigin}
                            </h6>
                            <p
                              style={{ textAlign: "justify", fontSize: "15px" }}
                            >
                              <p
                                style={{
                                  textAlign: "justify",
                                  fontSize: "15px",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "550",
                                  }}
                                >
                                  Description
                                </span>
                                {/* {item?.PDesc?parse(
                                  `<div>${item?.PDesc?.slice(0, 25)}</div>`
                                ):""} */}
                                 {item?.PDesc? parse(
                                
                                item?.PDesc?.slice(0, 25)
                               ):""}
                                <Link
                                  style={{ float: "right", marginTop: "-38px" }}
                                  onClick={() => {
                                    handleShow50();
                                    setView(item);
                                  }}
                                >
                                  Read More...
                                </Link>
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {/* <div className="text-end">
                 <Pagination style={{ float: "right" }}>
                  <Pagination.First onClick={() => setpageNumber(0)} />
                  <Pagination.Prev
                    onClick={() =>
                      setpageNumber((prev) => Math?.max(prev - 1, 0))
                    }
                  />
                  {Array.from({ length: pageCount }, (_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index === pageNumber}
                      onClick={() => setpageNumber(index)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setpageNumber((prev) => Math?.min(prev + 1, pageCount - 1))
                    }
                  />
                  <Pagination.Last
                    onClick={() => setpageNumber(pageCount - 1)}
                  />
                </Pagination>
              </div>  */}
            </>
          )}
        </div>
      </div>
      <Modal
        size="lg"
        style={{ zIndex: "99999" }}
        show={show50}
        onHide={handleClose50}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#FE5E00" }}>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <p>{View?.PDesc?parse(`<div>${View?.PDesc}</div>`):""}</p> */}
            <p>{View?.PDesc? parse(
                                
                                View?.PDesc
                               ):""}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose50}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={show100}
        onHide={handleClose100}
        style={{ zIndex: "999999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <img
                  src={`https://kanagamultiservice.in/Products/${View?.PImage}`}
                  alt="pic"
                  style={{ width: "70%", height: "99%" }}
                />
              </div>
              <div className="col-md-4">
                <img
                  src={`https://kanagamultiservice.in/Products/${View?.Image1}`}
                  alt="pic"
                  style={{ width: "70%", height: "99%" }}
                />
              </div>
              <div className="col-md-4">
                <img
                  src={`https://kanagamultiservice.in/Products/${View?.Image2}`}
                  alt="pic"
                  style={{ width: "70%", height: "99%" }}
                />
              </div>
              <div className="col-md-6 text-end mt-2">
                <img
                  src={`https://kanagamultiservice.in/Products/${View?.Image3}`}
                  alt="pic"
                  style={{ width: "70%", height: "99%" }}
                />
              </div>
              <div className="col-md-6 mt-2">
                <img
                  src={`https://kanagamultiservice.in/Products/${View?.Image4}`}
                  alt="pic"
                  style={{ width: "70%", height: "99%" }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose100}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Products;
