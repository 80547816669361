import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image, Pagination } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminOurCertificates = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // post method for experience
  const formdata = new FormData();
  const [AbCertificate, setAbCertificate] = useState("");

  const AddCertificates = async () => {
    formdata.append("AbCertificate", AbCertificate);
    try {
      if(!AbCertificate) return alert("Please Select the Image");
      const config = {
        url: "/admin/addcertificates",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getaddCertificates();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [Addcertificates, setAddcertificates] = useState([]);
  const getaddCertificates = async () => {
    let res = await axios.get(
      "https://kanagamultiservice.in/api/admin/getallcertificates"
    );
    if (res.status === 200) {
      setAddcertificates(res.data.getAddcertificates);
    }
  };
  //delete method
  const [show33, setShow33] = useState(false);
  const [Data, setData] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setData(item);
  };
  const deleteaddCertificates = async () => {
    try {
      const config = {
        url: "admin/DeleteAddcertificates/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddCertificates();
          handleClose33();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setDAta1(item);
  };
  const [DAta1, setDAta1] = useState({});

  

  useEffect(() => {
    getaddCertificates();
  }, []);
  console.log(Addcertificates); 

//Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 10;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = Addcertificates.slice(
    visitedPage,
    visitedPage + productPerPage
  );
  const pageCount = Math.ceil(Addcertificates.length / productPerPage);
  return (
    <>
      <div className="add-gr">
        <div className="container">
        {/* <div className="add-b text-end mt-4 col-md-12">
            <button onClick={()=>{setAbCertificate("");
              handleShow()}}>Add Certificates</button>
          </div> */}
          <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                 Our Certificates
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{setAbCertificate("");
              handleShow()}}>Add Certificates</button>
                  </div>
                </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-2">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Certificate</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {displayPage.map((item, i) => {
                const serialNumber = i + 1 + visitedPage;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <Image
                        src={`https://kanagamultiservice.in/Certificates/${item?.AbCertificate}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>

                    <td>
                      <button className="btn btn-danger">
                        <AiTwotoneDelete
                          style={{
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            handleShow33(item?._id);
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination style={{ float: "right" }}>
            <Pagination.First onClick={() => setPageNumber(0)} />
            <Pagination.Prev
              onClick={() => setPageNumber((prev) => Math.max(prev - 1, 0))}
            />
            {Array.from({ length: pageCount }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index === pageNumber}
                onClick={() => setPageNumber(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
              }
            />
            <Pagination.Last onClick={() => setPageNumber(pageCount - 1)} />
          </Pagination>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Certificates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder=""
              className="vi_0"
              onChange={(e) => setAbCertificate(e.target.files[0])}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={AddCertificates}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show33}
        onHide={handleClose33}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteaddCertificates}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Certificates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setCImg(e.target.files[0])}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={updateClients}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default AdminOurCertificates;
