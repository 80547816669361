import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image, Pagination, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { AiFillDelete, AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminSuccesstory = () => {
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setAbFeedback(data);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // intergrating post method in addminaboutus

  const formdata = new FormData();
  // const [AbImg, setAbImg] = useState("");
  const [AbName, setAbName] = useState("");
  const [AbLocation, setAbLocation] = useState("");
  const [AbFeedback, setAbFeedback] = useState("");

  const Addourstory = async () => {
    // formdata.set("AbImg", AbImg);
    // formdata.set("AbName", AbName);
    // formdata.set("AbLocation", AbLocation);
    // formdata.set("AbFeedback", AbFeedback);

    try {
      if (!AbName) {
        return alert("Please Enter the Name");
      }
      if (!AbLocation) {
        return alert("Please Enter the Location");
      }

      if (!AbFeedback) {
        return alert("Please Enter the Feedback");
      }
      const config = {
        url: "/admin/addourstory",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "application/json" },
        data: {
          AbName: AbName,
          AbLocation: AbLocation,
          AbFeedback: AbFeedback,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getaddourstory();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [Adddourstory, setAdddourstory] = useState([]);
  const getaddourstory = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getallourstory"
      );
      if (res.status === 200) {
        setAdddourstory(res.data.getAddourstory);
        setnochangedataH(res.data.getAddourstory);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const deleteadddourstory = async () => {
    try {
      const config = {
        url: "admin/DeleteAddourstory/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddourstory();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setAbName(item?.AbName);
    setAbLocation(item?.AbLocation);
    setAbFeedback(item?.AbFeedback);
  };

  const editAddourstory = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/editAddourstory",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        headers: { "content-type": "application/json" },
        data: {
          AbName: AbName,
          AbLocation: AbLocation,
          AbFeedback: AbFeedback,
          id: Data1?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getaddourstory();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
    // }
  };

  useEffect(() => {
    getaddourstory();
  }, []);
  console.log(Adddourstory);

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = Adddourstory.slice(
    visitedPage,
    visitedPage + productPerPage
  );
  const pageCount = Math.ceil(Adddourstory.length / productPerPage);

  //search filter for success stories
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setAdddourstory([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setAdddourstory([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = Adddourstory.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  return (
    <>
      <div className="ms-5">
        <div className="container">
          <div className="row mb-1 mt-3">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilterH}
                />
              </Form>
            </div>
            {/* <div className="col-md-4">
              <div className="add-b text-end col-md-12">
                <button 
                onClick={()=>{
                  setAbName("");
                  setAbLocation("");
                  setAbFeedback("");
                  handleShow()}}
                >+ Add Success Story</button>
              </div>
            </div> */}
             <div className="d-flex justify-content-between mt-3">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
                 Our Success Story
                  </h2>
                  <div className="add-b">
                  <button 
                onClick={()=>{
                  setAbName("");
                  setAbLocation("");
                  setAbFeedback("");
                  handleShow()}}
                >+ Add Success Story</button>
                  </div>
                </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-3 ms-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Location</th>
                <th>Feedback</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {displayPage.map((item, i) => {
                const serialNumber = i + 1 + visitedPage;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{item?.AbName}</td>
                    <td>{item?.AbLocation}</td>
                    <td style={{ paddingTop: "20px" }}>
                      {parse(`<div>${item?.AbFeedback}</div>`)}
                    </td>
                    <td>
                      <button className="btn btn-success">
                        <AiTwotoneEdit onClick={() => handleShow1(item)} />
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-danger">
                        <AiTwotoneDelete
                          style={{
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            handleShow2(item?._id);
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination style={{ float: "right" }}>
            <Pagination.First onClick={() => setPageNumber(0)} />
            <Pagination.Prev
              onClick={() => setPageNumber((prev) => Math.max(prev - 1, 0))}
            />
            {Array.from({ length: pageCount }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index === pageNumber}
                onClick={() => setPageNumber(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
              }
            />
            <Pagination.Last onClick={() => setPageNumber(pageCount - 1)} />
          </Pagination>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Success Story</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter Name"
              className="vi_0"
              onChange={(e) => setAbName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="vi_0"
              onChange={(e) => setAbLocation(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>FeedBack</label>
            <CKEditor
              editor={ClassicEditor}
              data={AbFeedback}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={Addourstory}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Success Story</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Name</label>
            <input
              type="text"
              value={AbName}
              className="vi_0"
              onChange={(e) => setAbName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Location</label>
            <input
              type="text"
              value={AbLocation}
              className="vi_0"
              onChange={(e) => setAbLocation(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Feedback</label>
            <CKEditor
              editor={ClassicEditor}
              data={Data1?.AbFeedback}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={editAddourstory}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>
            Do You Really Want To Delete...??
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#E81B24" }}
            onClick={deleteadddourstory}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminSuccesstory;
