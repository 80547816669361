import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Table, Image, Pagination } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";

const AdminGallery = () => {
  //Image==========================
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // post method for experience
  const formdata = new FormData();
  const [AddImg, setAddImg] = useState("");
  const [AddText, setAddText] = useState("");

  const AddGallerry = async () => {
    formdata.append("AddImg", AddImg);
    formdata.append("AddText", AddText);

    // console.log("formdata",formdata);

    try {
      if (!AddImg) {
        return alert("Please Select the Image");
      }
      if (!AddText) {
        return alert("Please Enter the Text");
      }
      const config = {
        url: "/admin/addGallery",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getaddgallerys();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [addgallery, setaddgallery] = useState([]);
  const getaddgallerys = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddGallery");
    if (res.status === 200) {
      setaddgallery(res.data.getadddgallery);
    }
  };

  //delete method
  const [show33, setShow33] = useState(false);
  const [Data, setData] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setData(item);
  };
  const deleteadddgallery = async () => {
    try {
      const config = {
        url: "/admin/deleteaddgallery/" + Data,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddgallerys();
          handleClose33();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setDAta1(item);
    setAddText(item?.AddText);
  };
  const [DAta1, setDAta1] = useState({});

  const editAddGallerry = async (e) => {
    e.preventDefault();
    // if (!AddImg) {
    //   alert("Select the image");
    // } else {
    formdata.append("AddImg", AddImg);
    formdata.append("AddText", AddText);
    formdata.append("id", DAta1?._id);
    try {
      const config = {
        url: "/admin/updategallery",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getaddgallerys();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  // //Video=========================
  const [show100, setShow100] = useState(false);

  const handleClose100 = () => setShow100(false);
  const handleShow100 = () => setShow100(true);

  // post method for experience
  const formdata1 = new FormData();
  const [VAddImg, setVAddImg] = useState("");
  const [VAddText, setVAddText] = useState("");

  const AddVGallerry = async () => {
    formdata1.append("VAddImg", VAddImg);
    formdata1.append("VAddText", VAddText);

    // console.log("formdata",formdata);

    try {
      if (!VAddImg) {
        return alert("Please Select the Video");
      }
      if (!VAddText) {
        return alert("Please Enter the Text");
      }
      const config = {
        url: "/admin/addVGallery",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data:
          formdata1,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose100();
        getaddVgallerys();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [addVgallery, setaddVgallery] = useState([]);
  const getaddVgallerys = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddVGallery");
    if (res.status === 200) {
      console.log("gallery",res.data.getadddVgallery);
      setaddVgallery(res.data.getadddVgallery);
    }
  };

  console.log("addVgallery",addVgallery);
  //delete method
  const [show98, setShow98] = useState(false);
  const [Data1, setData1] = useState("");

  const handleClose98 = () => setShow98(false);
  const handleShow98 = (item) => {
    setShow98(true);
    setData1(item);
  };
  const deleteadddVgallery = async () => {
    try {
      const config = {
        url: "/admin/deleteaddVgallery/" + Data1,
        method: "delete",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getaddVgallerys();
          handleClose98();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [show99, setShow99] = useState(false);
  const handleClose99 = () => setShow99(false);
  const handleShow99 = (item) => {
    setShow99(true);
    setDAta2(item);
    setVAddText(item?.VAddText);
  };
  const [DAta2, setDAta2] = useState({});

  const editAddVGallerry = async (e) => {
    e.preventDefault();
    // if (!AddImg) {
    //   alert("Select the image");
    // } else {
    formdata1.append("VAddImg", VAddImg);
    formdata1.append("VAddText", VAddText);
    formdata1.append("id", DAta2?._id);
    try {
      const config = {
        url: "/admin/updateVgallery",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: formdata1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Updated");
          handleClose99();
          getaddVgallerys();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getaddgallerys();
    getaddVgallerys();
  }, []);

  console.log(addgallery);
  const [Image, setImage] = useState(true);
  const [Video, setVideo] = useState(false);

  // Pagination for images
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 5;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = addgallery.slice(visitedPage, visitedPage + productPerPage);
  const pageCount = Math.ceil(addgallery.length / productPerPage);

  // Pagination for videos
  // Pagination
  // const [pageNumber1, setPageNumber1] = useState(0);
  // const productPerPage1 = 5;
  // const visitedPage1 = pageNumber1 * productPerPage1;
  // const displayPage1 = addVgallery?.slice(visitedPage1, visitedPage1 + productPerPage1);
  // const pageCount1 = Math.ceil(addVgallery.length / productPerPage1);
  const [pageNumber1, setPageNumber1] = useState(0);
  const productPerPage1 = 5;
  const visitedPage1 = pageNumber1 * productPerPage1;
  const displayPage1 = addVgallery.slice(
    visitedPage1,
    visitedPage1 + productPerPage1
  );
  const pageCount1 = Math.ceil(addVgallery.length / productPerPage1);
  console.log(displayPage1,"oFJDW")
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="ad-b mt-4 mb-4 col-md-12">
              
              <button
                onClick={() => {
                  setImage(true);
                  setVideo(false);
                }}
              >
                Images
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setImage(false);
                  setVideo(true);
                }}
              >
                Videos
              </button>
            </div>
          </div>
        </div>
      </div>
      {Image ? (
        <>
          <div className="add-gr">
            <div className="container">
             
              <div className="d-flex justify-content-between ">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
              Images
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                    setAddImg("");
                    setAddText("");
                    handleShow()}}
                    >Add Images</button>
                  </div>
                </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra ms-5 mt-1">
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Text</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {displayPage.map((item, i) => {
                const serialNumber = i + 1 + visitedPage;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>
                          <img
                            src={`https://kanagamultiservice.in/Gallery/${item?.AddImg}`}
                            // accept=".jpg,.png.avif,.jpeg,.mp4,.webm"
                            alt="pic"
                            style={{ width: "75px", height: "75px" }}
                          />
                        </td>
                        <td style={{ paddingTop: "20px" }}>{item?.AddText}</td>

                        <td>
                          <button className="btn btn-success">
                            <AiTwotoneEdit onClick={() => handleShow4(item)} />
                          </button>
                        </td>
                        <td>
                          <button className="btn btn-danger">
                            <AiTwotoneDelete
                              style={{
                                // color: "red",
                                fontSize: "20px",
                                // paddingTop: "20px",
                              }}
                              onClick={() => {
                                handleShow33(item?._id);
                              }}
                            />
                          </button>
                          {/* <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        title="delete"
                        onClick={() => {
                          handleShow33(item?._id);
                        }}
                      ></i> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination style={{ float: "right" }}>
                <Pagination.First onClick={() => setPageNumber(0)} />
                <Pagination.Prev
                  onClick={() => setPageNumber((prev) => Math.max(prev - 1, 0))}
                />
                {Array.from({ length: pageCount }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === pageNumber}
                    onClick={() => setPageNumber(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setPageNumber((prev) => Math.min(prev + 1, pageCount - 1))
                  }
                />
                <Pagination.Last onClick={() => setPageNumber(pageCount - 1)} />
              </Pagination>
            </div>
          </div>
        </>
      ) : (
        <>
          {Video ? (
            <>
              <div className="add-gr">
               
                 
                  <div className="d-flex justify-content-between ">
                  <h2 style={{ color: "#FC5E04", font: "bold" }}>
              Videos
                  </h2>
                  <div className="add-b">
                  <button onClick={()=>{
                      setVAddImg("");
                      setVAddText("");
                      handleShow100()}}
                      >Add Video</button>
                  </div>
                </div>
               
              </div>
              <div className="container">
                <div className="mo-gra ms-5 mt-1">
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Video</th>
                        <th>Text</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addVgallery?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i+ 1+ visitedPage1}</td>
                            <td>
                              <video width="220" height="140" controls>
                                <source
                                  src={`https://kanagamultiservice.in/Video/${item?.VAddImg}`}
                                  type="video/mp4"
                                />
                              </video>
                              {/* <Video
                        src={`https://kanagamultiservice.in/Gallery/${item?.VAddImg}`}
                        accept=".mp4,.webm"
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      /> */}
                            </td>
                            <td style={{ paddingTop: "20px" }}>
                              {item?.VAddText}
                            </td>

                            <td>
                              <button className="btn btn-success">
                                <AiTwotoneEdit
                                  onClick={() => handleShow99(item)}
                                />
                              </button>
                              {/* <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => {
                              handleShow4(item);
                            }}
                            title="edit"
                          ></i>
                        </span>
                      </div> */}
                            </td>
                            <td>
                              <button className="btn btn-danger">
                                <AiTwotoneDelete
                                  style={{
                                    // color: "red",
                                    fontSize: "20px",
                                    // paddingTop: "20px",
                                  }}
                                  onClick={() => {
                                    handleShow98(item?._id);
                                  }}
                                />
                              </button>
                              {/* <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        title="delete"
                        onClick={() => {
                          handleShow33(item?._id);
                        }}
                      ></i> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  {/* <Pagination style={{ float: "right" }}>
                <Pagination.First onClick={() => setPageNumber1(0)} />
                <Pagination.Prev
                  onClick={() => setPageNumber1((prev) => Math.max(prev - 1, 0))}
                />
                {Array.from({ length: pageCount }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === pageNumber1}
                    onClick={() => setPageNumber1(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setPageNumber1((prev) => Math.min(prev + 1, pageCount1 - 1))
                  }
                />
                <Pagination.Last onClick={() => setPageNumber1(pageCount1 - 1)} />
              </Pagination> */}
 <Pagination style={{ float: "right" }}>
                          <Pagination.First onClick={() => setPageNumber1(0)} />
                          <Pagination.Prev
                            onClick={() =>
                              setPageNumber1((prev) => Math.max(prev - 1, 0))
                            }
                          />
                          {Array.from({ length: pageCount1 }, (_, index) => (
                            <Pagination.Item
                              key={index}
                              active={index === pageNumber1}
                              onClick={() => setPageNumber1(index)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() =>
                              setPageNumber1((prev) =>
                                Math.min(prev + 1, pageCount1 - 1)
                              )
                            }
                          />
                          <Pagination.Last
                            onClick={() => setPageNumber1(pageCount1 - 1)}
                          />
                        </Pagination>

                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setAddImg(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Text</label>
            <input
              type="text"
              placeholder="Enter Text Here..."
              className="vi_0"
              onChange={(e) => setAddText(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={AddGallerry}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show100}
        onHide={handleClose100}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Video</label>
            <input
              type="file"
              className="vi_0"
              accept=".mp4,.webm"
              onChange={(e) => setVAddImg(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Text</label>
            <input
              type="text"
              placeholder="Enter Text Here..."
              className="vi_0"
              onChange={(e) => setVAddText(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose100}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={AddVGallerry}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show33}
        onHide={handleClose33}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddgallery}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show98}
        onHide={handleClose98}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose98}>
            Close
          </Button>
          <Button
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={deleteadddVgallery}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setAddImg(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Text</label>
            <input
              type="text"
              placeholder="Enter Text Here..."
              className="vi_0"
              value={AddText}
              onChange={(e) => setAddText(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={editAddGallerry}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show99}
        onHide={handleClose99}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Video</label>
            <input
              type="file"
              placeholder="Enter Years"
              className="vi_0"
              onChange={(e) => setVAddImg(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Text</label>
            <input
              type="text"
              placeholder="Enter Text Here..."
              className="vi_0"
              value={VAddText}
              onChange={(e) => setVAddText(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            style={{ border: "none", backgroundColor: "#633188" }}
            onClick={editAddVGallerry}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminGallery;
