import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import { GrMail } from "react-icons/gr";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BiSolidTime } from "react-icons/bi";
import { NavbarScrol } from "./NavbarScrol";
import { useNavigate } from "react-router-dom";
import { FaRegHandshake } from "react-icons/fa6";

export const Header = () => {
  const navigate = useNavigate();
  //integrating get  method
  const [AddContactuss, setAddContactuss] = useState([]);
  const getAddContactuss = async () => {
    try {
      let res = await axios.get(
        "https://kanagamultiservice.in/api/admin/getaddContactUs"
      );
      if (res.status === 200) {
        setAddContactuss(res.data.getaddContactUs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddContactuss();
  }, []);

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        zIndex: "99999",
      }}
    >
      <Container
        fluid
        
        style={{ background: "#484545", height: "32px" }}
      >
        <Container className="top-navbar pt-1 header1">
          {AddContactuss?.map((item) => {
            return (
              <div className="d-flex gap-3">
                <div>
                  <span>
                    <GrMail
                    id="header"
                      style={{
                        fontSize: "19px",
                        color: "#FE5E00",
                        marginRight: "5px",
                      }}
                    />
                  </span>
                  <span style={{ fontSize: "15px" }} id="header">{item?.AddEmail}</span>
                </div>
                <div id="header">|</div>
                <div>
                  <span>
                    <BiSolidPhoneCall
                    id="header"
                      style={{
                        fontSize: "19px",
                        color: "#FE5E00",
                        marginRight: "4px",
                      }}
                    />
                  </span>
                  <span style={{ fontSize: "15px" }} id="header">{item?.AddPhone}</span>
                </div>
                <div id="header">|</div>
                <div className="d-flex gap-2 text-end">
                  <span>
                    <BiSolidTime
                    id="header"
                      style={{
                        fontSize: "19px",
                        color: "#FE5E00",
                        marginRight: "3px",
                      }}
                    />
                  </span>
                  <span style={{ fontSize: "15px" }} id="header">9:00AM-8:00PM</span>
                </div>
              </div>
            );
          })}

          <div className="d-flex gap-2">
            <div>
              <span>
                <FaRegHandshake
                id="header"
                  onClick={() => navigate("/partner")}
                  style={{
                    fontSize: "19px",
                    color: "#FE5E00",
                    marginRight: "5px",
                  }}
                />
              </span>
              <span id="header"
                style={{ fontSize: "15px" }}
                onClick={() => navigate("/partner")}
              >
                Join As A Partner
              </span>
            </div>
            <div id="google_translate_element" ></div>
          </div>
         
        </Container>
        
      </Container>
      <NavbarScrol />
    </div>
  );
};
