import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table, Image, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import parse from "html-react-parser";

const AdminHomeService = () => {
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setHService(data);
  };
  //service............
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // post method for experience
  const [HService, setHService] = useState("");

  const AddHService = async () => {
    try {
      if (!HService) return alert("Please Enter the Description");
      const config = {
        url: "/admin/addHService",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: {
          HService: HService,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getaddHService();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method
  const [nochangedataH, setnochangedataH] = useState([]);
  const [HServices, setHServices] = useState([]);
  const getaddHService = async () => {
    let res = await axios.get("https://kanagamultiservice.in/api/admin/getaddHService");
    if (res.status === 200) {
      setHServices(res.data.getadddHService);
      setnochangedataH(res.data.getadddHService);
    }
  };

  //update method
  const [Data1, setData1] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setHService(item?.HService);
  };

  const editAddHService = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/updateHService",
        method: "put",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "multipart/form-data" },
        data: {
          HService: HService,
          id: Data1?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getaddHService();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getaddHService();
  }, []);
  // console.log(getaddAsianCountries);

  //search filter for home services
  const [searchH, setSearchH] = useState("");
  const handleFilterH = (e) => {
    if (e.target.value != "") {
      setSearchH(e.target.value);
      const filterTableH = nochangedataH.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
      setHServices([...filterTableH]);
    } else {
      setSearchH(e.target.value);
      setHServices([...nochangedataH]);
    }
  };
  const [searchTermH, setSearchTermH] = useState("");
  const searchedProductH = HServices.filter((item) => {
    if (searchTermH.value === "") {
      return item;
    }
    if (item?.EName?.toLowerCase().includes(searchTermH?.toLowerCase())) {
      return item;
    } else {
      return console.log("not found");
    }
  });
  return (
    <>
      <div className="ms-5">
        <div className="container">
          <div className="row mb-3 mt-3">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
              <Form inline>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className=" mr-sm-2"
                  onChange={handleFilterH}
                />
              </Form>
            </div>
            <div className="add-b d-flex justify-content-between mt-2">
              <div>
                <h2 style={{color:"#FC5E04",font:"bold"}}>Home Services</h2>
              </div>
              <div className="">
                {HServices?.length !== 0 ? (
                  ""
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setHService("");
                        handleShow();
                      }}
                    >
                      Add About Us
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="mo-gra mt-3">
            {/*            
                <div className="add-b text-end  mb-4 col-md-12">
                  <button onClick={handleShow}>Add Service </button>
                </div> */}

            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Description</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {HServices.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td style={{ paddingTop: "20px" }}>
                        {parse(`<div>${item?.HService}</div>`)}
                      </td>
                      <td>
                        <button className="btn btn-success">
                          <AiTwotoneEdit onClick={() => handleShow1(item)} />
                        </button>
                      </td>
                      {/* <td>
                            <button className="btn btn-danger">
                              <AiTwotoneDelete
                                style={{
                                  fontSize: "20px",
                                }}
                                onClick={() => {
                                  handleShow100(item?._id);
                                }}
                              />
                            </button>
                          </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={HService}
                onChange={handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={AddHService}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Update Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={Data1?.HService}
                onChange={handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={editAddHService}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <Modal
          show={show100}
          onHide={handleClose100}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose100}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={deleteadddHService}
            >
              Delete{" "}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show99}
          onHide={handleClose99}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ color: "#c0392b" }}>
              Do You Really Want To Delete...
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose99}>
              Close
            </Button>
            <Button
              variant="success"
              style={{ border: "none", backgroundColor: "#E81B24" }}
              onClick={deleteadddAfricanC}
            >
              Delete{" "}
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
    </>
  );
};

export default AdminHomeService;
