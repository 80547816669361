import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Aos from "aos";
import emailjs from "@emailjs/browser";

const Process = () => {
  //email integration
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_6oxt0dj",
        "template_mkwe6a3",
        form.current,
        "TBO6xmtenGkRrwbze"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          Enquiryf();
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      );
  };

  // integrating post method
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [EFName, setEFName] = useState("");
  const [ELName, setELName] = useState("");
  const [EPhone, setEPhone] = useState("");
  const [EEmail, setEEmail] = useState("");
  const [EComName, setEComName] = useState("");
  const [ELoc, setELoc] = useState("");
  const [ERequire, setERequire] = useState("");

  const Enquiryf = async () => {
    try {
      if (!EFName) return alert("Please Enter Your First Name");
      if (!ELName) return alert("Please Enter Your Last Name");
      if (!EPhone) return alert("Please Enter Your Phone no");
      if (!EEmail) return alert("Please Enter Your Email id");
      if (!EComName) return alert("Please Enter Your Company Name");
      if (!ELoc) return alert("Please Enter Your Location");
      if (!ERequire) return alert("Please Enter Your Requirement");
      const config = {
        url: "/user/enquiry",
        method: "post",
        baseURL: "https://kanagamultiservice.in/api",
        header: { "content-type": "application/json" },
        data: {
          EFName: EFName,
          ELName: ELName,
          EPhone: EPhone,
          EEmail: EEmail,
          EComName: EComName,
          ELoc: ELoc,
          ERequire: ERequire,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          setEFName("");
          setELName("");
          setEPhone("");
          setEEmail("");
          setEComName("");
          setELoc("");
          setERequire("");
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row"></div>
      </div>

      <div
        className="container counts_bg"
        style={{
          backgroundImage: 'url("../slide-bg-2.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          height: "600px",
          backgroundRepeat: "no-repeat",
          position: " relative",
          left: "-3px",
          boxSizing: "border-box",
          maxWidth: "100vw",
          paddingLeft: "3px",
          paddingRight: "20px",
        }}
      >
        <div className="bs-slider"></div>
        <div className="row text-center">
          <div
            className="col-md-6 bs-slider2"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h1 className="mt-4">GET A FREE QUOTE</h1>
            <h5 style={{ color: "black" }}>
              We Always Use Best & Fastest Fleets
            </h5>
            <div className="row mt-5 p-3">
              <Form ref={form} onSubmit={sendEmail}>
                <Row>
                  <Col>
                    <Form.Control
                      placeholder="First name"
                      type="text"
                      id="user_Fname"
                      name="user_fname"
                      onChange={(e) => setEFName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      id="user_Lname"
                      name="user_Lname"
                      onChange={(e) => setELName(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Control
                      placeholder="Email"
                      type="email"
                      id="user_email"
                      name="user_email"
                      onChange={(e) => setEEmail(e.target.value)}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Mobile"
                      name="user_phone"
                      onChange={(e) => setEPhone(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Control
                      name="user_company"
                      placeholder="Company Name"
                      onChange={(e) => setEComName(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      name="user_location"
                      placeholder="Location"
                      onChange={(e) => setELoc(e.target.value)}
                    />
                  </Col>
                </Row>
                <br />
                <Form.Control
                  as="textarea"
                  type="text"
                  name="user_message"
                  placeholder="Breif about your Requirement"
                  style={{ height: "100px" }}
                  onChange={(e) => {
                    setERequire(e.target.value);
                  }}
                />

                <br />
                <div className="d-flex align-items-center pb-5 justify-content-center">
                  <div className="p-1">
                    <Button
                      type="submit"
                      value="Send"
                      className="  btn-danger p-3"
                      // onClick={() => {
                      //   Enquiryf();
                      // }}
                      style={{ backgroundColor: "#FE5E00" }}
                    >
                      Send Enquiry
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
